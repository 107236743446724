import {useState} from "react";
import {uploadImage} from "../../../../../services/FileService";
import {updateUserAvatar} from "../../../../../services/AuthService";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import FileInput from "../../../../_BASE/UI/Input/FileInput/FileInput";

const EditAvatarModal = ({closeFunc, Store}) => {
    const [userAvatar, setUserAvatar] = useState()
    const [userAvatarURL, setUserAvatarURL] = useState(Store.authProfile.avatarLink)

    const handleUpdateClick = async () => {
        try {
            const avatar = await uploadImage(userAvatar)
            await updateUserAvatar(
                Store.authProfile.userId,
                avatar.fileId,
                avatar.fileLink
            )
        } catch (e) {
            throw e
        }
    }

    return <ModalWindow
        title={"Изменить аватар пользователя "}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={"Сохранить"}
        primaryAction={async () => {
            if (userAvatar) {
                await handleUpdateClick()
            }
            await Store.getUserAuthProfile()
            closeFunc(false)
        }}
    >
        <FileInput imageObject={userAvatar}
                   setImageObject={setUserAvatar}
                   imageUrl={userAvatarURL}
                   setImageUrl={setUserAvatarURL}
                   size={128}
        />
    </ModalWindow>
}

export default EditAvatarModal
