import style from './ChatList.module.scss'
import fonts from '../../../../static/fonts.module.scss'

import {useState, useMemo, useEffect, useRef, useContext} from "react";

import {ReactComponent as EditIcon} from "./Edit.svg";
import {ReactComponent as DeleteIcon} from "./Delete.svg";
import {ReactComponent as DocumentIcon} from "./document.svg";
import useOutsideAlerter from "../../../../static/hooks";
import {Context} from "../../../../index";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import {ReactComponent as Sent} from "./Checkmark.svg";
import {ReactComponent as Read} from "./CheckmarkRead.svg";
import {observer} from "mobx-react-lite";
import Fancybox from "../../../_BASE/UI/Fancybox/Fancybox";


const SingleMessage = observer(({readOnly, message, rightAligned, selectable}) => {
    const {ChatStore} = useContext(Context)
    const [selected, setSelected] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editValue, setEditValue] = useState(message.message);
    const [editModal, setEditModal] = useState(false);
    const buttonsRef = useRef(null)


    const handleDelete = () => {
        ChatStore.deleteMessage(message.id)
        setEditModal(false)
    }

    const handleEdit = () => {
        message.type === 'TEXT'
            ? ChatStore.editMessage(editValue, message.id, message.type)
            : ChatStore.editMessage(editValue, message.id, message.type, message.attachments)

        setEditModal(false)
    }

    useEffect(() => {
        if (!rightAligned && !message.readByRecipients.read && !readOnly) {
            ChatStore.readMessage(message.id)
        }
    }, [])

    useOutsideAlerter(buttonsRef, () => setSelected(false))

    return <div className={style.messageWrap}
                style={{
                    alignSelf: rightAligned ? 'flex-end' : 'flex-start',
                }}
    >
        <div className={`${style.message} ${rightAligned ? style.blue : ""}`}
             key={message.id}>
            {
                message.attachments?.length !== 0 &&
                <Fancybox>
                    {
                        message.type === 'DOC'
                            ? <div className={`${style.attachmentsDocumentsWrap} ${rightAligned ? style.blue : ""}`}>
                                {
                                    message.attachments.map(({link}, index) =>
                                        <div data-fancybox={`${message.id}-att`}
                                             data-src={link}
                                             key={`${index}-key`}
                                             className={style.attPreview}>
                                            <DocumentIcon/>
                                        </div>)
                                }
                            </div>
                            : <div className={`${style.attachmentsWrap} ${rightAligned ? style.blue : ""}`}>
                                {
                                    message.attachments.map(({link}, index) =>
                                        <div data-fancybox={`${message.id}-att`}
                                             data-src={link}
                                             key={`${index}-key`}
                                             className={style.attPreview}>
                                            <img src={link} alt=""/>
                                        </div>)
                                }
                            </div>
                    }
                </Fancybox>
            }
            <p className={fonts.body1}>
                {message.message}
            </p>
            <div className={style.messageStatus}>
                            <span className={fonts.caption}>
                            {new Date(message.date).toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
            </span>
                {
                    message.readByRecipients.read
                        ? <Read/>
                        : <Sent/>
                }
            </div>
        </div>
        {
            (!readOnly && rightAligned) &&
            <div ref={buttonsRef} className={`${style.buttons}`}>
                <button onClick={() => setEditModal(true)} title={'Редактировать'}><EditIcon/></button>
                <button onClick={() => setDeleteModal(true)} title={'Удалить'}><DeleteIcon/></button>
            </div>
        }
        {
            deleteModal && <ModalWindow title={'Удаление сообщения'}
                                        closeFunc={setDeleteModal}
                                        type={'small'}
                                        primaryAction={() => handleDelete()}
            >
                <p style={{maxWidth: 524, wordBreak: "break-word"}} className={fonts.subtitle1Bold}>Удалить сообщение
                    "{message.message}"?</p>
            </ModalWindow>
        }
        {
            editModal && <ModalWindow title={'Редактирование сообщения'}
                                      closeFunc={setEditModal}
                                      type={'small'}
                                      primaryAction={() => handleEdit()}
            >
                <Input value={editValue} setValue={setEditValue} title={'Новое сообщение'}
                       styles={{maxWidth: 524, width: 524}} type={'textarea'} maxHeight={300}/>
            </ModalWindow>
        }
    </div>
})

const MessageBlock = observer(({userID, messages, blockId, rightUserId, readOnly}) => {
    if (messages?.length === 0) {
        return null
    }

    return <div
        className={style.messageBlockWrap}
        style={{
            flexDirection: userID === rightUserId ? 'row' : 'row-reverse',
            alignSelf: userID === rightUserId ? 'flex-end' : 'flex-start'
        }}
        key={blockId}
    >
        <div
            className={style.messagesWrap}
        >
            {
                messages.map((message) => <SingleMessage message={message}
                                                         key={message.id}
                                                         rightAligned={userID === rightUserId}
                                                         selectable={userID === rightUserId}
                                                         readOnly={readOnly}
                    />
                )
            }
        </div>
    </div>
})

const ChatList = ({data = [], rightUserId, readOnly}) => {
    const allMessages = useMemo(() => {
        const messageBlocks = []
        let messageBlockIterator = 1
        data?.forEach((message, index) => {
            if (messageBlocks.length !== 0) {
                const date1 = new Date(message.date)
                const date2 = new Date(data[index - 1].date)
                const dif = date1 - date2

                if ((messageBlocks[messageBlocks.length - 1].userID === message.senderId)
                    && (dif < 1000 * 60 * 10)
                ) {
                    messageBlocks[messageBlocks.length - 1].messages.push(message)
                } else {
                    messageBlocks.push(
                        {
                            userID: message.senderId,
                            messages: [message],
                            blockId: messageBlockIterator
                        }
                    )
                    messageBlockIterator += 1
                }
            } else {
                messageBlocks.push(
                    {
                        userID: message.senderId,
                        messages: [message],
                        blockId: messageBlockIterator
                    }
                )
                messageBlockIterator += 1
            }
        })

        return messageBlocks
    }, [data, rightUserId])
    const chatBottomRef = useRef(null)
    const chatRef = useRef(null)
    const [onBottom, setOnBottom] = useState(true);


    useEffect(() => {
        chatBottomRef?.current?.scrollIntoView()
        chatRef.current.addEventListener('scroll', () => {
            if (chatRef.current.scrollHeight === chatRef.current.scrollTop + chatRef.current.offsetHeight) {
                setOnBottom(true)
            } else {
                setOnBottom(false)
            }
        })
    }, [])

    useEffect(() => {
        if (data?.length !== 0 && onBottom) {
            chatBottomRef?.current?.scrollIntoView({behavior: 'smooth'})
        }
    }, [data])


    return <div className={style.chatWrap} ref={chatRef}>
        {
            allMessages.map(({userID, avatar_url, messages, blockId}) =>
                <MessageBlock messages={messages}
                              userID={userID}
                              blockId={blockId}
                              key={blockId}
                              avatar_url={avatar_url}
                              rightUserId={rightUserId}
                              readOnly={readOnly}
                />
            )
        }
        <div ref={chatBottomRef}/>
    </div>
}

export default ChatList
