import $auth from "../http";
import {notifications} from "../index";

//Получение списка вопрос-ответов (FAQ)
export const getFAQList = async () => {
    try {
        const data = await $auth.get('/v1/support/faq')
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание вопрос-ответа (FAQ)
export const createFAQ = async (data) => {
    try {
        const req = await $auth.post(`/v1/support/faq`, {
            ...data
        })
        console.log(req)
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Изменение вопрос-ответа (FAQ)
export const editFAQ = async (id, data) => {
    try {
        const req = await $auth.put(`/v1/support/faq/${id}`, {
            ...data
        })
        console.log(req)
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Удаление вопрос-ответа (FAQ)
export const deleteFAQ = async (id) => {
    try {
        const req = await $auth.delete(`/v1/support/faq/${id}`)
        console.log(req)
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка обращений в "Обратной связи"
export const getFeedbackList = async (lifestatus, page, offset) => {
    try {
        const data = await $auth.get(`/v1/support/feedback/list?requestType=LIST&lifeStatus=${lifestatus}&page=${page}&offset=${offset}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение информации по обращению в "Обратной связи"
export const getFeedbackDetails = async (id) => {
    try {
        const data = await $auth.get(`/v1/support/feedback/record/${id}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Архивация обращения в "Обратной связи"
export const archiveFeedbackRecord = async (id) => {
    try {
        const data = await $auth.delete(`/v1/support/feedback/record/${id}`)
        notifications.addNotification(data.data.message, 'OK')
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение количества страниц в "Обратной связи"
export const getFeedbackCount = async (lifestatus) => {
    try {
        const data = await $auth.get(`/v1/support/feedback/list?requestType=NUMBER_OF_PAGE&lifeStatus=${lifestatus}&offset=20`)
        return data.data.recordCount
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка связей между СЕКЦИЕЙ_КАТЕГОРИЕЙ обращения в ТП
export const getTicketRelations = async () => {
    try {
        const data = await $auth.get(`/v1/support/link`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получение списка связей между СЕКЦИЕЙ_КАТЕГОРИЕЙ обращения в ТП по ID секции
export const getTicketRelationsBySectionId = async (id) => {
    try {
        const data = await $auth.get(`/v1/support/link/${id}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получение списка секций обращений в ТП
export const getTicketSections = async () => {
    try {
        const data = await $auth.get(`/v1/support/sections?code=ALL`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Изменение информации секции обращения в ТП
export const editTicketSection = async (id, data) => {
    try {
        const res = await $auth.put(`/v1/support/sections/${id}`, data)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка категорий обращений в ТП
export const getTicketCategories = async () => {
    try {
        const data = await $auth.get(`/v1/support/category`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Изменение информации категории обращения в ТП
export const editTicketCategory = async (id, data) => {
    try {
        const res = await $auth.put(`/v1/support/category/${id}`, data)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Обновление связи между СЕКЦИЕЙ_КАТЕГОРИЕЙ обращения в ТП
export const editTicketRelation = async (id, data) => {
    try {
        const res = await $auth.put(`/v1/support/link/${id}`, data)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Архивирования или разархивирования связи между СЕКЦИЕЙ_КАТЕГОРИЕЙ обращения в ТП (Удаление или восстановление)
export const archiveTicketRelation = async (id) => {
    try {
        const res = await $auth.delete(`/v1/support/link/${id}?status=OFF`)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание  категории обращения в ТП
export const createTicketCategory = async (data) => {
    try {
        const res = await $auth.post(`/v1/support/category`, data)
        notifications.addNotification(res.data.message, 'OK')
        return res.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание секции обращения в ТП
export const createTicketSection = async (data) => {
    try {
        const res = await $auth.post(`/v1/support/sections`, data)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание связи между СЕКЦИЕЙ_КАТЕГОРИЕЙ обращения в ТП
export const createTicketRelation = async (data) => {
    try {
        const res = await $auth.post(`/v1/support/link`, data)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание секции обращения в ТП
export const archiveTicketSection = async (id, status) => {
    try {
        const res = await $auth.delete(`/v1/support/sections/${id}?status=${status}`)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получение списка причин закрытия обращения в ТП
export const getTicketCloseReasons = async (status) => {
    try {
        const data = await $auth.get(`/v1/support/reason?status=${status}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание новой причины закрытия обращения в ТП
export const createTicketCloseReasons = async (data) => {
    try {
        const req = await $auth.post(`/v1/support/reason`, data)
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка причин закрытия обращения в ТП
export const editTicketCloseReasons = async (id, data) => {
    try {
        const req = await $auth.put(`/v1/support/reason/${id}`, data)
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка причин закрытия обращения в ТП
export const archiveTicketCloseReasons = async (id,) => {
    try {
        const req = await $auth.delete(`/v1/support/reason/${id}`)
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка обращений в ТП
export const getSupportTickets = async (page, offset) => {
    try {
        const data = await $auth.get(`/v1/support?requestType=LIST&statusCode=ALL_RECORD&page=${page}&offset=${offset}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение количества обращений в ТП
export const getSupportTicketsCount = async () => {
    try {
        const data = await $auth.get(`/v1/support?requestType=NUMBER_OF_PAGE&statusCode=ALL_RECORD&offset=20`)
        return data.data.recordCount
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Поиск обращений
export const searchTickets = async (message, type) => {
    try {
        const data = await $auth.get(`/v1/support/search/ticket?search=${type}&message=${message}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка профилей агентов ТП
export const getAgents = async () => {
    try {
        const data = await $auth.get(`/v1/support/agent?requestType=LIST&status=ON`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение списка причин закрытия обращения в ТП
export const transferTicket = async (id, agentId) => {
    try {
        const req = await $auth.put(`/v1/support/ticket/transfer/${id}?agentId=${agentId}`)
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по количеству обращений для агента ТП
export const getAgentStats = async (userId, type, dateFrom, dateTo) => {
    try {
        const data = await $auth.get(`/v1/support/agent/statistics?userId=${userId}&requestType=${type}&firstDate=${dateFrom}&secondDate=${dateTo}`)
        return data.data.message
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение списка обращений в ТП ДЛЯ определенного агента ТП
export const getAgentTickets = async (userId, statusCode, page, offset) => {
    try {
        const data = await $auth.get(`/v1/support/for-agent?requestType=LIST&agentId=${userId}&statusCode=${statusCode}&offset=${offset}&page=${page}`)
        console.log(data)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение параметров для пагинации ДЛЯ определенного агента ТП
export const getAgentTicketsCount = async (userId, statusCode) => {
    try {
        const data = await $auth.get(`/v1/support/for-agent?requestType=NUMBER_OF_PAGE&agentId=${userId}&statusCode=${statusCode}&offset=10`)
        return data.data.recordCount
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение профиля агента ТП
export const getAgentProfile = async (id) => {
    try {
        const data = await $auth.get(`/v1/support/agent?requestType=PROFILE&id=${id}`)
        return data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Активация или деактивация профиля агента ТП
export const changeAgentStatus = async (id) => {
    try {
        const req = await $auth.put(`/v1/support/agent/${id}`)
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        throw e
    }
}

//Получение информацию по обращению в ТП
export const getTicket = async (id) => {
    try {
        const req = await $auth.get(`/v1/support/ticket/${id}`)
        return req.data
    } catch (e) {
        throw e
    }
}

//Получение списка категорий обращений в ТП
export const archiveTicketCategories = async (categoryId) => {
    try {
        await $auth.delete(`/v1/support/category/${categoryId}?status=OFF`,)
        notifications.addNotification('Категория успешно удалена', 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Взять тикет в работу
export const openTicket = async () => {
    try {
        const req = await $auth.put(`/v1/support/ticket?type=AUTO`)
        if(req.data.status){
            notifications.addNotification(req.data.message, "OK")
        } else {
            notifications.addNotification(req.data.message)
        }
        return req.data.status
    } catch (e) {
        throw e
    }
}

//Закрытие обращения в ТП
export const closeTicket = async (id, reasonId, comment) => {
    try {
        const req = await $auth.delete(`/v1/support/ticket/${id}?reasonId=${reasonId}&comment=${comment}`)
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        throw e
    }
}