import loader from './icon.gif'

import s from './Preloader.module.scss'

const Preloader = ({type = 'defualt', style}) => {
    return <div className={`${s.loader} ${type === 'big' ? s.big : null}`} style={style}>
        <img src={loader} alt="loading..."/>
    </div>
}

export default Preloader