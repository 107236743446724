import s from './RefTable.module.scss'
import f from '../../../../static/fonts.module.scss'

import RefIcon from './refer.png'
import MedalIcon from './medal.png'


import CountUp from "react-countup";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import Table from "../../../_BASE/Layout/Table/Table";


import {useEffect, useState} from "react";
import {
    getPromoAdditionalInfo,
    getPromoInfo,
    getReferralsCount,
    getReferralsList
} from "../../../../services/PaymentService";
import {userStore} from "../UserInfo";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import UserCard from "../../OrderInfo/UserCard/UserCard";


const TableRow = ({item}) => {
    const [isOpen, setIsOpen] = useState(false);
    const actions = [{
        label: 'Подробнее',
        func: () => setIsOpen(true)
    }]
    return <>
        <p className={f.subtitle1Bold}>{item.userId}</p>
        <p className={f.subtitle1Bold}>{item.name} {item.middlename} {item.surname}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton actions={actions} type={"icon"}
                            nested_styles={{background: "white"}}/>
        </div>

        {
            isOpen &&
            <ModalWindow closeFunc={setIsOpen}
                         title={"Профиль"}
            >
                <div className={s.profileWrap}>
                    <UserCard userId={item.userId}/>
                    {/*<p className={f.caption}>*Нажмите на карточку для перехода в профиль</p>*/}
                </div>
            </ModalWindow>
        }
    </>
}

const RefTable = () => {
    const Store = userStore
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 10
    });
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [countIsLoaded, setCountIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [code, setCode] = useState("");
    const [activatedCounter, setActivatedCounter] = useState(0);

    const [promoIsLoaded, setPromoIsLoaded] = useState(false);
    const [activatedCounterLoaded, setActivatedCounterLoaded] = useState(false);

    const [codeNotFound, setCodeNotFound] = useState(false);

    useEffect(() => {

        const getCount = async () => {
            try {
                setCountIsLoaded(false)
                const count = await getReferralsCount(Store.authProfile.userId, "REFERRAL_CODE")
                setCountIsLoaded(true)
                setTotal(count)
            } catch (e) {
                throw e
            }
        }
        const getCode = async () => {
            try {
                setIsLoading(true)
                setPromoIsLoaded(false)
                const code = await getPromoInfo(Store.authProfile.userId, 'REFERRAL_CODE')
                await getCount()
                await getActivated()
                setCode(code.secretWord)
            } catch (e) {
                setCodeNotFound(true)
                throw e
            } finally {
                setPromoIsLoaded(true)
                setIsLoading(false)
            }
        }
        const getActivated = async () => {
            try {
                setActivatedCounter(false)
                const counter = await getPromoAdditionalInfo(Store.authProfile.userId, "USERS_ACTIVATION")
                setActivatedCounter(counter.usersGetReward)
                setActivatedCounterLoaded(true)
            } catch (e) {
                throw e
            }
        }

        getCode()

    }, [Store.authProfile.userId])


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getReferralsList(Store.authProfile.userId, "REFERRAL_CODE", paginationObject.page, paginationObject.offset)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, total, countIsLoaded, Store.authProfile.userId])


    return <div className={s.wrap}>
        <h4 className={`${f.boldH4}`}>Реферальная программа</h4>
        <div className={s.content}>
            <div className={s.cards}>
                <div className={`${s.card} ${s.gradient}`}>
                    {
                        promoIsLoaded
                            ? <>
                                <p className={`${s.psub} ${f.boldH5}`}>PROMOCODE</p>
                                <h3 className={f.boldH3}>{
                                    codeNotFound
                                        ? 'Код не найден'
                                        : code
                                }</h3>
                            </>
                            : <Preloader/>
                    }
                </div>
                <div className={`${s.card} ${s.blue}`}>
                    {
                        countIsLoaded
                            ? <>
                                <img className={s.icon} src={RefIcon} alt=""/>
                                <div className={s.cardText}>
                                    <h3 className={f.boldH3}>
                                        <CountUp end={total} duration={.3}/>
                                    </h3>
                                    <p className={f.boldH5}>Пригласил</p>
                                </div>
                            </>
                            : <Preloader/>
                    }
                </div>
                <div className={`${s.card} ${s.grey}`}>
                    {
                        activatedCounterLoaded
                            ? <>
                                <img className={s.icon} src={MedalIcon} alt=""/>
                                <div className={s.cardText}>
                                    <h3 className={f.boldH3}>
                                        <CountUp end={activatedCounter} duration={.3}/>
                                    </h3>
                                    <p style={{textAlign: 'right'}} className={f.boldH5}>Получили <br/> награду</p>
                                </div>
                            </>
                            : <Preloader/>
                    }
                </div>
            </div>


            <div className={s.table}>
                {
                    isLoading
                        ? <Preloader type={'big'}/>
                        : <Table headsArray={['ID', 'ИМЯ', 'Действие']}
                                 gridTemplate={'100px 1fr 91px'}
                                 rowsArray={data.map((item) =>
                                     <TableRow item={item} key={item.id}/>)}/>
                }
                <Pagination
                    paginationObject={paginationObject}
                    setPaginationObject={setPaginationObject}
                    totalCount={total}
                />
            </div>
        </div>
    </div>
}

export default RefTable