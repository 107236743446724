import s from './SingleSkillRow.module.scss'
import f from '../../../../../static/fonts.module.scss'
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {useEffect, useState} from "react";

import {ReactComponent as EditIcon} from "../icon/editIcon.svg";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {
    blockTeacherCompetency,
    deleteCompetency,
    getCompetencyBlockReasons
} from "../../../../../services/CompetencyService";
import {userStore} from "../../UserInfo";
import Select from "../../../../_BASE/UI/Select/Select";
import Input from "../../../../_BASE/UI/Input/Input";
import {notifications} from "../../../../../index";

const SingleSkillRow = ({iconLink, subject, option, updateFunc, aggRelId}) => {
    const Store = userStore
    const [deleteModal, setDeleteModal] = useState(false);
    const [blockModal, setBlockModal] = useState(false);

    const actions = [
        {
            label: 'Заблокировать',
            func: () => setBlockModal(true)
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        }
    ]

    const DeleteModal = () => {
        return <ModalWindow closeFunc={setDeleteModal}
                            title={'Удаление компетенции'}
                            type={'small'}
                            primaryAction={async () => {
                                try {
                                    await deleteCompetency(
                                        Store.competencyProfile.profileId,
                                        Store.authProfile.userId,
                                        [aggRelId.toString()]
                                    )
                                    setDeleteModal(false)
                                    updateFunc()
                                } catch (e) {
                                    throw e
                                }
                            }
                            }
        >
            <p className={f.body1}
                // style={{maxWidth: 524, width: 524}}
            >Вы уверены, что хотите удалить компетенцию {" "}
                <span className={f.subtitle1Bold}>"{subject} — {option}"</span> ?</p>
        </ModalWindow>
    }

    const BlockModal = () => {
        const [comment, setComment] = useState('');
        const [blockReasons, setBlockReasons] = useState([])
        const [selectedReason, setSelectedReason] = useState(null);


        useEffect(() => {
            async function getData() {
                const data = await getCompetencyBlockReasons()
                const layout = data.map(({title, id}) => {
                    return <p key={id} dataId={id}>{title}</p>
                })
                setBlockReasons(layout)
            }

            getData()
        }, [])


        return <ModalWindow
            title={'Блокировка компетенции'}
            type={'small'}
            closeFunc={setBlockModal}
            primaryAction={async () => {
                if (!selectedReason) {
                    notifications.addNotification('Выберите причину блокировки')
                    return
                }
                if (comment.length < 10) {
                    notifications.addNotification('Укажите комментарий')
                    return
                }
                try {
                    await blockTeacherCompetency(
                        Store.competencyProfile.profileId,
                        selectedReason.props.dataId,
                        Store.competencyProfile.workCode === 'VUZ' ? 'TASK_TYPE' : 'CLASS',
                        aggRelId,
                        comment
                    )
                    await updateFunc()
                    setBlockModal(false)
                } catch (e) {
                    throw e
                }
            }
            }
        >
            <div>
                <Select title={'Укажите причину'} options={blockReasons}
                        selectedOption={selectedReason} setSelectedOption={setSelectedReason}/>
                <div style={{marginTop: 16}}>
                    <Input type={'textarea'}
                           value={comment} setValue={setComment}
                           title={'Укажите комментарий'}
                           styles={{maxWidth: 524, width: 524}}
                    />
                </div>
            </div>
        </ModalWindow>
    }


    return <>
        <img className={s.image} src={iconLink} alt=""/>
        <p className={f.subtitle1Bold}>{subject}</p>
        <p className={f.subtitle1Bold}>{option}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} nested_styles={{background: 'white'}} actions={actions} text={<EditIcon/>}/>
        </div>

        {
            deleteModal && <DeleteModal/>
        }

        {
            blockModal && <BlockModal/>
        }
    </>
}

export default SingleSkillRow