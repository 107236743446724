import s from './common.module.scss'
import f from '../../../../../static/fonts.module.scss'

import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useCallback, useEffect, useState} from "react";
import {getUserBlockInfo} from "../../../../../services/AuthService";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";

export const dateFormat = (dateString) =>{
    return  dateString.split('T')[0]
}

const UserBlockInfo = ({closeFunc, userId}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(false);


    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getUserBlockInfo(userId)
            setData(req.data)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }, [userId])

    useEffect(() => {
        getData()
    }, [getData])

    return <ModalWindow
        closeFunc={closeFunc}
        title={'Информация о блокировке'}
    >
        <div className={s.BlockUserInfoWrap}>
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <div className={s.info}>
                        <div className={s.block}>
                            <h5 className={f.boldH5}>Причина</h5>
                            <p className={f.subtitle1Bold}>{data.title}</p>
                        </div>
                        <div className={s.cols}>
                            <div className={s.block}>
                                <h5 className={f.boldH5}>Дата блокировки:</h5>
                                <p className={f.subtitle1Bold}>{dateFormat(data.blockingDate)}</p>
                            </div>
                            <div className={s.block}>
                                <h5 className={f.boldH5}>Конец блокировки:</h5>
                                <p className={f.subtitle1Bold}>{dateFormat(data.blockingTime)}</p>
                            </div>
                        </div>
                        <div className={s.block}>
                            <h5 className={f.boldH5}>Комментарий:</h5>
                            <p className={f.body1}>{data.title}</p>
                        </div>
                    </div>


            }
        </div>
    </ModalWindow>
}
export default UserBlockInfo