import React, {useEffect, useState} from "react";

import style from './BlockUserModal.module.scss'

import Button from "../../../_BASE/UI/Button/Button";
import {ReactComponent as DangerIcon} from "../icons/Danger.svg";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Select from "../../../_BASE/UI/Select/Select";
import Input from "../../../_BASE/UI/Input/Input";
import {banUser, getUserBanReasons} from "../../../../services/AuthService";
import {notifications} from "../../../../index";
import {userStore} from "../../UserInfo/UserInfo";

const BlockUserModal = ({closeModalFunc, userId}) => {
    const [blockComment, setBlockComment] = useState('')
    const [reason, setReason] = useState('')
    const [blockDate, setBlockDate] = useState('')
    const [blockReasons, setBlockReasons] = useState([]);

    useEffect(() => {
        async function getData() {
            const data = await getUserBanReasons()
            const layout = data.map(({title, id}) => {
                return <p key={id} dataId={id}>{title}</p>
            })
            setBlockReasons(layout)
        }

        getData()
    }, [])

    const handleBlockClick = async () => {
        try {
            if (reason.length === 0) {
                notifications.addNotification('Выберите причину блокировки')
            }
            if (blockDate.length === 0) {
                notifications.addNotification('Выберите дату блокировки')
            }
            if (blockComment.length === 0) {
                notifications.addNotification('Укажите комментарий')
            }
            if ((reason.length !== 0) && (blockDate.length !== 0) && (blockComment.length !== 0)) {
                await banUser(
                    Number(userId),
                    reason.props.dataId,
                    blockDate,
                    blockComment
                )
                await userStore.getAllData()
                closeModalFunc(false)
            }
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <ModalWindow
            title={`Блокировка`}
            closeFunc={closeModalFunc}
        >
            <div className={style.wrap}>
                <Select
                    title={'Выберите причину блокировки'}
                    options={blockReasons}
                    selectedOption={reason}
                    setSelectedOption={setReason}
                />
                <Input
                    title={'Выберите время блокировки'}
                    value={blockDate}
                    setValue={setBlockDate}
                    type={'date'}
                />
                <Input
                    type={'textarea'}
                    setValue={setBlockComment}
                    value={blockComment}
                    title={'Комментарий'}
                />
            </div>
            <Button
                text={"Заблокировать"}
                type={'alert'}
                icon={<DangerIcon/>}
                click={handleBlockClick}
            />
        </ModalWindow>

    )
}

export default BlockUserModal
