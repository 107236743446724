import {useEffect, useState} from "react";

import style from './OrdersTable.module.scss'
import fonts from '../../../../static/fonts.module.scss'
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import {userStore} from "../UserInfo";
import {getOrdersStatuses, getUserOrdersCount} from "../../../../services/OrdersService";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Table from "../../../_BASE/Layout/Table/Table";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";

const TableRow = ({orderId, orderTitle, subjectTitle, optionTitle, createDate}) => {
    const categories = [subjectTitle, optionTitle]


    const dateString = new Date(Number(createDate)).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })

    const actions = [{
        label: 'Просмотр',
        type: "link",
        link: `/orders/${orderId}`,
        target: "_blank"
    }]


    return <>
        <p className={fonts.subtitle1Bold}>{orderId}</p>
        <div className={style.tagContainer}>
            {
                categories.map((value) =>
                    <div className={style.tagWrap}>
                        <p className={`${fonts.body2} ${style.tag}`}>{value}</p>
                    </div>
                )
            }
        </div>
        <p className={fonts.body1}>{orderTitle}</p>
        <p className={fonts.body1}>{dateString}</p>
        <DropdownButton actions={actions} type={'icon'}/>
    </>
}

const OrdersTable = () => {
    const Store = userStore
    const [data, setData] = useState([]);
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 10
    });
    const [total, setTotal] = useState(0);
    const [currentStatus, setCurrentStatus] = useState('ALL_ORDER');
    const [currentStatusLabel, setCurrentStatusLabel] = useState('Все заказы');
    const [statuses, setStatuses] = useState([])

    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);


    useEffect(() => {
        const getAllStatuses = async () => {
            try {
                const req = await getOrdersStatuses()
                req.data.push({id: 999, title: "Все заказы", code: "ALL_ORDER"})
                const statusActions = req.data.map(({title, code}) => {
                    return {
                        label: title,
                        func: () => {
                            setCurrentStatus(code)
                            setCurrentStatusLabel(title)
                            setPaginationObject({
                                page: 1,
                                offset: 10
                            })
                        }
                    }
                })
                setStatuses(statusActions)
            } catch (e) {
                throw e
            }
        }

        getAllStatuses()
    }, [])

    useEffect(() => {
        if (Store.competencyProfile) {
            const getCount = async () => {
                try {
                    console.log(currentStatus)
                    const req = await getUserOrdersCount(
                        Store.competencyProfile.profileId,
                        currentStatus,
                        Store.authProfile.userRoleTitleCode,
                    )
                    // const req = await Store.getTotalUserOrders(currentStatus)
                    setTotal(req)

                } catch (e) {
                    console.log(e)
                }
            }

            getCount()
        }
    }, [currentStatus])


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await Store.getUserOrders(paginationObject.page, paginationObject.offset, currentStatus)
                setData(req.data)
                setIsLoading(false)
            } catch (e) {
                console.log(e)
            }
        }

        getData()

    }, [paginationObject.page, paginationObject.offset, total, currentStatus])


    return (
        <div>
            <div className={style.headerLine}>
                <h4 className={`${fonts.boldH4}`}>Заказы</h4>
                <div className={style.typeSelectorWrap}>
                    <p className={fonts.subtitle1Bold}>Выбор статуса заказа:</p>
                    <DropdownButton actions={statuses} text={currentStatusLabel} type={'secondary'}/>
                </div>
            </div>
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <div className={style.table}>
                        <Table gridTemplate={'1fr 1fr 1fr 1fr 91px'}
                               headsArray={[
                                   'Номер заказа',
                                   'Категории',
                                   'Заголовок задачи',
                                   'Дата создания',
                                   'Действия'
                               ]}
                               rowsArray={data.map(({orderId, orderTitle, subjectTitle, optionTitle, createDate}) =>
                                   <TableRow
                                       orderId={orderId}
                                       orderTitle={orderTitle}
                                       subjectTitle={subjectTitle}
                                       optionTitle={optionTitle}
                                       createDate={createDate}
                                       key={orderId}
                                   />
                               )}/>
                        <Pagination
                            paginationObject={paginationObject}
                            setPaginationObject={setPaginationObject}
                            totalCount={total} offsetSteps={[10, 25, 50]}
                        />
                    </div>
            }
        </div>
    )
}

export default OrdersTable