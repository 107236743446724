import s from "./PersonaInfo.module.scss";
import f from "../../../../../static/fonts.module.scss";

import {ReactComponent as Edit} from "../icon/Edit.svg";

import EditFioModal from "../Modals/EditFio";
import EditPhoneModal from "../Modals/EditPhone";
import EditEmailModal from "../Modals/EditEmail";
import EditDateModal from "../Modals/EditDate";
import EditGenderModal from "../Modals/EditGender";
import TextPreloader from "../../../../_BASE/UI/Preloader/TextPreloader/TextPreloader";

import {useState} from "react";


const PersonaInfo = ({Store}) => {
    const [editFioModal, setEditFioModal] = useState(false)
    const [editPhoneModal, setEditPhoneModal] = useState(false)
    const [editEmailModal, setEditEmailModal] = useState(false)
    const [editDOBModal, setEditDOBModal] = useState(false)

    const [editGenderModal, setEditGenderModal] = useState(false);


    return <div className={s.detailsWrap}>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>Идентификатор аккаунта</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <p className={f.subtitle1Bold}>
                            {Store.authProfile.userId}
                        </p>
                        : <TextPreloader/>
                }
            </div>
        </div>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>ФИО</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <>
                            <p className={f.subtitle1Bold}>{Store.authProfile.surname} {Store.authProfile.name} {Store.authProfile.middlename}</p>
                            <button
                                className={s.detailsEditButton}
                                onClick={() => setEditFioModal(true)}
                            >
                                <Edit/>
                            </button>
                        </>
                        : <TextPreloader/>
                }
            </div>
        </div>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>Дата рождения</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <>
                            <p className={f.subtitle1Bold}>{Store.authProfile.dob}</p>
                            <button
                                className={s.detailsEditButton}
                                onClick={() => setEditDOBModal(true)}
                            >
                                <Edit/>
                            </button>
                        </>
                        : <TextPreloader/>
                }

            </div>
        </div>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>Пол</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <>
                            <p className={f.subtitle1Bold}>{Store.authProfile.gender}</p>
                            <button
                                className={s.detailsEditButton}
                                onClick={() => setEditGenderModal(true)}
                            >
                                <Edit/>
                            </button>
                        </>
                        : <TextPreloader/>
                }
            </div>
        </div>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>Телефон</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <>
                            <p className={f.subtitle1Bold}>{Store.authProfile.phone}</p>
                            <button
                                className={s.detailsEditButton}
                                onClick={() => setEditPhoneModal(true)}
                            >
                                <Edit/>
                            </button>
                        </>
                        : <TextPreloader/>
                }
            </div>
        </div>
        <div className={s.detailItem}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <p className={`${f.subtitle2Light} ${s.coloredText}`}>E-mail</p>
                {
                    !Store.authProfile.isActivated &&
                    <span className={`${f.overline} ${s.emailConfirm}`}>НЕ ПОДТВЕРЖДЁН</span>
                }
            </div>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <>
                            <p className={f.subtitle1Bold}>{Store.authProfile.email}</p>
                            <button
                                className={s.detailsEditButton}
                                onClick={() => setEditEmailModal(true)}
                            >
                                <Edit/>
                            </button>
                        </>
                        : <TextPreloader/>
                }
            </div>
        </div>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>Дата регистрации</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <p className={f.subtitle1Bold}>{Store.authProfile.registrationDate}</p>
                        : <TextPreloader/>
                }
            </div>
        </div>
        <div className={s.detailItem}>
            <p className={`${f.subtitle2Light} ${s.coloredText}`}>Время последней активности</p>
            <div className={s.detailsEditWrap}>
                {
                    Store.isLoaded
                        ? <p className={f.subtitle1Bold}>Был в
                            сети {new Date(Number(Store.authProfile.lastActivityTime)).toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit',
                            })}</p>
                        : <TextPreloader/>
                }
            </div>
        </div>

        {
            editFioModal && <EditFioModal closeFunc={setEditFioModal} Store={Store}/>
        }
        {
            editPhoneModal && <EditPhoneModal closeFunc={setEditPhoneModal} Store={Store}/>
        }
        {
            editEmailModal && <EditEmailModal closeFunc={setEditEmailModal} Store={Store}/>
        }
        {
            editDOBModal && <EditDateModal closeFunc={setEditDOBModal} Store={Store}/>
        }
        {
            editGenderModal && <EditGenderModal closeFunc={setEditGenderModal} Store={Store}/>
        }


    </div>
}

export default PersonaInfo
