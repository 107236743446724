import s from '../SkillsTable.module.scss'
import f from '../../../../../static/fonts.module.scss'
import {useCallback, useEffect, useState} from "react";
import {getTeacherBlockedCompetencies, unblockTeacherCompetency} from "../../../../../services/CompetencyService";
import {userStore} from "../../UserInfo";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {ReactComponent as EditIcon} from "../icon/editIcon.svg";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Table from "../../../../_BASE/Layout/Table/Table";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";

const BlockedTable = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const Store = userStore

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getTeacherBlockedCompetencies(Store.competencyProfile.profileId)
            setData(req)
        } catch (e) {
            throw e
        } finally {
            setIsLoading(false)
        }
    },[Store.competencyProfile.profileId])

    useEffect(() => {
        getData()
    }, [getData])

    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.table}>
        <Table rowsArray={data.map((data) => <TableRow
            key={data.blockRecordId}
            data={data}
            getData={getData}
        />)}
               gridTemplate={'40px 1fr 1fr 2fr 3fr 120px 91px'}
               headsArray={['', 'ПРЕДМЕТ', "Опция", "Причина", "Комментарий", "Дата блокировки", "Действие"]}/>
    </div>
}

const TableRow = ({data, getData}) => {

    const [unblockModalIsVisible, setUnblockModalIsVisible] = useState(false);


    const UnblockModal = () => {
        const handleClick = async () => {
            try {
                await unblockTeacherCompetency(data.blockRecordId)
                await getData()
                setUnblockModalIsVisible(false)
            } catch (e) {
                throw e
            }
        }
        return <ModalWindow
            closeFunc={setUnblockModalIsVisible}
            title={'Разблокировать компетенцию'}
            type={'small'}
            primaryAction={() => handleClick()}
        >
            <p className={f.body1}>Вы уверены, что хотите разблокировать компетенцию {" "}
                <span className={f.subtitle1Bold}>"{data.competencySubjectTitle} — {data.competencyOpTitle}"</span>
                ?
            </p>
        </ModalWindow>
    }

    const actions = [{
        label: 'Разблокировать',
        func: () => setUnblockModalIsVisible(true)
    }]

    return <>
        <img className={s.avatar} src={data.competencySubjectIconLink} alt=""/>
        <p className={f.subtitle1Bold}>{data.competencySubjectTitle}</p>
        <p className={f.subtitle1Bold}>{data.competencyOpTitle}</p>
        <p className={f.subtitle1Bold}>{data.blockReasonTitle}</p>
        <p className={f.subtitle1Bold}>{data.comment}</p>
        <p className={f.subtitle1Bold}>{data.blockingDate}</p>
        <div style={{display: 'flex', justifyContent: "center"}}>
            <DropdownButton type={'icon'} nested_styles={{background: 'white'}} actions={actions}
                            text={<EditIcon/>}/>
        </div>

        {unblockModalIsVisible && <UnblockModal/>}
    </>
}

export default BlockedTable
