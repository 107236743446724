import style from './Button.module.scss'
import fonts from '../../../../static/fonts.module.scss'

const Button = ({type, text, icon, click, size, disabled, nestedStyles}) => {
    return (
        <button
            disabled={disabled}
            className={`
                ${style.button} 
                ${fonts.button} 
                ${type ? style[type] : style.main}
                ${icon ? style.smallPadding : ''}
                ${size === 'small' ? style.small : ''}
                ${disabled ? style.disabled : ''}               
            `}
            onClick={click}
            style={nestedStyles}
        >
            {
                icon
                    ? <div className={style.icon}>{icon}</div>
                    : null
            }
            {text}
        </button>
    )
}

export default Button