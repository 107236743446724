import $auth from '../http/index'
import {AxiosResponse} from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import {notifications} from "../index";

const md5 = require('md5')

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $auth.post<AuthResponse>('/v1/account/signin', {email, password})
    }

    static async logout(): Promise<void> {
        return $auth.post('/v1/account/signout')
    }
}

export const registerNewUser = async (data: any) => {
    try {
        return await $auth.post('/v1/account/signup', {
            roleId: data.roleId,
            name: data.name,
            surname: data.surname ? data.surname : null,
            middlename: data.middlename ? data.middlename : null,
            phone: data.phone,
            email: data.email,
            password: md5(data.password),
            dob: data.dob,
            gender: data.gender
        })
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })

    }
}

//Получение информации о пользователе
export const getUserInfo = async (id: number) => {
    try {
        return await $auth.get(`/v1/account/user/${id}`)
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
        if(e.response.data.errors){
            e.response.data.errors.forEach((er: any) => {
                console.log(er)
                notifications.addNotification(er.msg)
            })
        }
        throw  e
    }
}

//Получение информации о причине блокировке пользователя
export const getUserBlockInfo = async (id: number) => {
    try {
        return await $auth.get(`/v1/account/block/status?id=${id}`)
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}


//Получение количества пользователей
export const getUsersCount = async (roleTitle: string, status: string) => {
    try {
        const res = await $auth.get(`/v1/account/list/number/?roleId=${roleTitle}&offset=20&status=${status}`)
        return res.data
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Получение списка пользователей
export const getUsersList = async (roleId: string, page: number, queryOffset: number, status: string) => {
    try {
        const res = await $auth.get(`/v1/account/list/?roleId=${roleId}&page=${page}&offset=${queryOffset}&status=${status}`)
        return res.data
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Получение количества заблокированных пользователей
export const getBlockedUsersCount = async (roleTitle: string, reasonId: number, firstDate: string, secondDate: string) => {
    try {
        const res = await $auth.get(`/v1/account/list/block`, {
            params: {
                roleId: roleTitle,
                code: 'NUMBER_OF_PAGE',
                offset: 20,
                reasonId: reasonId ? reasonId : null,
                firstDate: firstDate ? firstDate : null,
                secondDate: secondDate ? secondDate : null
            }
        })
        return res.data
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Получени списка заблокированных пользователей
export const getBlockedUsersList = async (roleTitle: string, page: number, offset: number, reasonId: number, firstDate: string, secondDate: string) => {
    try {
        const res = await $auth.get(`/v1/account/list/block`, {
            params: {
                roleId: roleTitle,
                code: 'LIST',
                page: page,
                offset: offset,
                reasonId: reasonId ? reasonId : null,
                firstDate: firstDate ? firstDate : null,
                secondDate: secondDate ? secondDate : null
            }
        })
        return res.data
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Поиск пользователей
export const getSearchUsers = async (roleId: string, type: string, message: string) => {
    try {
        const res = await $auth.get(`/v1/account/search/?roleId=${roleId}&type=${type}&message=${message}`)
        return res.data
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Изменение профиля пользователя
export const updateUserAuthProfile = async (updateCode: string, array: object, userId: number) => {
    try {
        const res = await $auth.post(`/v1/account/update`, {
            updateCode: updateCode,
            array: array,
            userId: userId
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}


//Изменить аватар в аккаунте
export const updateUserAvatar = async (userId: number, avatarId: number, avatarLink: string) => {
    try {
        const res = await $auth.post(`/v1/account/update/avatar`, {
            userId: userId,
            avatarId: avatarId,
            avatarLink: avatarLink
        })
        notifications.addNotification(res.data.status, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Получить список причин блокировки пользователя
export const getUserBanReasons = async () => {
    try {
        const res = await $auth.get(`/v1/account/block/reasons`)
        return res.data
    } catch (e: any) {
        console.log(e.response.data)
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er: any) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}

//Создание новой причины блокировки пользователя
export const createUserBanReason = async (title: string) => {
    try {
        const res = await $auth.post(`/v1/account/block/reasons/create`, {
            title: title
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
    }
}

//Создание новой причины блокировки пользователя
export const updateUserBanReason = async (id: number, title: string) => {
    try {
        const res = await $auth.post(`/v1/account/block/reasons/update`, {
            title: title,
            id: id
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
    }
}

//Архивирование причины блокировки пользователя
export const deleteUserBanReason = async (id: number) => {
    try {
        const res = await $auth.post(`/v1/account/block/reasons/archive/${id}`)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
    }
}

//Блокировка пользователей
export const banUser = async (id: number, reasonId: number, blockingTime: string, comment: string) => {
    try {
        const res = await $auth.post(`/v1/account/block`, {
            userId: id,
            reasonId: reasonId,
            blockingTime: blockingTime,
            comment: comment
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
    }
}


//Разблокировка пользователей
export const unbanUser = async (id: number) => {
    try {
        const res = await $auth.post(`/v1/account/unblock`, {
            userId: id,
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
    }
}

//Удаление аккаунта
export const deleteUser = async (id: number) => {
    try {
        const res = await $auth.delete(`/v1/account/user?id=${id}`)
        notifications.addNotification(res.data.message, 'OK')
    } catch (e: any) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}
