import {makeAutoObservable} from "mobx";
import {notifications} from "../index";
import {uploadImage} from "../services/FileService";


export default class ChatsStore {
    chats = new Map();
    selectedChatId = null
    selectedData = null
    activeMessage = {
        text: '',
        attachments: []
    }
    myId = null
    MessageIsSending = false
    chatCards = []


    constructor() {
        makeAutoObservable(this)
    }

    reset() {
        this.chats = new Map();
        this.selectedChatId = null
        this.selectedData = null
    }

    setCurrentMessage(message) {
        this.activeMessage = message
    }


    setNewTextMessage(m) {
        this.activeMessage.text = m
    }

    setSelectedChatId(chatId) {
        this.selectedChatId = chatId
        if (chatId) {
            this.selectedData = this.chats.get(chatId).data
            const selectedChat = this.chats.get(chatId)
            this.chats.set(chatId, {
                ...selectedChat,
                newMessagesCounter: 0
            })
        }
    }

    setChatCards() {
        this.chatCards = Array.from(this.chats.values())
    }

    setNewChat(chatData, chatId) {
        this.chats.set(chatId, chatData)
        this.setChatCards()
    }

    deleteChatById(chatId) {
        this.chats.get(chatId)?.socket.removeAllListeners()
        this.chats.delete(chatId)
        this.setChatCards()
    }

    setMyId(id) {
        this.myId = id
    }

    //Загружаем сообщения после установки соединения
    loadMessages(messages, chatId) {
        //Считаем новые
        const newMessages = messages.filter((message) => {
            if (message.senderId !== this.myId) {
                return !message.readByRecipients.read
            } else return false
        }).length

        const prevValue = this.chats.get(chatId)
        if (prevValue.messages) {
            this.chats.set(chatId, {
                ...prevValue,
                messages: [...messages, ...prevValue.messages],
                newMessagesCounter: newMessages
            })
        } else {
            this.chats.set(chatId, {
                ...prevValue,
                messages: messages,
                newMessagesCounter: newMessages
            })
        }
    }

    //Добавляем в массив новые сообщения
    addNewMessage(message, chatId) {
        const prevValue = this.chats.get(chatId)
        if (this.selectedChatId !== chatId) {
            this.chats.set(chatId, {
                ...prevValue,
                messages: [...prevValue.messages, {...message, readByRecipients: {read: false}}],
                newMessagesCounter: 1
            })
        } else {
            this.chats.set(chatId, {
                ...prevValue,
                messages: [...prevValue.messages, {...message, readByRecipients: {read: false}}],
            })
        }
    }

    //Добавляем в массив собственные сообщения
    addMyMessage(message, chatId) {
        const prevValue = this.chats.get(chatId)
        this.chats.set(chatId, {
            ...prevValue,
            messages: [...prevValue.messages, {...message, readByRecipients: {read: false}}]
        })
    }

    //Изменяем статус сообщения на прочитано
    readMessageFromList(messageId, chatId) {
        this.chats.get(chatId).messages.filter((message) => messageId === message.id)[0].readByRecipients.read = true
    }

    //Удаляем сообщение из локльного массива при успешном ответе об удалении
    removeMessageFromList(messageId, chatId) {
        const thisChat = this.chats.get(chatId)
        const newArr = thisChat.messages.filter((message) => message.id !== messageId)
        this.chats.set(chatId, {...thisChat, messages: newArr})

        notifications.addNotification('Сообщение успешно удалено', "OK")
    }

    //Изменяем сообщение из локльного массива при успешном ответе об удалении
    editMessageFromList(messageId, newMessage, chatId) {
        const thisChat = this.chats.get(chatId)
        const newArr = []
        thisChat.messages.forEach((messageItem) => {
            if (messageItem.id === messageId) {
                newArr.push({...messageItem, message: newMessage})
            } else {
                newArr.push(messageItem)
            }
        })
        this.chats.set(chatId, {...thisChat, messages: newArr})

        notifications.addNotification('Сообщение успешно изменено', "OK")
    }

    addFileToQueue(file) {
        this.activeMessage.attachments.push(file)
    }

    removeAttachmentFromQueue(index) {
        this.activeMessage.attachments.splice(index, 1)
    }


    sendMessage = async (message) => {
        this.MessageIsSending = true
        try {
            //Простое текстовое
            if (message.attachments?.length === 0) {
                if (message.length === 0) {
                    notifications.addNotification('Сообщение не должно быть пустым')
                    return
                }
                const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                const regex = new RegExp(expression);

                if(message.text.match(regex)){
                    this.chats.get(this.selectedChatId).socket.emit("chat:message:create", {
                        type: "LINK", message: message.text
                    });
                } else {
                    this.chats.get(this.selectedChatId).socket.emit("chat:message:create", {
                        type: "TEXT", message: message.text
                    });
                }

            } else { //С вложениями
                const uploadedLinksArray = []
                for (let attachment of message.attachments) {
                    const upload = await uploadImage(attachment.object)
                    uploadedLinksArray.push(upload.fileLink)
                }
                const messageType = message.attachments[0].object.type === 'application/pdf' ? "DOC" : "IMAGE"
                this.chats.get(this.selectedChatId).socket.emit("chat:message:create", {
                    type: messageType, message: message.text, attachments: uploadedLinksArray
                });
            }
            this.activeMessage.attachments = []
            this.setNewTextMessage('')
        } catch (e) {
            throw e
        } finally {
            this.MessageIsSending = false
        }
    }

    deleteMessage = (messageId) => {
        try {
            this.chats.get(this.selectedChatId).socket.emit("chat:message:remove", {
                id: messageId,
            });
        } catch (e) {
            throw e
        }
    }

    editMessage = (message, messageId, type, attachments, chatId = this.selectedChatId) => {
        try {
            if (message.length === 0) {
                notifications.addNotification('Сообщение не должно быть пустым')
                return
            }
            this.chats.get(chatId).socket.emit("chat:message:edit", {
                type: type,
                message: message,
                id: messageId,
                attachments: type === 'TEXT' ? null : attachments?.map(({link}) => link)
            });
        } catch (e) {
            throw e
        }
    }

    readMessage(messageId) {
        try {
            this.chats.get(this.selectedChatId).socket.emit("chat:message:read", {
                id: messageId
            });
        } catch (e) {
            throw e
        }
    }
}
