import {useState} from "react";
import {userStore} from "../../UserInfo";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import s from "../Profile.module.scss";
import Radios from "../../../../_BASE/UI/Radios/Radios";
import {ReactComponent as SchoolIcon} from "../../../UserList/CreateNewStudentModal/icon/School.svg";
import {ReactComponent as StudentIcon} from "../../../UserList/CreateNewStudentModal/icon/Student.svg";

const CreateCompetencyProfileModal = ({closeFunc}) => {
    const [type, setType] = useState(null)
    const Store = userStore


    return <ModalWindow title={'Выберите профиль компетенций'}
                        type={'small'}
                        closeFunc={closeFunc}
                        primaryAction={async () => {
                            await Store.registerCompetencyProfile(type)
                            await Store.getUserCompetencyProfile()
                            closeFunc(false)
                        }}
                        primaryButtonIsDisabled={!type}
    >
        <div className={s.registerCompetencyModal}>
            <Radios
                data={[
                    {
                        title: Store.authProfile.userRoleTitleCode === 'STUDENT' ? 'Школьник' : 'Школьный учитель',
                        icon: <SchoolIcon/>,
                        value: 'SCHOOL'
                    },
                    {
                        title: Store.authProfile.userRoleTitleCode === 'STUDENT' ? 'Студент' : 'Преподаватель ВУЗа',
                        icon: <StudentIcon/>,
                        value: 'VUZ'
                    }]
                }
                checkedValue={type}
                setCheckedValue={setType}
            />
        </div>
    </ModalWindow>
}

export default CreateCompetencyProfileModal
