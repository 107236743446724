import s from './OrderModal.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import {getOrder} from "../../../../services/OrdersService";
import {getAllMessages} from "../../../../services/ChatService";
import {getReviewsByOrder} from "../../../../services/ReviewsService";

import {statusTag} from "../../OrderList/TableRow/TableRow";

import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import UserCard from "../UserCard/UserCard";
import ChatList from "../ChatList/ChatList";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Fancybox from "../../../_BASE/UI/Fancybox/Fancybox";

import style from "../../OrderList/TableRow/TableRow.module.scss";
import fonts from "../../../../static/fonts.module.scss";
import {ReactComponent as Star} from "./Star.svg";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";

export    const getDate = (timestamp) => {
    return new Date(Number(timestamp)).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    })
}


const OrderModal = ({closeFunc, orderId}) => {
    const [data, setData] = useState([]);
    const [messages, setMessages] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [chatIsLoading, setChatIsLoading] = useState(true);
    const [chatNotfound, setChatNotFound] = useState(false);

    const [reviews, setReviews] = useState();
    const [reviewsLoading, setReviewsLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const req = await getOrder(orderId)
                setData(req)
            } catch (e) {
                throw e
            }
        }

        const getMessages = async () => {
            try {
                setChatIsLoading(true)
                const chat = await getAllMessages("ORDER", orderId)
                setMessages(chat)
            } catch (e) {
                setChatNotFound(true)
                throw e
            } finally {
                setChatIsLoading(false)
            }
        }

        const getReviews = async () => {
            try {
                setReviewsLoading(true)
                const req = await getReviewsByOrder(orderId)
                setReviews(req)
                setReviewsLoading(false)
            } catch (e) {
                throw e
            }
        }

        const runAsync = async () => {
            try {
                setIsLoading(true)
                await getData()
                await getMessages()
                await getReviews()
            } catch (e) {
                throw e
            } finally {
                setIsLoading(false)
            }
        }

        runAsync()
    }, [orderId])




    return <ModalWindow
        closeFunc={closeFunc}
        title={`Заказ ${orderId}`}
    >
        <div className={s.wrap}>

            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <div className={s.infoColumn}>
                        <div className={s.users}>
                            <UserCard userData={data.studentInfo} userId={data?.studentInfo?.userId}
                                      style={{background: "#E6F1FF"}}/>
                            <UserCard userData={data.teacherInfo} userId={data?.teacherInfo?.userId}/>
                        </div>
                        <h5 className={f.boldH5}>Информация о заказе</h5>
                        <div className={s.stats}>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Статус заказа:</h6>
                                {statusTag(data.orderStatusTitle)}
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Индентификатор заказа:</h6>
                                <div className={`${f.boldH6} `}>{orderId}</div>
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Категории заказа:</h6>
                                <div className={style.tagContainer}>
                                    <div className={style.tagWrap}>
                                        <p className={`${fonts.subtitle1Bold} ${style.tag}`}>{data.competency?.subjectTitle}</p>
                                    </div>
                                    <div className={style.tagWrap}>
                                        <p className={`${fonts.subtitle1Bold} ${style.tag}`}>{data.competency?.subjectOption}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Стоимость заказа:</h6>
                                <div className={`${f.boldH6}`}>{data.price} ₽</div>
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Дата создания заказа:</h6>
                                <div className={`${f.boldH6}`}>{getDate(data.createDate)}</div>
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Дата закрытия заказа:</h6>
                                <div className={`${f.boldH6}`}>{getDate(data.statusChangeDate)}</div>
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Срок сдачи от заказчика:</h6>
                                <div className={`${f.boldH6}`}>{getDate(data.deadline)}</div>
                            </div>
                            {
                                data.warrantyEndDate &&
                                <div className={s.infoRow}>
                                    <h6 className={f.boldH6}>Дата истечения гарантии:</h6>
                                    <div className={`${f.boldH6}`}>{getDate(data.warrantyEndDate)}</div>
                                </div>
                            }
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Оригинальность работы:</h6>
                                <div
                                    className={`${f.boldH6}`}>{data.originality ? data.originality : 'Не указано'}</div>
                            </div>
                            <div className={s.infoRow}>
                                <h6 className={f.boldH6}>Количество задач:</h6>
                                <div
                                    className={`${f.boldH6}`}>{data.taskNumber ? data.taskNumber : 'Не указано'}</div>
                            </div>
                            <div className={s.infoColumn}>
                                <h6 className={f.boldH6}>Заголовок задания:</h6>
                                <div className={`${f.subtitle1Bold}`}>{data.orderTitle}</div>
                            </div>
                        </div>
                        <div className={s.attachmentWrap}>
                            <div className={s.attTabs}>
                                <button
                                    className={`${f.boldH6} ${s.tabButton} ${activeTab === 0 ? s.active : ''}`}
                                    onClick={() => setActiveTab(0)}
                                > Описание задания
                                </button>
                                <button
                                    className={`${f.boldH6} ${s.tabButton} ${activeTab === 1 ? s.active : ''}`}
                                    onClick={() => setActiveTab(1)}
                                > Файлы задания
                                </button>

                            </div>
                            <div className={s.attContent}>
                                {
                                    activeTab === 0 && <p className={f.body1}>
                                        {data.orderComment}
                                    </p>
                                }

                                {
                                    activeTab === 1 && <Fancybox>
                                        <div className={s.attFiles}>
                                            {
                                                data?.imageLinkArray.map((url) => {
                                                    return <div key={url}
                                                                data-fancybox="gallery"
                                                                data-src={url}
                                                                className={s.imgItem}>
                                                        <img className={s.modalImgPrev} src={url} alt=""/>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </Fancybox>
                                }
                            </div>
                            {
                                reviewsLoading
                                    ? <Preloader/>
                                    : <div className={s.reviewsWrap}>
                                        <h5 className={`${f.boldH5}`}>Отзывы</h5>
                                        <div className={s.reviews}>
                                            {
                                                (reviews?.studentReview.status || reviews?.teacherReview.status)
                                                    ? <div className={s.infoRows}>
                                                        {
                                                            reviews.studentReview?.status &&
                                                            <div className={s.reviewBlock}>
                                                                <div className={s.titleRow}>
                                                                    <p className={f.subtitle1Bold}>Заказчик</p>
                                                                    <div className={s.rate}>
                                                                        <Star/>
                                                                        <p className={f.subtitle1Bold}>{reviews.studentReview.message.rating} из
                                                                            5</p>
                                                                    </div>
                                                                </div>
                                                                <p className={f.body1}>
                                                                    {reviews.studentReview.message.comment}
                                                                </p>
                                                                <p className={f.body2}>
                                                                    {
                                                                        new Date(reviews.studentReview.message.createDate).toLocaleDateString('ru-RU', {
                                                                            year: 'numeric',
                                                                            month: '2-digit',
                                                                            day: '2-digit',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            second: '2-digit',
                                                                        })
                                                                    }
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            reviews.teacherReview?.status &&
                                                            <div className={s.reviewBlock}>
                                                                <div className={s.titleRow}>
                                                                    <p className={f.subtitle1Bold}>Исполнитель</p>
                                                                    <div className={s.rate}>
                                                                        <Star/>
                                                                        <p className={f.subtitle1Bold}>{reviews.teacherReview.message.rating} из
                                                                            5</p>
                                                                    </div>
                                                                </div>
                                                                <p className={f.body1}>
                                                                    {reviews.teacherReview.message.comment}
                                                                </p>
                                                                <p className={f.body2}>
                                                                    {
                                                                        new Date(reviews.teacherReview.message.createDate).toLocaleDateString('ru-RU', {
                                                                            year: 'numeric',
                                                                            month: '2-digit',
                                                                            day: '2-digit',
                                                                            hour: '2-digit',
                                                                            minute: '2-digit',
                                                                            second: '2-digit',
                                                                        })
                                                                    }
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                    : <p className={f.subtitle1Bold}>Отзывы отсутствуют</p>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
            }
            <ErrorBoundary>
                {
                    chatIsLoading
                        ? <Preloader type={'big'}/>
                        : <div className={s.chat}>
                            {
                                chatNotfound
                                    ? <p className={fonts.subtitle1Bold}>Чат не найден</p>
                                    : <ChatList data={messages} rightUserId={data.teacherInfo.userId} readOnly/>
                            }
                        </div>
                }
            </ErrorBoundary>
        </div>
    </ModalWindow>
}

export default OrderModal
