import style from './RatingModal.module.scss'

import {useState} from "react";

import Button from "../../../_BASE/UI/Button/Button";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import {updateRating} from "../../../../services/ReviewsService";
import {notifications} from "../../../../index";
import {userStore} from "../../UserInfo/UserInfo";

const RatingModal = ({closeModalFunc, competencyProfileId, userCode}) => {
    const [amount, setAmount] = useState(0);
    const [comment, setComment] = useState('');
    const Store = userStore

    const handleChangeRating = async (code) => {
        const anum = Number(amount)

        if (!((0.1 <= anum) && (anum <= 4.5))) {
            notifications.addNotification('Величина изменения должна быть в диапазоне от 0,1 до 4,5')
            return
        }
        if (comment.length < 10) {
            notifications.addNotification('Укажите причину изменений')
            return
        }
        try {
            await updateRating(userCode, competencyProfileId, code, amount, comment)
            notifications.addNotification('Рейтинг успешно обновлен', 'OK')
            await Store.getUserAuthProfile()
            await Store.getUserCompetencyProfile()
            closeModalFunc(false)
        } catch (e) {
            throw e
        }
    }

    return (
        <ModalWindow
            title={`Изменение рейтинга`}
            closeFunc={closeModalFunc}
        >
            <div className={style.wrap}>
                <Input value={amount} setValue={setAmount}
                       title={'Величина изменения'}
                       placeholder={'0,1'}
                       styles={{width: 100}}
                       type={'number'}
                       step={'0.1'}
                       min={0.1}
                       max={4.5}
                />
                <Input value={comment} setValue={setComment}
                       title={'Причина изменения'}
                       type={'textarea'}
                       styles={{maxWidth: "100%"}}
                />
            </div>
            <div className={style.buttonsWrap}>
                <Button
                    text={"Снизить рейтинг"}
                    type={'alert'}
                    click={() => handleChangeRating('DOWNGRADE')}
                />
                <Button
                    text={"Повысить рейтинг"}
                    type={'main'}
                    click={() => handleChangeRating('UPGRADE')}
                />
            </div>
        </ModalWindow>

    )
}

export default RatingModal
