import $auth from "../http";

//Получение логов пользователя
export const getLogsByUserId = async (id, page, offset) => {
    try {
        const res = await $auth.get(`/v1/logs/list/${id}?page=${page}&offset=${offset}`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Получить количество страниц логов пользователя
export const getLogsCount = async (id) => {
    try {
        const res = await $auth.get(`/v1/logs/pages/${id}?offset=20`)
        return res.data
    } catch (e) {
        throw e
    }
}