import s from './Profile.module.scss'
import f from '../../../../static/fonts.module.scss'

import {userStore} from "../UserInfo";
import {observer} from "mobx-react-lite";
import {useCallback, useEffect, useState} from "react";
import {unbanUser} from "../../../../services/AuthService";
import {changeAgentStatus, getAgentProfile} from "../../../../services/SupportService";

import {ReactComponent as ShieldDone} from "./icon/Shield Done.svg";
import {ReactComponent as ShieldFail} from "./icon/Shield Fail.svg";
import {ReactComponent as Wallet} from "./icon/Wallet.svg";
import {ReactComponent as Document} from "./icon/Document.svg";
import {ReactComponent as Lock} from "./icon/Lock.svg";
import {ReactComponent as Arrow} from "./icon/Arrow - Right 2.svg";
import {ReactComponent as Delete} from "./icon/Delete.svg";
import {ReactComponent as BlockInfo} from "./icon/Info.svg";
import {ReactComponent as Star} from "./icon/Star.svg";

import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import TextPreloader from "../../../_BASE/UI/Preloader/TextPreloader/TextPreloader";
import Button from "../../../_BASE/UI/Button/Button";
import BlockUserModal from "../../UserList/BlockUserModal/BlockUserModal";
import {getWalletBalance} from "../../../../services/PaymentService";
import {notifications} from "../../../../index";
import EditAvatarModal from "./Modals/EditAvatar";
import CreateCompetencyProfileModal from "./Modals/CreateCompetencyProfile";
import DocumentModal from "./Modals/DocumentsModal";
import DeleteUser from "./Modals/DeleteUser";
import PersonaInfo from "./PersonaInfo/PersonaInfo";
import UserBlockInfo from "./Modals/UserBlockInfo";
import PaymentModal from "./Modals/PaymentModal/PaymentModal";
import RatingModal from "../../UserList/RatingModal/RatingModal";

const SupportActiveTag = ({userId}) => {
    const [status, setStatus] = useState();
    const [counter, setCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getAgentProfile(userId)
            setStatus(req.status)
            setCounter(req.amount)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [userId])

    useEffect(() => {
        getData()
    }, [getData])

    const updateStatus = async () => {
        try {
            await changeAgentStatus(userId)
            await getData()
        } catch (e) {
            throw e
        }
    }


    return <div className={s.supportTag}>
        <div className={s.suppCounter}>
            <p className={f.subtitle1Bold}>Всего закрыто обращений</p>
            {
                isLoading
                    ? <TextPreloader/>
                    : <h4 className={f.boldH4}>{counter}</h4>
            }
        </div>
        <div className={s.teacherStatusWrap}>
            {
                !isLoading &&
                <div
                    className={`${f.subtitle2Bold} ${s.statusIndicator} ${status ? s.active : s.inactive}`}>
                    {status
                        ? 'АГЕНТ АКТИВЕН'
                        : 'АГЕНТ НЕАКТИВЕН'
                    }
                </div>
            }
            <button
                className={`${s.statusButton} ${status ? s.active : s.inactive}`}
                title={status ? 'Деактивировать' : 'Активировать'}
                onClick={() => updateStatus()}
            >
                {status
                    ? <ShieldFail/>
                    : <ShieldDone/>
                }
            </button>
        </div>
    </div>
}


const Profile = ({Store = userStore}) => {
    const [editAvatarModal, setEditAvatarModal] = useState(false)
    const [createCompetencyProfileModal, setCreateCompetencyProfileModal] = useState(false)
    const [documentModal, setDocumentModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [userBlockInfoModal, setUserBlockInfoModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);

    const [balanceIsLoading, setBalanceIsLoading] = useState(true);
    const [balance, setBalance] = useState(0);

    const [banModal, setBanModal] = useState(false);
    const [unbanModal, setUnbanModal] = useState(false);

    const [ratingModalShown, setRatingModalShown] = useState(false)

    const handleBanClick = () => {
        Store.authProfile.isBanned
            ? setUnbanModal(true)
            : setBanModal(true)
    }

    useEffect(() => {
        const getBalance = async () => {
            try {
                setBalanceIsLoading(true)
                const req = await getWalletBalance(Store.userId)
                setBalance(req)
            } catch (e) {
                notifications.addNotification('Не удалось загрузить баланс')
            } finally {
                setBalanceIsLoading(false)
            }
        }
        if (Store.isLoaded && Store.authProfile.userRoleGroupTitleCode === 'SYSTEM_USERS') {
            getBalance()
        }
    }, [Store.isLoaded, Store.authProfile.userRoleGroupTitleCode, Store.userId])


    return <div className={s.wrap}>
        <div className={s.baseCard}>
            <div title={'Изменить аватар'} onClick={() => setEditAvatarModal(true)}>
                <img src={Store.authProfile.avatarLink} alt={Store.authProfile.name}/>
            </div>
            <div className={s.nameWrap}>
                <h5 className={`${f.boldH5}`}>{Store.authProfile.surname} {Store.authProfile.name}</h5>
                <p className={`${f.subtitle2Light} ${s.coloredText}`}>
                    {
                        Store.competencyProfile?.positionTitle
                            ? Store.competencyProfile.positionTitle
                            : Store.authProfile.userRoleTitle
                    }
                </p>
                {
                    Store.authProfile.isBanned === 1 &&
                    <div className={s.banTag}>
                        <p className={f.subtitle2Bold}>Пользователь заблокирован</p>
                    </div>
                }
                {
                    Store.authProfile.isDeleted === 1 &&
                    <div className={s.banTag}>
                        <p className={f.subtitle2Bold}>Пользователь удален</p>
                    </div>
                }
            </div>
        </div>
        <div className={s.line}/>
        {
            Store.authProfile.userRoleTitleCode !== 'ADMIN' &&
            Store.authProfile.userRoleTitleCode !== 'TEC_SUP_AGENT' &&
            <div className={s.indWrap}>
                {
                    Store.competencyProfile
                        ? <div className={s.stats}>
                            <div className={s.statCount}>
                                <p className={`${f.subtitle1Bold} ${s.coloredText}`}>Рейтинг</p>
                                <h4 className={`${f.boldH4}`}>{Store.competencyProfile.rating}</h4>
                            </div>
                            <div className={s.statCount}>
                                <p className={`${f.subtitle1Bold} ${s.coloredText}`}>Заказов</p>
                                <h4 className={`${f.boldH4}`}>{Store.competencyProfile.ordersNumber}</h4>
                            </div>
                            <div className={s.statCount}>
                                <p className={`${f.subtitle1Bold} ${s.coloredText}`}>Баланс</p>
                                {
                                    balanceIsLoading
                                        ? <TextPreloader/>
                                        : <h4 className={`${f.boldH4}`}>{balance}</h4>
                                }
                            </div>
                        </div>
                        : <div className={s.competencyWarningWrap}>
                            <p className={`${f.subtitle2Bold}`}>Отсутствует профиль компетенций</p>
                            <Button text={'СОЗДАТЬ ПРОФИЛЬ'}
                                    type={'secondary'}
                                    nestedStyles={{height: 30, justifyContent: 'center', borderRadius: 3}}
                                    click={() => setCreateCompetencyProfileModal(true)}
                            />
                        </div>
                }
                {
                    !(Store.authProfile.isDeleted || Store.authProfile.isBanned) && Store.authProfile.userRoleTitleCode === 'TEACHER' && Store.competencyProfile
                        ? !Store.statusIsChecking
                            ? <div className={s.teacherStatusWrap}>
                                <div
                                    className={`${f.subtitle2Bold} ${s.statusIndicator} ${Store.teacherStatus ? s.active : s.inactive}`}>
                                    {Store.teacherStatus
                                        ? 'УЧИТЕЛЬ ПОДТВЕРЖДЕН'
                                        : 'УЧИТЕЛЬ НЕ ПОДТВЕРЖДЕН'
                                    }
                                </div>
                                <button
                                    className={`${s.statusButton} ${Store.teacherStatus ? s.active : s.inactive}`}
                                    title={Store.teacherStatus ? 'Снять подтверждение' : 'Подтвердить'}
                                    onClick={async () => Store.updateCompetencyTeacherStatus()}
                                >
                                    {Store.teacherStatus
                                        ? <ShieldFail/>
                                        : <ShieldDone/>
                                    }
                                </button>
                            </div>
                            : <TextPreloader/>
                        : null
                }
            </div>
        }
        {
            Store.authProfile.userRoleTitleCode === 'TEC_SUP_AGENT' &&
            <SupportActiveTag userId={Store.authProfile.userId}/>
        }


        <PersonaInfo Store={Store}/>
        {
            Store.authProfile.userRoleTitleCode !== 'ADMIN' &&
            <>
                <div className={s.line}/>
                <div className={s.other}>
                    {
                        Store.authProfile.userRoleTitleCode !== 'TEC_SUP_AGENT' &&
                        <button className={s.otherRow} onClick={() => setPaymentModal(true)}>
                            <div className={s.textWithIcon}>
                                <div className={s.iconBg}>
                                    <Wallet/>
                                </div>
                                <p className={f.subtitle1Bold}>Способы оплаты</p>
                            </div>
                            <div className={s.iconArrow}>
                                <Arrow/>
                            </div>
                        </button>
                    }
                    {
                        paymentModal && <PaymentModal closeFunc={setPaymentModal}/>
                    }
                    {
                        Store.authProfile.userRoleTitleCode === 'TEACHER' &&
                        <button className={s.otherRow} onClick={() => setDocumentModal(true)}>
                            <div className={s.textWithIcon}>
                                <div className={s.iconBg}>
                                    <Document/>
                                </div>
                                <p className={f.subtitle1Bold}>Документы</p>
                            </div>
                            <div className={s.iconArrow}>
                                <Arrow/>
                            </div>
                        </button>
                    }
                    {
                        documentModal &&
                        <DocumentModal closeFunc={setDocumentModal} id={userStore.userId}/>
                    }
                    {
                        !(Store.authProfile.userRoleTitleCode === "TEC_SUP_AGENT") &&
                        <button className={s.otherRow} onClick={() => setRatingModalShown(true)}>
                            <div className={s.textWithIcon}>
                                <div className={s.iconBg}>
                                    <Star/>
                                </div>
                                <p className={f.subtitle1Bold}>Изменить рейтинг</p>
                            </div>
                            <div className={s.iconArrow}>
                                <Arrow/>
                            </div>
                        </button>
                    }
                    {
                        ratingModalShown &&
                        <RatingModal closeModalFunc={setRatingModalShown}
                                     userCode={Store.authProfile.userRoleTitleCode}
                                     competencyProfileId={Store.competencyProfile?.profileId}
                        />
                    }
                    {
                        Store.authProfile.isBanned === 1 &&
                        <button className={s.otherRow} onClick={() => setUserBlockInfoModal(true)}>
                            <div className={s.textWithIcon}>
                                <div className={s.iconBg}>
                                    <BlockInfo/>
                                </div>
                                <p className={f.subtitle1Bold}>Информация о блокировке</p>
                            </div>
                            <div className={s.iconArrow}>
                                <Arrow/>
                            </div>
                        </button>
                    }
                    {
                        userBlockInfoModal &&
                        <UserBlockInfo closeFunc={setUserBlockInfoModal} userId={userStore.userId}/>
                    }
                    {
                        !Store.authProfile.isDeleted &&
                        <>
                            <button className={s.otherRow}
                                    onClick={() => handleBanClick()}>
                                <div className={s.textWithIcon}>
                                    <div className={s.iconBg}>
                                        <Lock/>
                                    </div>
                                    <p className={f.subtitle1Bold}>
                                        {
                                            Store.authProfile.isBanned
                                                ? `Разблокировать пользователя`
                                                : `Заблокировать пользователя`
                                        }
                                    </p>
                                </div>
                                <div className={s.iconArrow}>
                                    <Arrow/>
                                </div>
                            </button>
                            <button className={s.otherRow}
                                    onClick={() => setDeleteModal(true)}
                            >
                                <div className={s.textWithIcon}>
                                    <div className={s.iconBg}>
                                        <Delete/>
                                    </div>
                                    <p className={f.subtitle1Bold}>
                                        Удалить пользователя
                                    </p>
                                </div>
                                <div className={s.iconArrow}>
                                    <Arrow/>
                                </div>
                            </button>
                        </>
                    }
                    {
                        deleteModal && <DeleteUser closeFunc={setDeleteModal} Store={Store}/>
                    }
                </div>
            </>
        }

        {
            createCompetencyProfileModal && <CreateCompetencyProfileModal closeFunc={setCreateCompetencyProfileModal}/>
        }
        {
            editAvatarModal && <EditAvatarModal closeFunc={setEditAvatarModal} Store={Store}/>
        }

        {
            banModal && <BlockUserModal userId={Store.authProfile.userId} name={Store.authProfile.name}
                                        closeModalFunc={setBanModal}/>
        }
        {
            unbanModal && <ModalWindow
                type={'small'}
                closeFunc={setUnbanModal}
                title={`Разблокровать пользователя ${Store.authProfile.name}`}
                primaryButtonTitle={'Разблокировать'}
                primaryAction={async () => {
                    try {
                        await unbanUser(Store.authProfile.userId)
                        await Store.getAllData()
                        setUnbanModal(false)
                    } catch (e) {
                        throw e
                    }
                }
                }
            >
                <p className={`${f.body1}`}>Вы уверены?</p>
            </ModalWindow>
        }
    </div>
}

export default observer(Profile)
