import s from './SpecList.module.scss'
import f from '../../../../static/fonts.module.scss'
import m from "../../../../static/misc.module.scss";

const SpecList = ({params, blockTitle}) => {
    return <div className={s.block}>
        {blockTitle && <h5 className={f.boldH5}>{blockTitle}</h5>}
        <div className={s.infoList}>
            {
                params.map(row => row.value
                    ? row.type === 'vertical'
                        ? <div key={row.label} className={s.col}>
                            <h6 title={row.title} className={f.subtitle1Bold}>{row.label}</h6>
                            {
                                row.complexValue
                                    ? row.value
                                    : <p className={f.subtitle1Bold}>{row.value}</p>
                            }
                        </div>
                        : <div key={row.label} className={s.row}>
                            <h6 title={row.title} className={f.subtitle1Bold}>{row.label}</h6>
                            <div className={m.dotline}/>
                            {
                                row.complexValue
                                    ? row.value
                                    : <p className={f.subtitle1Bold}>{row.value}</p>
                            }
                        </div>
                    : null
                )
            }
        </div>
    </div>
}

export default SpecList