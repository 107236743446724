import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import React, {useEffect, useState} from "react";
import Input from "../../../../_BASE/UI/Input/Input";
import {getOptionsListBySubjectID, getSubjectList} from "../../../../../services/CompetencyService";
import {notifications} from "../../../../../index";

import s from './AddCompetencyModal.module.scss'
import f from '../../../../../static/fonts.module.scss'
import Button from "../../../../_BASE/UI/Button/Button";

const AddCompetencyModal = ({
                                closeFunc,
                                role,
                                dataStructure,
                                setDataStructure,
                                optionLabels,
                                setOptionLabels,
                                addButtonAction = closeFunc
                            }) => {
    const [searchValue, setSearchValue] = useState('')
    const [subjects, setSubjects] = useState([])
    const [allSubjects, setAllSubjects] = useState([])
    const [options, setOptions] = useState([])
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectAllClicked, setSelectAllClicked] = useState(false)

    const [loadedOptionsID, setLoadedOptionsID] = useState(new Set())


    //Загрузка предметов при создании модального окна
    useEffect(() => {
        const getData = async () => {
            try {
                const subjectsResponse = await getSubjectList(role)
                setAllSubjects(subjectsResponse)
                setSubjects(subjectsResponse)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [role])


    //Обработка поиска
    useEffect(() => {
        if (searchValue.length > 1) {
            const matched = allSubjects.filter(subject => subject.title.toUpperCase().includes(searchValue.toUpperCase()))
            setSubjects(matched)
            setOptions([])
            setSelectedSubject('')
        } else {
            setSubjects(allSubjects)
        }

    }, [searchValue, allSubjects])


    const updateOptionsView = async (subjectId) => {
        const optRes = await getOptionsListBySubjectID(role, subjectId)
        setOptions(optRes)
        setSelectAllClicked(false)
        const tmpSet = new Set()
        optRes.forEach(({id}) => {
            tmpSet.add(id)
        })
        setLoadedOptionsID(tmpSet)
    }

    useEffect(() => {
        const asyncF = async () => {
            try {
                const mapElement = await dataStructure.get(selectedSubject.id)
                if (loadedOptionsID?.size === mapElement.options.size) {
                    setSelectAllClicked(true)
                }
            } catch (e) {
                throw e
            }
        }

        if (dataStructure.has(selectedSubject.id)) {
            asyncF()
        }
    }, [loadedOptionsID, dataStructure, selectedSubject.id])

    //Верстка колонки предметов
    const subjectsLayout = subjects.map((subject) => {
        return <div
            className={`${s.singleCompetency} ${subject.id === selectedSubject.id ? s.active : ''}`}
            key={subject.id}
            onClick={async () => {
                try {
                    await setSelectedSubject(subject)
                    await updateOptionsView(subject.id)
                } catch (e) {
                    notifications.addNotification('Ошибка при получении списка опций')
                }
            }
            }
        >
            <div className={`${s.mark} ${dataStructure.has(subject.id) ? s.active : ''}`}/>
            <p className={f.subtitle1Bold}>{subject.title}</p>
        </div>
    })


    //Верстка колонки опций
    const optionsLayout = options.map(({title, id}) => {
        const toggleClick = () => {
            //Если выбрана хоть одна опция этого предмета
            if (dataStructure.has(selectedSubject.id)) {
                setDataStructure(new Map(dataStructure.set(selectedSubject.id, {
                    title: selectedSubject.title,
                    options: toggleId(id, dataStructure.get(selectedSubject.id).options),
                    iconLink: selectedSubject.iconLink
                })))
                setOptionLabels(new Map(optionLabels.set(id, title)))
                if (dataStructure.get(selectedSubject.id).options.size === 0) {
                    const Temp = new Map(dataStructure)
                    Temp.delete(selectedSubject.id)
                    setDataStructure(new Map(Temp))
                }
            } else {
                setDataStructure(new Map(dataStructure.set(selectedSubject.id, {
                    title: selectedSubject.title,
                    options: new Set().add(id),
                    iconLink: selectedSubject.iconLink
                })))
                setOptionLabels(new Map(optionLabels.set(id, title)))
            }
        }
        let selected = false
        if (dataStructure.has(selectedSubject.id)) {
            selected = dataStructure.get(selectedSubject.id).options.has(id)
        }
        return <div
            className={s.singleOption}
            key={id}
            onClick={() => toggleClick()}
        >
            {
                <div className={`${s.mark} ${selected ? s.active : ''}`}/>
            }
            <p className={f.subtitle1Bold}>{title}</p>
        </div>
    })

    const toggleId = (item, set) => {
        set.has(item) ? set.delete(item) : set.add(item)
        return set
    }

    const handleAddAllOptions = () => {
        if (selectAllClicked) {
            const tmp = new Map(dataStructure)
            tmp.delete(selectedSubject.id)
            setDataStructure(new Map(tmp))
            setSelectAllClicked(false)
        } else {
            setDataStructure(new Map(dataStructure.set(selectedSubject.id, {
                title: selectedSubject.title,
                options: loadedOptionsID,
                iconLink: selectedSubject.iconLink
            })))
            setSelectAllClicked(true)
        }
    }


    return <ModalWindow title={'Добавление компетенций'} closeFunc={closeFunc}>
        <div className={s.wrap}>
            <div className={s.form}>
                <Input value={searchValue} setValue={setSearchValue} placeholder={'Введите название предмета'}
                       styles={{maxWidth: "100%"}}/>
                <div className={s.cols}>
                    <div className={s.col}>
                        <h6 className={f.boldH6}>Список предметов</h6>
                        {subjectsLayout}
                    </div>
                    <div className={s.col}>
                        <h6 className={f.boldH6}>Список опций предмета: {selectedSubject.title}</h6>
                        {optionsLayout}
                        {options.length > 0 && <div>
                            <button
                                onClick={() => handleAddAllOptions()}
                                className={`${f.subtitle1Bold} ${s.selectAll}`}>{selectAllClicked ? `Убрать все` : `Выбрать все`}
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: 'center'}}><Button text={'Выбрать компетенции'}
                                                                             click={addButtonAction}/></div>
        </div>
    </ModalWindow>
}

export default AddCompetencyModal