import s from './ReviewsTable..module.scss'
import f from '../../../../static/fonts.module.scss'
import Table from "../../../_BASE/Layout/Table/Table";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";

import {useEffect, useState} from "react";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {getReviews, getReviewsCount} from "../../../../services/ReviewsService";
import {userStore} from "../UserInfo";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Button from "../../../_BASE/UI/Button/Button";
import {useNavigate} from "react-router";


const ReviewsTable = () => {
    const store = userStore
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [countLoaded, setCountLoaded] = useState(false);


    useEffect(() => {
        const getCount = async () => {
            const count = await getReviewsCount(store.authProfile.userRoleTitleCode, store.userId)
            setTotal(count)
            setCountLoaded(true)
        }

        getCount()
    }, [store.authProfile.userRoleTitleCode, store.userId])

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            const req = await getReviews(
                store.authProfile.userRoleTitleCode,
                store.userId,
                paginationObject.page,
                paginationObject.offset)
            await setData(req)
            setIsLoading(false)
        }

        if (countLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, total, countLoaded, store.authProfile.userRoleTitleCode, store.userId])

    if (isLoading) return <Preloader type={'big'}/>


    return <div className={s.wrap}>
        <h4 className={`${f.boldH4} ${s.title}`}>Отзывы</h4>
        <Table gridTemplate={'7fr 3fr 8fr 91px'}
               headsArray={['Комментарий', "Рейтинг", "Дата отзыва", "Действия"]}
               rowsArray={data.map((item) => <TableRow rowData={item} key={item.id}/>)}
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total}
        />
    </div>
}

const TableRow = ({rowData}) => {
    const [reviewModal, setReviewModal] = useState(false);
    const navigation = useNavigate()

    const actions = [
        {
            label: 'Просмотр',
            func: () => setReviewModal(true)
        }
    ]
    return <>
        <p className={f.body1}>{rowData.comment}</p>
        <p className={f.subtitle1Bold}>{rowData.rating} из 5</p>
        <p className={f.subtitle1Bold}>{new Date(rowData.createDate).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            reviewModal && <ModalWindow
                title={'Просмотр отзыва'}
                closeFunc={setReviewModal}>
                <div className={s.modalWrap}>
                    {
                        rowData.orderId &&
                        <Button text={'Посмотреть заказ'} type={'secondary'}
                                nestedStyles={{width: "100%", justifyContent: "center"}}
                                click={() => navigation(`/orders/${rowData.orderId}`)}
                        />
                    }
                    <div className={s.reviewInfo}>
                        {
                            rowData.orderId &&
                            <div className={s.row}>
                                <h6 className={f.boldH6}>ID заказа</h6>
                                <p className={f.subtitle1Bold}>{rowData.orderId}</p>
                            </div>
                        }
                        <div className={s.col}>
                            <h6 className={f.boldH6}>Комментарий:</h6>
                            <p className={f.body1}>{rowData.comment}</p>
                        </div>
                        <div className={s.row}>
                            <h6 className={f.boldH6}>Рейтинг</h6>
                            <p className={f.subtitle1Bold}>{rowData.rating}</p>
                        </div>
                        <div className={s.row}>
                            <h6 className={f.boldH6}>Дата создания запроса:</h6>
                            <p className={f.subtitle1Bold}>
                                {
                                    new Date(rowData.createDate).toLocaleDateString('ru-RU', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit',
                                    })
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </ModalWindow>
        }
    </>
}

export default ReviewsTable
