import s from './EmptyList.module.scss'
import f from '../../../../../static/fonts.module.scss'

const EmptyList = ({title = null, height = 500}) => {

    return <div className={s.emptyList} style={{height: height}}>
        <h4 className={f.boldH4}>
            {
                title ? title : 'Записи не найдены'
            }
        </h4>
    </div>

}

export default EmptyList