import s from './SystemNotifications.module.scss'
import f from '../../static/fonts.module.scss'
import {observer} from "mobx-react-lite";
import {useContext} from "react";
import {Context} from "../../index";


const SystemNotifications = () => {
    const {notifications} = useContext(Context)
    const notificationLayout =  Array.from(notifications.notifications).map(([key, value]) => {
        return <p key={key} className={`${s.message} ${f.caption} ${value.status==='OK'?s.OK:''}`}>{value.message}</p>
    })
    return <div className={s.wrap}>{notificationLayout}</div>
}

export default observer(SystemNotifications)