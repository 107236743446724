import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import s from "./ChatView.module.scss";
import EmptyList from "../../../../_BASE/Layout/Table/EmptyList/EmptyList";
import ChatList from "../../ChatList/ChatList";
import ErrorBoundary from "../../../../ErrorBoundary/ErrorBoundary";
import {useEffect, useState} from "react";
import {getAllMessages} from "../../../../../services/ChatService";


const ChatView = ({orderId, rightUserId}) => {
    const [chatIsLoading, setChatIsLoading] = useState(true);
    const [chatNotfound, setChatNotFound] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const getMessages = async () => {
            try {
                setChatIsLoading(true)
                const chat = await getAllMessages("ORDER", orderId)
                setMessages(chat)
            } catch (e) {
                setChatNotFound(true)
                throw e
            } finally {
                setChatIsLoading(false)
            }
        }

        getMessages()
    }, [orderId])

    if (chatIsLoading) return <Preloader type={'big'}/>

    return <ErrorBoundary>
        <div className={s.chat}>
            {
                chatNotfound
                    ? <EmptyList title={'Чат не найден'}/>
                    : <ChatList data={messages} rightUserId={rightUserId} readOnly/>
            }
        </div>
    </ErrorBoundary>
}

export default ChatView