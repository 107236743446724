import {useCallback, useEffect, useState} from "react";
import {getTeacherCompetencyDocuments, deletePersonalDocuments} from "../../../../../services/CompetencyService";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import EmptyList from "../../../../_BASE/Layout/Table/EmptyList/EmptyList";
import s from "../Profile.module.scss";
import Fancybox from "../../../../_BASE/UI/Fancybox/Fancybox";
import Button from "../../../../_BASE/UI/Button/Button";

const DocumentModal = ({closeFunc, id}) => {
    const [urls, setUrls] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteDocuments = async () => {
        try {
            setIsDeleting(true)
            await deletePersonalDocuments(id)
            await getUrl()
            setDeleteModal(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsDeleting(false)
        }
    }

    const getUrl = useCallback(async () => {
        try {
            setIsLoading(true)
            const data = await getTeacherCompetencyDocuments(id)
            setUrls(data)
        } catch (e) {
            throw e
        } finally {
            setIsLoading(false)
        }
    }, [id])

    useEffect(() => {
        getUrl()
    }, [getUrl])


    return <ModalWindow closeFunc={closeFunc} title={"Документы"}>
        <div className={s.documentsWrap}>
            {
                isLoading
                    ? <Preloader/>
                    : !urls?.photoOneLink && !urls?.photoTwoLink
                        ? <EmptyList title={'Документы не найдены'}/>
                        : <div className={s.documentsWrap}>
                            <div className={s.documentModal}>
                                <Fancybox>
                                    {
                                        urls?.photoOneLink &&
                                        <button className={s.docPreview} data-fancybox="gallery"
                                                data-src={urls?.photoOneLink}>
                                            <img className={s.docPreview} src={urls?.photoOneLink} alt={''}/>
                                        </button>
                                    }
                                    {
                                        urls?.photoTwoLink &&
                                        <button className={s.docPreview} data-fancybox="gallery"
                                                data-src={urls?.photoTwoLink}>
                                            <img className={s.docPreview} src={urls?.photoTwoLink} alt={''}/>
                                        </button>
                                    }

                                </Fancybox>
                            </div>
                            <div>
                                <Button text={'Удалить персональные документы'} click={() => setDeleteModal(true)}/>
                            </div>

                            {
                                deleteModal && <ModalWindow closeFunc={setDeleteModal}
                                                            title={'Удаление персональных документов'}
                                                            type={'small'}
                                                            primaryAction={() => deleteDocuments()}
                                                            primaryButtonTitle={'Удалить'}
                                                            primaryButtonIsDisabled={isDeleting}
                                >
                                    <p>Вы действительно хотите удалить персональные документы?</p>
                                </ModalWindow>
                            }
                        </div>
            }
        </div>
    </ModalWindow>
}

export default DocumentModal
