import React from "react";

import s from './Radios.module.scss'
import fonts from "../../../../static/fonts.module.scss";

const Radios = ({data, size, checkedValue, setCheckedValue, groupName}) => {

    const res = data.map((value, i) =>
        <div className={`${s.singleRadioWrap}`} key={i}>
            <label
                className={`${s.radioLabel} ${fonts.button}`}
            >
                <input
                    className={`${s.radio} `}
                    type={'radio'}
                    name={groupName ? groupName : 'radios'}
                    value={value.value}
                    onChange={() => setCheckedValue(value.value)}
                    checked={checkedValue === value.value}
                />
                <div className={s.checkmark}>
                    {
                        size === 'small' &&
                        <div className={s.dot}/>
                    }
                    <div className={s.icon}>
                        {value.icon}
                    </div>
                    {
                        value.title && <h6 className={`${fonts.boldH6} ${s.title}`}>{value.title}</h6>
                    }
                </div>
            </label>
        </div>
    )
    return (
        <div className={`${s.radioGroupWrap} ${size === 'small' ? s.small : s.big}`}>
            {res}
        </div>
    )
}

export default Radios