import $auth from "../http";
import {notifications} from "../index";

//Получение списка заказов пользователя
export const getUserOrders = async (id,
                                    orderStatusCode,
                                    userPositionCode,
                                    page,
                                    offset) => {
    try {
        return await $auth.get(`/v2/orders/list/${id}?sortType=DEFAULT&orderStatusCode=${orderStatusCode}&userPositionCode=${userPositionCode}&page=${page}&offset=${offset}`)
    } catch (e) {
        throw e
    }
}

//Получение количества страниц списка заказов пользователя
export const getUserOrdersCount = async (id, orderStatusCode, userPositionCode) => {
    try {
        const req = await $auth.get(`/v2/orders/pages/${id}?orderStatusCode=${orderStatusCode}&userPositionCode=${userPositionCode}&offset=20`)
        return req.data.recordCount
    } catch (e) {
        throw e
    }
}

//Получение всех статусов заказа
export const getOrdersStatuses = async () => {
    try {
        return await $auth.get(`/v2/orders/categories`)
    } catch (e) {
        throw e
    }
}

//Получение списка заказов пользователя
export const getTotalOrders = async (orderStatusCode, page, offset) => {
    try {
        return await $auth.get(`/v2/orders/get-all-list?orderStatusCode=${orderStatusCode}&offset=${offset}&page=${page}`)
    } catch (e) {
        throw e
    }
}

//Получение количества страниц списка заказов пользователя
export const getTotalOrdersCount = async (orderStatusCode, offset) => {
    try {
        const req = await $auth.get(`/v2/orders/get-all-list/pages?orderStatusCode=${orderStatusCode}&offset=${offset}`)
        return req.data.recordCount
    } catch (e) {
        throw e
    }
}

//Получение информации о заказе
export const getOrder = async (id) => {
    try {
        const req = await $auth.get(`/v2/orders/get/${id}`)
        return req.data
    } catch (e) {
        throw e
    }
}

//Поиск заказов
export const searchOrder = async (type, message) => {
    try {
        const req = await $auth.get(`/v2/orders/search?type=${type}&message=${message}`)
        return req.data
    } catch (e) {
        console.log({e})
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка параметром сервиса
export const getOrderSettings = async () => {
    try {
        const req = await $auth.get(`/v2/orders/options`)
        return req.data
    } catch (e) {
        throw e
    }
}

//Обновление параметра сервиса
export const updateOrderSettings = async (code, data) => {
    try {
        const req = await $auth.put(`/v2/orders/options?code=${code}&data=${data}`)
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        throw e
    }
}

//Получение истории изменения статуса заказа
export const getOrderStatusHistory = async (id) => {
    try {
        const req = await $auth.get(`/v2/orders/get/history/status`, {
            params: {
                id: id
            }
        })
        return req.data
    } catch (e) {
        throw e
    }
}

//Получение анализа стоимости заказа
export const getOrderPriceStats = async (id) => {
    try {
        const req = await $auth.get(`/v2/orders/get/history/price`, {
            params: {
                id: id
            }
        })
        return req.data
    } catch (e) {
        throw e
    }
}

//Обновление статуса заказа
export const updateOrderStatus = async (params) => {
    try {
        const req = await $auth.put(`/v2/orders/update/status`, {}, {
            params: {
                code: params.code,
                id: params.id
            }
        })
        return req.data
    } catch (e) {
        throw e
    }
}