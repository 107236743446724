import s from './Table.module.scss'
import f from '../../../../static/fonts.module.scss'
import EmptyList from "./EmptyList/EmptyList";

const Table = ({headsArray, rowsArray, gridTemplate, headerStyle, emptyListTitle}) => {

    return <div className={s.wrap}>
        {
            rowsArray?.length > 0
                ? <>
                    <div className={s.header} style={{gridTemplateColumns: gridTemplate, ...headerStyle}}>
                        {
                            headsArray.map((text, i) => <p key={i} className={f.subtitle1Bold}>{text}</p>)
                        }
                    </div>
                    <div className={s.rows}>
                        {
                            rowsArray.map((row, i) => <div key={i}
                                                           className={s.row}
                                                           style={{gridTemplateColumns: gridTemplate}}
                            >{row}
                            </div>)
                        }
                    </div>
                </>
                : <EmptyList title={emptyListTitle}/>
        }

    </div>
}

export default Table