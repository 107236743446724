import s from './Chats.module.scss'
import f from '../../static/fonts.module.scss'
import m from "../../static/misc.module.scss";

import {useEffect, useState, useContext} from "react";
import {Context, notifications, store} from "../../index";
import {observer} from "mobx-react-lite";


import ChatList from "../_PAGES/OrderInfo/ChatList/ChatList";
import Button from "../_BASE/UI/Button/Button";
import Input from "../_BASE/UI/Input/Input";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

import {ReactComponent as SendIcon} from "./Send.svg";
import {ReactComponent as PlusIcon} from "./Plus.svg";
import {ReactComponent as DeleteIcon} from "./Delete.svg";
import {ReactComponent as DocumentIcon} from "./document.svg";
import DropdownButton from "../_BASE/UI/Button/DropdownButton/DropdownButton";
import ModalWindow from "../_BASE/Layout/ModalWindow/ModalWindow";
import OrderModal from "../_PAGES/OrderInfo/OrderModal/OrderModal";
import UserInfo from "../_PAGES/UserInfo/UserInfo";
import {createBonusRequest} from "../../services/PaymentService";
import FileInput from "../_BASE/UI/Input/FileInput/FileInput";
import Fancybox from "../_BASE/UI/Fancybox/Fancybox";
import BlockUserModal from "../BlockUserModal/BlockUserModal";

import TicketInfo from "./ActiveChat/ChatInfo/ChatInfo";
import ChatSelector from "./ChatSelector/ChatSelector";
import UserCard from "../_PAGES/OrderInfo/UserCard/UserCard";


const BonusModal = ({closeFunc, userId, ticketId}) => {
    const [comment, setComment] = useState('');
    const [amount, setAmount] = useState(0);

    const handleSubmit = async () => {
        if (comment?.length === 0) {
            notifications.addNotification('Укажите комментарий')
            return
        }
        if (amount?.length === 0) {
            notifications.addNotification('Укажите сумму')
            return
        }
        try {
            await createBonusRequest(userId, ticketId, comment, amount)
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    return <ModalWindow closeFunc={closeFunc} title={'Создание запроса'}>
        <div className={s.bonusWrap}>
            <div style={{width:"100%"}}>
                <UserCard userId={userId}/>
            </div>
            <Input styles={{maxWidth: 524}} value={comment} setValue={setComment} title={"Комментарий"}/>
            <Input styles={{maxWidth: 524}} value={amount} setValue={setAmount} title={"Сумма"}/>
            <Button text={'Отправить'} click={() => handleSubmit()}/>
        </div>
    </ModalWindow>
}

const Chats = () => {
    const {ChatStore} = useContext(Context)


    const [orderModal, setOrderModal] = useState(false);
    const [uploadImageModal, setUploadImageModal] = useState(false);

    const [teacherId, setTeacherId] = useState();
    const [studentId, setStudentId] = useState();

    const [studentModal, setStudentModal] = useState(false);
    const [teacherModal, setTeacherModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [banModal, setBanModal] = useState(false);
    const [bonusModal, setBonusModal] = useState(false);
    const [uploadedMimeType, setUploadedMimeType] = useState(null);

    const [imageObject, setImageObject] = useState();
    const [imageUrl, setImageUrl] = useState();


    const handleSubmitClick = () => {
        ChatStore.sendMessage(ChatStore.activeMessage)
    }

    useEffect(() => {
        ChatStore.setMyId(store.user.userId)
    }, [])

    return <div className={s.wrap}>
        <ChatSelector/>
        {ChatStore.selectedChatId && <div className={s.contentColumn}>
            <div className={s.headline}>
                <div className={s.titles}>
                    <h4 className={f.boldH4}>{ChatStore.selectedData.name}, <span style={{color: "#FFB8BA"}}>Вы</span>
                    </h4>
                    <div className={s.headCategories}>
                        <h6 className={f.boldH6}>Категории обращения:</h6>
                        <div className={m.tableTags}>
                            <p className={`${m.bigTag} ${f.subtitle1Bold}`}>{ChatStore.selectedData.sTitle}</p>
                            <p className={`${m.bigTag} ${f.subtitle1Bold}`}>{ChatStore.selectedData.cTitle}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <DropdownButton text={'Действия'} actions={ChatStore.selectedData?.orderId !== "NULL"
                        ? [{
                            label: 'Просмотр профиля ученика', func: () => setStudentModal(true)
                        }, {
                            label: 'Просмотр профиля учителя', func: () => setTeacherModal(true)
                        }, {
                            label: 'Просмотр заказа', func: () => setOrderModal(true)
                        }, {
                            label: 'Запрос на зачисление бонусов',
                            func: () => setBonusModal(true)
                        }, {
                            label: `Заблокировать ${Number(ChatStore.selectedData.userId) === Number(teacherId)
                                ? "ученика"
                                : "учителя"
                            }`,
                            func: () => setBanModal(true)
                        }]
                        : [{
                            label: 'Просмотр профиля', func: () => setUserModal(true)
                        }, {
                            label: 'Запрос на зачисление бонусов', func: () => setBonusModal(true)
                        }]}/>
                </div>
            </div>

            <div className={s.contentWrap}>
                <div className={s.chatWrap}>
                    <ChatList data={ChatStore.chats.get(ChatStore.selectedChatId).messages}
                              rightUserId={Number(ChatStore.selectedData.aUserId)}
                              key={ChatStore.selectedChatId}
                    />
                    <div className={s.chatInputWrap}>
                        <Button type={'icon'} icon={<PlusIcon/>}
                                nestedStyles={{height: 42, padding: 0}}
                                click={() => setUploadImageModal(true)}
                        />
                        {
                            uploadImageModal && <ModalWindow
                                title={'Прикрепить изображение'}
                                closeFunc={setUploadImageModal}>
                                <FileInput imageUrl={imageUrl} setImageUrl={setImageUrl}
                                           imageObject={imageObject} setImageObject={setImageObject}
                                           text={'Загрузите файл .jpg или .png размером не менее 25х25. Или файл .pdf'}
                                           size={128} types={
                                    uploadedMimeType
                                        ? uploadedMimeType === "application/pdf"
                                            ? ["application/pdf"]
                                            : ['image/png', "image/jpg", "image/jpeg"]
                                        : ['image/png', "image/jpg", "image/jpeg", "application/pdf"]
                                }
                                />
                                <Button nestedStyles={{marginTop: 24}} text={'Загрузить'}
                                        click={() => {
                                            //Если это первый файл
                                            if (ChatStore.activeMessage.attachments?.length === 0) {
                                                setUploadedMimeType(imageObject.type)
                                            }
                                            ChatStore.addFileToQueue({object: imageObject, url: imageUrl})
                                            setImageObject(null)
                                            setImageUrl(null)
                                            setUploadImageModal(false)
                                        }
                                        }/>
                            </ModalWindow>
                        }
                        <div className={s.chatInputArea}>
                            {
                                ChatStore.activeMessage.attachments?.length !== 0 &&
                                <div className={s.chatAttachmentsWrap}>
                                    {
                                        ChatStore.activeMessage.attachments.map((attachment, index) =>
                                            <div className={s.chatAttachmentWrap} key={index}>
                                                {
                                                    attachment.object.type === 'application/pdf'
                                                        ? <div className={s.chatAttachmentCard}>
                                                            <DocumentIcon/>
                                                            <p className={`${f.caption} ${s.fileName}`}>{attachment.object.name}</p>
                                                        </div>
                                                        : <Fancybox>
                                                            <div data-fancybox="gallery"
                                                                 data-src={attachment.url}
                                                                 className={s.chatAttachmentCard}>
                                                                <img src={attachment.url} alt=""/>
                                                            </div>
                                                        </Fancybox>
                                                }
                                                <button title={'Удалить'}
                                                        className={s.deleteAttachment}
                                                        onClick={() => {
                                                            ChatStore.removeAttachmentFromQueue(index)
                                                            if (ChatStore.activeMessage.attachments?.length === 0) {
                                                                setUploadedMimeType(null)
                                                            }
                                                        }}
                                                ><DeleteIcon/>
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            }
                            <Input placeholder={'Введите ваше сообщение'} value={ChatStore.activeMessage.text}
                                   setValue={(m) => ChatStore.setNewTextMessage(m)}
                                   styles={{maxWidth: "100%"}}
                                   type={"textarea"}
                                   maxHeight={100}
                                   inputStyles={{backgroundColor: 'white', padding: 8, height: 42}}
                                   minHeight={42}
                            />
                        </div>
                        <Button text={ChatStore.MessageIsSending ? 'Отправка...' : 'Отправить'}
                                click={() => handleSubmitClick()}
                                nestedStyles={{height: 42}}
                                icon={<div className={s.sendIcon}><SendIcon/></div>}
                                iconOn={"right"}
                                disabled={ChatStore.MessageIsSending}
                        />
                    </div>
                </div>
                <ErrorBoundary>
                    <TicketInfo key={ChatStore.selectedData?.id} data={ChatStore.selectedData}
                                setTeacherId={setTeacherId} setStudentId={setStudentId}
                    />
                </ErrorBoundary>
            </div>

        </div>

        }
        {userModal && <ModalWindow closeFunc={setUserModal}>
            <div className={s.profileModal}>
                <UserInfo id={ChatStore.selectedData.userId}/>
            </div>
        </ModalWindow>}
        {studentModal && <ModalWindow closeFunc={setStudentModal}>
            <div className={s.profileModal}>
                <UserInfo id={studentId}/>
            </div>
        </ModalWindow>}
        {teacherModal && <ModalWindow closeFunc={setTeacherModal}>
            <div className={s.profileModal}>
                <UserInfo id={teacherId}/>
            </div>
        </ModalWindow>}
        {orderModal && <ErrorBoundary>
            <OrderModal closeFunc={setOrderModal} orderId={ChatStore.selectedData?.orderId}/>
        </ErrorBoundary>}
        {bonusModal &&
            <BonusModal closeFunc={setBonusModal} userId={ChatStore.selectedData?.userId}
                        ticketId={ChatStore.selectedData?.id}/>}
        {
            banModal && <BlockUserModal closeModalFunc={setBanModal}
                                        userId={Number(ChatStore.selectedData.userId) === Number(teacherId)
                                            ? Number(studentId)
                                            : Number(teacherId)}/>
        }
    </div>
}

export default observer(Chats)
