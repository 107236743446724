import {notifications} from "../index";
import io from 'socket.io-client';

export const createSocketConnection = (params) => {
    try {
        return io(process.env.NODE_ENV === 'development' ?'http://api.uuch.lo-cus.ru':`https://api.reshkaforum.ru`, {
            path: '/ws/v1/chat',
            auth: {
                token: `Bearer ${localStorage.getItem('authToken')}`
            },
            query: {...params}
        });
    } catch (e) {
        notifications.addNotification('Ошибка при установке socket-соединения')
    }
}
