import s from './PageTitle.module.scss'
import fonts from '../../static/fonts.module.scss'

import {observer} from "mobx-react-lite";
import PageTitleStore from "../../store/PageTitle";

const PageTitle = observer(() => {

    if (PageTitleStore.title.length === 0) return null

    return (
        <h2 className={`${fonts.boldH2} ${s.header}`}>{PageTitleStore.title}</h2>
    )
})
export default PageTitle