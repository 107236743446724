import React, {createContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";

import './static/globals.scss'
import './static/animations.scss'
import style from './static/layout.module.scss'


import {observer} from "mobx-react-lite";

import Store from "./store/auth";
import NotificationsStore from "./store/notifications";

import SystemNotifications from "./components/SystemNotifications/SystemNotifications";
import Preloader from "./components/_BASE/UI/Preloader/Preloader";


import Auth from "./pages/auth/auth";
import MainPage from "./pages/main/mainPage";
import TicketsPage from "./pages/tickets/tickets";
import Sidebar from "./components/Menu/Sidebar/Sidebar";
import PageTitle from "./components/PageTitle/PageTitle";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import SettingsPage from "./pages/settings/settings";
import ActiveTicketsPage from "./pages/chats/active";
import ChatsStore from "./store/chatsStore";
import OrderInfo from "./components/_PAGES/OrderInfo/OrderInfo/OrderInfo";

export const store = new Store()
export const notifications = new NotificationsStore()
export const ChatStore = new ChatsStore()

export const Context = createContext({
    store,
    notifications,
    ChatStore
})

const App = observer(() => {

    useEffect(() => {
        if (localStorage.getItem('authToken')) {
            store.checkAuth()
        } else {
            store.setIsLoading(false)
        }
    }, [])

    if (store.isLoading) {
        return <div style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Preloader/>
        </div>
    }


    return <React.StrictMode>
        <Context.Provider value={{store, notifications, ChatStore}}>
            <SystemNotifications/>
            <Router>
                {
                    !store.isAuth
                        ? <Routes>
                            <Route
                                path='/auth'
                                element={<Auth/>}
                            />
                            <Route
                                path='*'
                                element={<Navigate to="/auth"/>}
                            />
                        </Routes>
                        : <div className={style.wrap}>
                            <Sidebar/>
                            <div className={style.content}>
                                <PageTitle/>
                                <ErrorBoundary>
                                    <Routes>
                                        <Route
                                            path='/'
                                            element={<MainPage/>}/>
                                        <Route
                                            path='/archive'
                                            element={<TicketsPage/>}/>
                                        <Route
                                            path='/settings'
                                            element={<SettingsPage/>}/>
                                        <Route
                                            path='/chats'
                                            element={<ActiveTicketsPage/>}/>
                                        <Route
                                            path='/orders/:orderId'
                                            element={<OrderInfo/>}/>
                                        <Route
                                            path='*'
                                            element={<Navigate to="/"/>}
                                        />
                                    </Routes>
                                </ErrorBoundary>
                            </div>
                        </div>
                }
            </Router>
        </Context.Provider>
    </React.StrictMode>
})

ReactDOM.render(<App/>,
    document.getElementById('root')
);


