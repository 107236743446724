import {useEffect} from "react";
import pageTitle from "../../store/PageTitle";
import Chats from "../../components/Chats/Chats";

const ActiveTicketsPage = () => {
    useEffect(() => {
        pageTitle.setNewTitle('')
    }, [])

    return <Chats/>
}

export default ActiveTicketsPage