import {observer} from "mobx-react-lite";
import {useContext, useEffect} from "react";
import {Context} from "../../../../index";
import s from './ChatListCard.module.scss'
import f from "../../../../static/fonts.module.scss";
import m from "../../../../static/misc.module.scss";


const ChatListCard = ({data}) => {
    const {ChatStore} = useContext(Context)


    useEffect(() => {
    }, []);

    const changeChat = (id) => {
        if (ChatStore.selectedChatId) {
            //Для сохранения поля ввода при переключении актиывного чата
            const currentChat = ChatStore.chats.get(ChatStore.selectedChatId)
            ChatStore.chats.set(ChatStore.selectedChatId, {
                ...currentChat,
                currentMessage: ChatStore.activeMessage
            })
        }
        ChatStore.setSelectedChatId(id)
        ChatStore.setCurrentMessage(ChatStore.chats.get(id)?.currentMessage)
    }


    return <div className={`${s.chatListCardWrap} ${data.chatRoomId === ChatStore.selectedChatId ? s.active : ""}`}
                onClick={() => changeChat(data.chatRoomId)}
    >
        {
            ChatStore.chats.get(data.chatRoomId)?.newMessagesCounter !== 0
            && <div className={s.newIndicator}/>
        }
        <p className={f.subtitle1Bold}>Обращение
            #{data.id} от {new Date(Number(data.createDate)).toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            })}

        </p>
        <p className={f.subtitle1Light}>{data.name} {data.surname}</p>
        <p className={f.body1}>{data.question?.slice(0, 63)}</p>
        <div className={m.tableTags}>
            <p className={`${m.tag} ${f.body2}`}>{data.sTitle}</p>
            <p className={`${m.tag} ${f.body2}`}>{data.cTitle}</p>
        </div>
    </div>
}

export default observer(ChatListCard)
