import {useState} from "react";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";

const EditEmailModal = ({closeFunc, Store}) => {
    const [email, setEmail] = useState(Store.authProfile.email)
    const [emailIsValid, setEmailIsValid] = useState(Store.authProfile.email)

    return <ModalWindow
        title={"Изменить e-mail "}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={"Сохранить"}
        primaryAction={async () => {
            await Store.updateUserAuthProfile('EMAIL', JSON.stringify({
                email: email
            }), Store.authProfile.userId)
            closeFunc(false)
        }}
        primaryButtonIsDisabled={!emailIsValid}
    >
        <div style={{width: 550,}}>
            <Input title={'E-mail'}
                   value={email}
                   setValue={setEmail}
                   styles={{maxWidth: '100%'}}
                   validator={'email'}
                   setIsValid={setEmailIsValid}
            />
        </div>
    </ModalWindow>
}

export default EditEmailModal
