import {useEffect} from "react";


import Main from "../../components/Dashboard/Main";
import pageTitle from "../../store/PageTitle";

const MainPage = () => {
    useEffect(() => {
        pageTitle.setNewTitle('Мониторинг')
    }, [])


    return <Main/>
}

export default MainPage