import React, {useState} from "react";

import style from "./ModalWindow.module.scss"
import fonts from "../../../../static/fonts.module.scss"
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import Button from "../../UI/Button/Button";


const ModalWindow = ({
                         title,
                         children,
                         closeFunc,
                         type = 'default',
                         primaryAction = null,
                         primaryButtonTitle = 'Подтвердить',
                         primaryButtonIsDisabled = false
                     }) => {
    const [animation, setAnimation] = useState('fadeIn')

    return (
        <div className={style.bg}>
            <div className={`${style.wrap} ${animation}`}>
                <div className={style.titleRow} style={type === 'small' ? {justifyContent: 'center'} : null}>
                    <h4 className={fonts.boldH4}>{title}</h4>
                    {
                        type === 'default' &&
                        <button
                            className={`${style.closeButton} ${fonts.button}`}
                            onClick={() => {
                                setAnimation('fadeOut')
                                setTimeout(() => closeFunc(false), 200)
                            }}
                        >
                            Закрыть
                        </button>
                    }
                </div>
                <ErrorBoundary>
                    <div className={style.content}>{children}</div>
                </ErrorBoundary>
                {type === 'small' &&
                    <div className={style.modalButtons}>
                        <Button text={'Отмена'} type={'alert'} click={() => {
                            setAnimation('fadeOut')
                            setTimeout(() => closeFunc(false), 200)
                        }}/>
                        <Button text={primaryButtonTitle} click={async () => primaryAction()}
                                disabled={primaryButtonIsDisabled}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default ModalWindow