import s from './Reviews.module.scss'
import f from '../../../../../../static/fonts.module.scss'
import {ISOToDate} from "../../../../../../static/utilities";

import {useEffect, useState} from "react";
import {getReviewsByOrder} from "../../../../../../services/ReviewsService";

import Preloader from "../../../../../_BASE/UI/Preloader/Preloader";

import {ReactComponent as Star} from "./Star.svg";

const Reviews = ({orderId}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [reviews, setReviews] = useState();

    useEffect(() => {
        const getReviews = async () => {
            try {
                setIsLoading(true)
                const req = await getReviewsByOrder(orderId)
                setReviews(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getReviews()
    }, [orderId])

    if (isLoading) return <Preloader/>

    return <div className={s.reviewsWrap}>
        <h5 className={`${f.boldH5}`}>Отзывы</h5>
        <div className={s.reviews}>
            {
                (reviews?.studentReview.status || reviews?.teacherReview.status)
                    ? <>
                        {
                            reviews.studentReview?.status &&
                            <div className={s.reviewBlock}>
                                <div className={s.titleRow}>
                                    <p className={f.subtitle1Bold}>Заказчик</p>
                                    <div className={s.rate}>
                                        <Star/>
                                        <p className={f.subtitle1Bold}>{reviews.studentReview.message.rating} из
                                            5</p>
                                    </div>
                                </div>
                                <p className={f.body1}>
                                    {reviews.studentReview.message.comment}
                                </p>
                                <p className={f.body2}>{ISOToDate(reviews.studentReview.message.createDate)}                                                    </p>
                            </div>
                        }
                        {
                            reviews.teacherReview?.status &&
                            <div className={s.reviewBlock}>
                                <div className={s.titleRow}>
                                    <p className={f.subtitle1Bold}>Исполнитель</p>
                                    <div className={s.rate}>
                                        <Star/>
                                        <p className={f.subtitle1Bold}>{reviews.teacherReview.message.rating} из
                                            5</p>
                                    </div>
                                </div>
                                <p className={f.body1}>
                                    {reviews.teacherReview.message.comment}
                                </p>
                                <p className={f.body2}>{ISOToDate(reviews.teacherReview.message.createDate)}
                                </p>
                            </div>
                        }
                    </>
                    : <p className={f.subtitle1Bold}>Отзывы отсутствуют</p>
            }
        </div>
    </div>
}
export default Reviews