import $auth from "../http";
import {notifications} from "../index";

// Получение всех сообщений из чата
export const getAllMessages = async (type, typeId) => {
    try {
        const res = await $auth.get(`/v1/chat/room?type=${type}&id=${typeId}`)
        return res.data
    } catch (e) {
        notifications.addNotification('Не удалось загрузить сообщения')
        throw e
    }
}
