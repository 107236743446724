import React, {useContext, useState} from "react";
import style from './auth.module.scss'
import fonts from '../../static/fonts.module.scss'
import Input from "../../components/_BASE/UI/Input/Input";
import Button from "../../components/_BASE/UI/Button/Button";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const Auth = () => {
    const {store} = useContext(Context)

    const [login, setLogin] = useState(process.env.NODE_ENV === 'development' ? 'qq@ya.ru' : '')
    const [password, setPassword] = useState(process.env.NODE_ENV === 'development' ? 'qwerty' : '')

    const makeRequest = (e) => {
        e.preventDefault()
        store.login(login, password)
    }


    return (
        <div className={style.bg}>
            <div className={style.layout}>
                <h3 className={fonts.boldH3}>Служба техподдержки</h3>
                <form className={style.form}>
                    <div className={style.input}>
                        <Input title={"Логин"} value={login} setValue={setLogin}/>
                    </div>
                    <div className={style.input}>
                        <Input title={"Пароль"} value={password} password setValue={setPassword}/>
                    </div>

                    <div className={style.button}>
                        <Button text={"Авторизация"} click={(e) => makeRequest(e)}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default observer(Auth)
