import {useState} from "react";

import style from "./TabsPano.module.scss"
import fonts from "../../../../static/fonts.module.scss";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";


const TabsPano = ({data}) => {

    const [currentLink, setCurrentLink] = useState(data[0].link);


    const tabs = data.map(({label, link}) => {
        return (
            <button
                key={link}
                className={`
                    ${style.tab} 
                    ${fonts.button} 
                    ${link === currentLink ? style.active : ""}
                `}
                onClick={() => setCurrentLink(link)}
            >
                {label}
            </button>
        )
    })



    return (
        <>
            <div className={style.tabNavbar}>
                <div className={style.tabs}>
                    {tabs}
                </div>
            </div>
            <div className={style.tabContent}>
                <ErrorBoundary>
                    {data.filter(({link}) => currentLink === link)[0].content}
                </ErrorBoundary>
            </div>
        </>
    )
}

export default TabsPano