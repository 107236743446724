import {makeAutoObservable} from "mobx";

export default class NotificationsStore {
    notifications = new Map();

    constructor() {
        makeAutoObservable(this)
    }

    addNotification(message, status = 'error') {
        const uid = Date.now() * Math.floor(Math.random() * 100)
        this.notifications.set(uid, {message: message, status: status})
        setTimeout(() => this.notifications.delete(uid)
            , 3000)
    }
}