import s from './TicketModal.module.scss'
import f from '../../../static/fonts.module.scss'
import m from "../../../static/misc.module.scss";

import {useEffect, useState} from "react";
import {getTicket} from "../../../services/SupportService";
import {getAllMessages} from "../../../services/ChatService";

import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import ChatList from "../OrderInfo/ChatList/ChatList";
import UserCard from "../OrderInfo/UserCard/UserCard";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import EmptyList from "../../_BASE/Layout/Table/EmptyList/EmptyList";
import {timestampToDate} from "../../../static/utilities";

export const getColor = (statusLabel) => {
    if (
        statusLabel === 'В очереди'
    ) return `${m.statusTag} ${m.yellow}`
    if (
        statusLabel === 'В работе'

    ) return `${m.statusTag} ${m.blue}`
    if (
        statusLabel === 'Закрыт'
    ) return `${m.statusTag} ${m.red}`
}

const getDifferenceBetweenDates = (date1, date2) => {
    let delta = Math.abs(date1 - date2) / 1000;

    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    const seconds = Math.floor(delta) % 60;
    return {
        days,
        hours: hours < 10 ? "0" + hours : hours,
        minutes: minutes < 10 ? "0" + minutes : minutes,
        seconds: seconds < 10 ? "0" + seconds : seconds
    }
}

const CounterCard = ({date1, date2, text, color}) => {
    const [diff] = useState(getDifferenceBetweenDates(date1, date2));


    return <div className={`${s.counterCard} ${color === 'blue' ? s.blue : ''}`}>
        <p className={f.subtitle1Bold}>
            {text}
        </p>
        <h4 className={f.boldH4}>
            {diff.days !== 0 ? diff.days + "д" : ""}
            {diff.hours}ч
            {diff.minutes}м
        </h4>
    </div>
}

const TicketModal = ({closeFunc, id}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [chatIsLoading, setChatIsLoading] = useState(true);
    const [chatNotFound, setChatNotFound] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const orderData = await getTicket(id)
                setData(orderData)
                setIsLoading(false)
            } catch (e) {
                setIsLoading(false)
                throw e
            }
        }

        const getChat = async () => {
            try {
                setChatIsLoading(true)
                const messages = await getAllMessages('TICKET', id)
                setMessages(messages)
            } catch (e) {
                setChatNotFound(true)
                throw e
            } finally {
                setChatIsLoading(false)
            }
        }
        getData()
        getChat()
    }, [id])


    return <ModalWindow closeFunc={closeFunc}
                        title={`Обращение #${id}`}
    >
        {
            isLoading
                ? <Preloader/>
                : <div className={s.wrap}>
                    <div className={s.info}>
                        <div className={s.cards}>
                            <UserCard userId={data.userId}/>
                            <UserCard userId={data.aUserId}/>
                        </div>
                        <h5 className={f.boldH5}>Информация об обращении</h5>
                        <div className={s.desc}>
                            <h6 className={f.boldH6}>Описание проблемы:</h6>
                            <p className={f.body1}>
                                {data.question}
                            </p>
                        </div>
                        <div className={s.rows}>
                            <div className={s.row}>
                                <h6 className={f.boldH6}>Статус обращения:</h6>
                                <div className={m.dotline}/>
                                <p className={`${f.subtitle2Bold} ${getColor(data.tsTitle)}`}>{data.tsTitle}</p>
                            </div>
                            {
                                data.closeTitle &&
                                <div className={s.col}>
                                    <h6 className={f.boldH6}>Причина закрытия обращения:</h6>
                                    <p className={f.body1}>
                                        {data.closeTitle}
                                    </p>
                                </div>
                            }
                            {
                                data.closeComment &&
                                <div className={s.col}>
                                    <h6 className={f.boldH6}>Комментарий к закрытию обращения:</h6>
                                    <p className={f.body1}>
                                        {data.closeComment}
                                    </p>
                                </div>
                            }
                            <div className={s.row}>
                                <h6 className={f.boldH6}>Индентификатор обращения:</h6>
                                <div className={m.dotline}/>
                                <p className={f.subtitle1Bold}>{data.id}</p>
                            </div>
                            {
                                data.orderId !== 'NULL' && <div className={s.row}>
                                    <h6 className={f.boldH6}>ID прикрепленного заказа:</h6>
                                    <div className={m.dotline}/>
                                    <p className={`${f.subtitle1Bold}`}>{data.orderId}</p>
                                </div>
                            }
                            <div className={s.row}>
                                <h6 className={f.boldH6}>Категории обращения:</h6>
                                <div className={m.dotline}/>
                                <div className={m.tableTags}>
                                    <p className={`${m.tag} ${f.body2}`}>{data.sTitle}</p>
                                    <p className={`${m.tag} ${f.body2}`}>{data.cTitle}</p>
                                </div>
                            </div>
                            <div className={s.row}>
                                <h6 className={f.boldH6}>Дата создания обращения:</h6>
                                <div className={m.dotline}/>
                                <p className={f.subtitle1Bold}>{timestampToDate(data.createDate)}</p>
                            </div>
                            {
                                data.acceptanceTime &&
                                <div className={s.row}>
                                    <h6 className={f.boldH6}>Дата принятия в работу обращения</h6>
                                    <div className={m.dotline}/>
                                    <p className={f.subtitle1Bold}>{timestampToDate(data.acceptanceTime)}</p>
                                </div>
                            }
                            {
                                data.changeStatusTime &&
                                <div className={s.row}>
                                    <h6 className={f.boldH6}>Дата закрытия обращения:</h6>
                                    <div className={m.dotline}/>
                                    <p className={f.subtitle1Bold}>{timestampToDate(data.changeStatusTime)}</p>
                                </div>
                            }
                        </div>
                        {
                            data.changeStatusTime && data.acceptanceTime &&
                            <div className={s.counters}>
                                <CounterCard date1={data.acceptanceTime} date2={data.createDate}
                                             text={'Время ожидания первого ответа от агента ТП'} color={'blue'}/>
                                <CounterCard date1={data.changeStatusTime} date2={data.acceptanceTime}
                                             text={'Время обращения в работе'}/>
                            </div>
                        }
                    </div>
                    <div className={s.chat}>
                        {
                            chatIsLoading
                                ? <Preloader/>
                                : chatNotFound
                                    ? <EmptyList title={'Чат не найден'}/>
                                    : <ChatList data={messages} readOnly rightUserId={Number(data.aUserId)}/>

                        }
                    </div>
                </div>
        }
    </ModalWindow>
}

export default TicketModal