import {useEffect, useState} from "react";
import {getOrder} from "../../../../services/OrdersService";

import {ReactComponent as CloseIcon} from "../../icon1.svg";
import {ReactComponent as Star} from "../../Star.svg";

import {statusTag} from "../../../_PAGES/OrderList/TableRow/TableRow";
import s from "./ChatInfo.module.scss";
import f from "../../../../static/fonts.module.scss";
import m from "../../../../static/misc.module.scss";
import UserCard from "../../../_PAGES/OrderInfo/UserCard/UserCard";
import Button from "../../../_BASE/UI/Button/Button";
import CloseTicketModal from "../../CloseTicketModal/CloseTicketModal";
import {getReviewsByOrder} from "../../../../services/ReviewsService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import {getDate} from "../../../_PAGES/OrderInfo/OrderModal/OrderModal";


const TicketInfo = ({data, setTeacherId, setStudentId}) => {
    const [orderData, setOrderData] = useState();
    const [closeTicketModal, setCloseTicketModal] = useState(false);
    const [reviewsData, setReviewsData] = useState([]);

    const [reviewsAreLoading, setReviewsAreLoading] = useState(true);

    useEffect(() => {
        if (data?.orderId !== 'NULL') {
            const getData = async () => {
                const orderData = await getOrder(data.orderId)
                setOrderData(orderData)
                setStudentId(orderData.studentInfo?.userId)
                setTeacherId(orderData?.teacherInfo?.userId)
            }

            const getReviews = async () => {
                setReviewsAreLoading(true)
                const req = await getReviewsByOrder(data.orderId)
                setReviewsData(req)
                setReviewsAreLoading(false)
            }
            getData()
            getReviews()
        }
    }, [data, setTeacherId, setStudentId])


    if (!data) {
        return null
    }


    return <div className={s.wrap}>
        <Button type={'alert'} text={'Закрыть обращение'} icon={<CloseIcon/>}
                click={() => setCloseTicketModal(true)}
        />
        <div className={s.ticketInfoWrap}>

            <UserCard userId={data.userId} style={{backgroundColor: '#E6F1FF'}}/>
            <div className={s.flexBlock}>
                <h6 className={`${f.boldH6}`}>Описанная проблема</h6>
                <p className={f.body1}>{data.question}</p>
            </div>
            {orderData && <>
                <div className={s.flexBlock}>
                    <h6 className={`${f.boldH6}`}>Прикрепленный заказ к обращению</h6>
                    <div className={s.infoRows}>
                        <div className={s.infoRow}>
                            <p className={f.subtitle1Bold}>Статус заказа</p>
                            {statusTag(orderData.orderStatusTitle, 10)}
                        </div>
                        <div className={s.infoRow}>
                            <p className={f.subtitle1Bold}>Индентификатор заказа:</p>
                            <p className={f.body1}>{orderData.orderId}</p>
                        </div>
                        <div className={s.infoCol}>
                            <p className={f.subtitle1Bold}>Категории заказа:</p>
                            <div className={m.tableTags}>
                                <p className={`${m.bigTag} ${f.subtitle1Bold}`}>{orderData.competency.subjectTitle}</p>
                                <p className={`${m.bigTag} ${f.subtitle1Bold}`}>{orderData.competency.subjectOption}</p>
                            </div>
                        </div>
                        <div className={s.infoRow}>
                            <p className={f.subtitle1Bold}>Стоимость заказа:</p>
                            <p className={f.body1}>{orderData.price} ₽</p>
                        </div>
                        <div className={s.infoRow}>
                            <p className={f.subtitle1Bold}>Заказ создан:</p>
                            <p className={f.body1}>{getDate(orderData.createDate)}</p>
                        </div>
                        <div className={s.infoRow}>
                            <p className={f.subtitle1Bold}>Заказ изменен:</p>
                            <p className={f.body1}>{getDate(orderData.statusChangeDate)}</p>
                        </div>
                        {
                            orderData.warrantyEndDate &&
                            <div className={s.infoRow}>
                                <p className={f.subtitle1Bold}>Дата истечения гарантии:</p>
                                <p className={f.body1}>{getDate(orderData.warrantyEndDate)}</p>
                            </div>
                        }
                        <p className={`${s.infoBanner} ${f.body1}`}>
                            Для получения более подробной информации по заказу воспользуйтесь кнопкой «Просмотр заказа»
                            в
                            меню
                            «Действия»
                        </p>
                    </div>
                </div>
                {
                    reviewsAreLoading
                        ? <Preloader/>
                        : <div className={s.flexBlock}>
                            <h6 className={`${f.boldH6}`}>Отзывы</h6>
                            {
                                (reviewsData.studentReview.status || reviewsData.teacherReview.status)
                                    ? <div className={s.infoRows}>
                                        {
                                            reviewsData.studentReview?.status &&
                                            <div className={s.reviewBlock}>
                                                <div className={s.titleRow}>
                                                    <p className={f.subtitle1Bold}>Заказчик</p>
                                                    <div className={s.rate}>
                                                        <Star/>
                                                        <p className={f.subtitle1Bold}>{reviewsData.studentReview.message.rating} из
                                                            5</p>
                                                    </div>
                                                </div>
                                                <p className={f.body1}>
                                                    {reviewsData.studentReview.message.comment}
                                                </p>
                                                <p className={f.body2}>
                                                    {
                                                        new Date(reviewsData.studentReview.message.createDate).toLocaleDateString('ru-RU', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                        })
                                                    }
                                                </p>
                                            </div>
                                        }
                                        {
                                            reviewsData.teacherReview?.status &&
                                            <div className={s.reviewBlock}>
                                                <div className={s.titleRow}>
                                                    <p className={f.subtitle1Bold}>Исполнитель</p>
                                                    <div className={s.rate}>
                                                        <Star/>
                                                        <p className={f.subtitle1Bold}>{reviewsData.teacherReview.message.rating} из
                                                            5</p>
                                                    </div>
                                                </div>
                                                <p className={f.body1}>
                                                    {reviewsData.teacherReview.message.comment}
                                                </p>
                                                <p className={f.body2}>
                                                    {
                                                        new Date(reviewsData.teacherReview.message.createDate).toLocaleDateString('ru-RU', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                        })
                                                    }
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    : <p className={f.subtitle1Bold}>Отзывы отсутствуют</p>

                            }

                        </div>
                }

            </>
            }
            {closeTicketModal &&
                <CloseTicketModal chatId={data.chatRoomId} closeFunc={setCloseTicketModal} ticketId={data.id}/>}
        </div>
    </div>


}

export default TicketInfo
