import style from './UserInfo.module.scss'
import f from '../../../static/fonts.module.scss'

import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";

import TabsPano from "../../_BASE/Layout/TabsPano/TabsPano";

import UserProfileStore from "../../../store/profile";
import Profile from "./Profile/Profile";
import OrdersTable from "./OrdersTable/OrdersTable";
import LogsTable from "./LogsTable/LogsTable";
import RefTable from "./RefTable/RefTable";
import SkillsTable from "./SkillsTable/SkillsTable";
import ReviewsTable from "./ReviewsTable/ReviewsTable";
import PaymentsTable from "./PaymentsTable/PaymentsTable";
import BonusHistoryTable from "./BonusHistoryTable/BonusHistoryTable";


export const userStore = new UserProfileStore()

const UserInfo = ({id}) => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        const getData = async () => {
            userStore.resetValues()
            await userStore.setUserId(id)
            await userStore.setIsLoaded(false)
            await userStore.getUserAuthProfile()
            await userStore.getUserCompetencyProfile()
            await userStore.setIsLoaded(true)
            if (userStore.authProfile.userRoleTitleCode === 'STUDENT') {
                setContent(<TabsPano data={
                    [
                        {
                            label: 'Заказы',
                            content: <OrdersTable/>,
                            link: 'orders'
                        },
                        {
                            label: 'События и Логи',
                            content: <LogsTable/>,
                            link: 'logs'
                        },
                        {
                            label: 'Платежи',
                            content: <PaymentsTable/>,
                            link: 'payments'
                        },
                        {
                            label: 'Отзывы',
                            content: <ReviewsTable/>,
                            link: 'reviews'
                        },
                        {
                            label: 'Реферальная программа',
                            content: <RefTable/>,
                            link: 'ref'
                        },
                        {
                            label: 'Бонусы',
                            content: <BonusHistoryTable/>,
                            link: 'bonuses'
                        },
                    ]
                }/>)
            }
            if (userStore.authProfile.userRoleTitleCode === 'TEACHER') {
                setContent(<TabsPano data={
                    [
                        {
                            label: "Заказы",
                            content: <OrdersTable/>,
                            link: 'orders'
                        },
                        {
                            label: "События и логи",
                            content: <LogsTable/>,
                            link: 'logs'
                        },
                        {
                            label: 'Платежи',
                            content: <PaymentsTable/>,
                            link: 'payments'
                        },
                        {
                            label: "Компетенции",
                            content: <SkillsTable/>,
                            link: 'skills'
                        },
                        {
                            label: 'Отзывы',
                            content: <ReviewsTable/>,
                            link:'reviews'
                        },
                        {
                            label: "Реферальная программа",
                            content: <RefTable/>,
                            link: 'ref'
                        },
                        {
                            label: 'Бонусы',
                            content: <BonusHistoryTable/>,
                            link: 'bonuses'
                        },
                    ]
                }/>)
            }
        }
        getData()
    }, [id])


    return (
        <div className={style.wrap}>
            <div className={style.leftBar}>
                <Profile/>
            </div>
            <div className={style.rightPanel}>
                {
                    userStore.competencyProfile
                        ? content
                        : <div className={style.contentWrap}>
                            <p className={f.boldH5}>Создайте профиль компетенций для полноценной работы</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default observer(UserInfo)