import  s from './PaymentsTable.module.scss'
import SearchTable from "../../Payments/Operations/SearchTable/SearchTable";
import {userStore} from "../UserInfo";
import fonts from "../../../../static/fonts.module.scss";

const PaymentsTable = () => {

    return <div className={s.wrap}>
        <h4 className={`${fonts.boldH4}`}>Платежи пользователя</h4>
        <SearchTable searchID={userStore.userId}
                     searchCode={userStore.authProfile.userRoleTitleCode === 'TEACHER'
                         ? "EXECUTOR_ID"
                         : "CUSTOMER_ID"
                     }
        />
    </div>
}

export default PaymentsTable