import s from './ChatSelector.module.scss'
import f from '../../../static/fonts.module.scss'
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import ChatListCard from "./ChatListCard/ChatListCard";
import {useContext, useEffect, useState} from "react";
import {getAgentStats, getAgentTickets} from "../../../services/SupportService";
import {Context, notifications, store} from "../../../index";
import {createSocketConnection} from "../../../services/Sockets";
import {observer} from "mobx-react-lite";

const ChatSelector = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasTickets, setHasTickets] = useState(false);
    const {ChatStore} = useContext(Context)

    const getData = async () => {
        try {
            setIsLoading(true)
            const data = await getAgentTickets(store.user.userId, 'IN_WORK', 1, 100)
            data.forEach(chat => {
                const socket = createSocketConnection({
                    chatRoomType: "TICKET",
                    chatRoomTypeId: chat.id
                })


                ChatStore.setNewChat({
                    socket: socket,
                    data: chat,
                    messages: null,
                    newMessagesCounter: 0,
                    currentMessage: {text: '', attachments: []}
                }, chat.chatRoomId)

                socket.on('chat:message:get', (response) => {
                    ChatStore.loadMessages(response.data, chat.chatRoomId)
                });

                socket.on("connect_error", (response) => {
                    console.log(response)
                    socket.connect()
                });

                //Собственнное сообщение дошло до сервера
                socket.on('chat:message:status', (response) => {
                    ChatStore.addMyMessage(response.data.message, chat.chatRoomId)
                })

                socket.on('chat:message:read', (response) => {
                    try {
                        ChatStore.readMessageFromList(response.data.id, response.data.chatRoomId)
                    } catch (e) {
                        console.log(e)
                        throw e
                    }
                })

                //Пришло сообщение от другого пользователя
                socket.on('chat:message:new', (response) => {
                    try {
                        ChatStore.addNewMessage(response, chat.chatRoomId)
                    } catch (e) {
                        console.log(e)
                        throw e
                    }
                })

                socket.on('chat:message:remove', (response) => {
                    if (response.status) {
                        ChatStore.removeMessageFromList(response.data.id, chat.chatRoomId)
                    } else {
                        notifications.addNotification(response?.message)
                    }
                })

                socket.on('chat:message:edit', (response) => {
                    if (response.status) {
                        ChatStore.editMessageFromList(response.data.id, response.data.message, chat.chatRoomId)
                    } else {
                        notifications.addNotification(response?.message)
                    }
                })
            })
            ChatStore.setSelectedChatId(data[0].chatRoomId)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }

    const getCount = async () => {
        try {
            const today = new Date().toISOString().split('T')[0]
            const count = await getAgentStats(null, 'IN_WORK', '1970-01-01', today)
            if(count !== 0){
                setHasTickets(true)
            }
            if (count === 0) {
                setIsLoading(false)
            }
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        getCount()
        ChatStore.reset()
    }, [])

    useEffect(() => {
        if (hasTickets) {
            getData()
        }
    }, [hasTickets])

    if (isLoading) {
        return <Preloader/>
    }

    return ChatStore.chatCards?.length === 0
        ? <p className={f.boldH5}>Активных обращений нет</p>
        : <div className={s.chatsColumn}>
            <div className={s.title}>
                <h5 className={f.boldH5}>Активных обращений:</h5>
                <p className={f.boldH5}>{ChatStore.chatCards?.length}</p>
            </div>
            <div className={s.chatListWrap}>
                {isLoading
                    ? <Preloader/>
                    : ChatStore.chatCards.map((item) => <ChatListCard
                        data={item.data} key={item.data.id}
                    />)}
            </div>
        </div>
}

export default observer(ChatSelector)
