import TicketList from "../../components/_PAGES/TicketList/TicketList";
import {useEffect} from "react";
import pageTitle from "../../store/PageTitle";

const TicketsPage = () => {
    useEffect(() => {
        pageTitle.setNewTitle('Мои обращения')
    }, [])
    return <TicketList/>
}

export default TicketsPage