import s from "./main.module.scss";
import f from '../../static/fonts.module.scss'
import m from '../../static/misc.module.scss'

import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {getAgentTicketsCount, getAgentStats, getAgentTickets, openTicket} from "../../services/SupportService";

import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'
import Table from "../_BASE/Layout/Table/Table";
import Card from "./Card/Card";
import Button from "../_BASE/UI/Button/Button";
import DropdownButton from "../_BASE/UI/Button/DropdownButton/DropdownButton";
import Pagination from "../_BASE/UI/Pagination/Pagination";
import ModalWindow from "../_BASE/Layout/ModalWindow/ModalWindow";
import TicketModal from "../_PAGES/TicketList/TicketModal/TicketModal";
import Preloader from "../_BASE/UI/Preloader/Preloader";
import PopularCategories from "./PopularCategories/PopularCategories";

export const getColor = (statusLabel) => {
    if (
        statusLabel === 'В очереди'
    ) return `${m.statusTag} ${m.yellow}`
    if (
        statusLabel === 'В работе'

    ) return `${m.statusTag} ${m.blue}`
    if (
        statusLabel === 'Закрыт'
    ) return `${m.statusTag} ${m.red}`
}


const TableRow = ({rowData}) => {
    const [ticketModal, setTicketModal] = useState(false);

    const actions = [
        {
            label: 'Посмотреть обращение',
            func: () => setTicketModal(true)
        }
    ]

    return <>
        <div className={m.tableTags}>
            <p className={`${m.tag} ${f.body2}`}>{rowData.sTitle}</p>
            <p className={`${m.tag} ${f.body2}`}>{rowData.cTitle}</p>
        </div>
        <p className={f.body2}>{rowData.question}</p>
        <p className={f.body2}>{new Date(Number(rowData.createDate)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })}</p>
        <p className={`${f.subtitle2Bold} ${getColor(rowData.tsTitle)}`}>{rowData.tsTitle}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>

        {
            ticketModal && <TicketModal id={rowData.id} closeFunc={setTicketModal}/>
        }
    </>
}
const date = new Date()
const currentDate = new Date().toISOString().split('T')[0]
const weekAgo = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]
const monthAgo = new Date(date.getTime() - (31 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]


const SingleCard = ({type, title, img, theme, tag, actions, dateFrom}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [counter, setCounter] = useState();



    useEffect(() => {
        const getCounter = async () => {
            try {
                setIsLoading(true)
                const today = new Date().toISOString().split('T')[0]
                const counter = await getAgentStats(
                    null,
                    type,
                    dateFrom ? dateFrom : '1970-01-01',
                    today
                )
                setCounter(counter)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getCounter()
    }, [dateFrom])

    if(isLoading){
        return <div className={s.preloaderCard}><Preloader/></div>
    }

    return <Card title={title}
                 icon={<img src={img} alt={''}/>}
                 counter={counter}
                 theme={theme}
                 isLoading={isLoading}
                 actions={actions}
                 tag={tag}
    />
}

const Main = () => {
    const [counter3Period, setCounter3Period] = useState({label: 'За сегодня', date: currentDate});


    const counter3Periods = [
        {
            label: 'За сегодня',
            func: () => setCounter3Period({label: 'За сегодня', date: currentDate})
        },
        {
            label: 'За неделю',
            func: () => setCounter3Period({label: 'За неделю', date: weekAgo})
        },
        {
            label: 'За месяц',
            func: () => setCounter3Period({label: 'За месяц', date: monthAgo})
        },
        {
            label: 'За все время',
            func: () => setCounter3Period({label: 'За все время', date: null})
        },
    ]

    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });

    const [total, setTotal] = useState(null);

    const [openTicketModal, setOpenTicketModal] = useState(false);

    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const openTicketClick = async (id) => {
        const req = await openTicket(id)
        if (req) {
            navigate('/chats')
        }
    }

    useEffect(() => {
        const getRecordCount = async () => {
            try {
                const count = await getAgentTicketsCount(null, 'IN_QUEUE')
                setTotal(count)
            } catch (e) {
                throw e
            }
        }
        getRecordCount()
    }, [])


    useEffect(() => {
        if (total !== null) {
            const getData = async () => {
                try {
                    setIsLoading(true)
                    const data = await getAgentTickets(null, 'IN_QUEUE', paginationObject.page, paginationObject.offset)
                    setData(data)
                    setIsLoading(false)
                } catch (e) {
                    throw e
                }
            }

            getData()
        }
    }, [paginationObject.page, paginationObject.offset, total])

    if (isLoading) return <Preloader/>

    return <div className={s.wrap}>
        <div className={s.cards}>
            <SingleCard type={'IN_QUEUE'} title={'Обращений в очереди'} img={img1} theme={'blue'}/>
            <SingleCard type={'IN_WORK'} title={'Обращений в работе'} img={img2}/>
            <SingleCard type={'CLOSE'} title={'Обращений закрыто'} img={img3}
                        tag={counter3Period.label} dateFrom={counter3Period.date}
                        actions={counter3Periods}
            />
        </div>
        <div className={s.table}>
            {
                total !== 0
                    ? <div>
                        <Table headsArray={[
                            'Категория',
                            'ТЕМА',
                            'ДАТА СОЗДАНИЯ',
                            'СТАТУС',
                            'ДЕЙСТВИЯ',
                        ]}
                               gridTemplate={'3fr 5fr 4fr 4fr 91px'}
                               rowsArray={data.map((item) => <TableRow
                                   rowData={item}
                                   key={item.id}
                                   dataSetter={setData}
                               />)}/>
                        <Pagination
                            paginationObject={paginationObject}
                            setPaginationObject={setPaginationObject}
                            totalCount={total}
                        />
                    </div>
                    : <div className={s.emptyTicketList}>
                        <p style={{marginBottom: 24}} className={f.boldH4}>У вас нет обращений в очереди</p>
                        <Button click={() => navigate('/chats')}
                                text={'Перейти в активные обращения'}
                                type={'secondary'}
                                nestedStyles={{backgroundColor: "white"}}
                        />
                    </div>
            }
            <div className={s.chart}>
                {
                    total !== 0 &&
                    <Button text={'Получить новое обращение'}
                            nestedStyles={{width: '100%', justifyContent: "center"}}
                            click={() => setOpenTicketModal(true)}/>
                }
                <PopularCategories/>
            </div>
        </div>

        {
            openTicketModal && <ModalWindow closeFunc={setOpenTicketModal}
                                            title={'Взять в работу обращение'}
                                            type={'small'}
                                            primaryAction={async () => openTicketClick(data[0].id)}
            >
                <p className={f.subtitle1Bold}>Вы действительно хотите взять тикет #{data[0].id} в работу?</p>
            </ModalWindow>
        }
    </div>
}

export default Main
