import s from './SystemInfo.module.scss'
import f from '../../../../../static/fonts.module.scss'

import React, {useEffect, useState} from "react";
import {timestampToDate} from "../../../../../static/utilities";
import {notifications} from "../../../../../index";
import {getOrderPriceStats, getOrderStatusHistory} from "../../../../../services/OrdersService";
import {createNewPaymentInvoice, getOperationsListWithFilter} from "../../../../../services/PaymentService";

import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import SpecList from "../../../../_BASE/Layout/SpecList/SpecList";
import Table from "../../../../_BASE/Layout/Table/Table";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Button from "../../../../_BASE/UI/Button/Button";

import {ReactComponent as Plus} from "./Plus.svg";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";
import Radios from "../../../../_BASE/UI/Radios/Radios";

const SystemInfo = ({orderId, orderTitle, customerUserId, executorUserId}) => {
    return <div className={s.wrap}>
        <StatusHistory orderId={orderId}/>
        <PaymentsList orderId={orderId}
                      orderTitle={orderTitle}
                      customerUserId={customerUserId}
                      executorUserId={executorUserId}
        />
        <PriceStats orderId={orderId}/>
    </div>
}

const StatusHistory = ({orderId}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOrderStatusHistory(orderId)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                console.log(e)
            }
        }
        getData()
    }, [orderId])

    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.block}>
        <h5 className={f.boldH5}>История</h5>
        <Table headsArray={["Старый стаус", "Новый статус", "Дата"]}
               gridTemplate={"2fr 2fr 1fr"}
               rowsArray={data.map(rowData => <React.Fragment key={rowData.timestamp}>
                   <p className={f.body1}>{rowData.oldTitle}</p>
                   <p className={f.body1}>{rowData.newTitle}</p>
                   <p className={f.body1}>{timestampToDate(rowData.timestamp)}</p>
               </React.Fragment>)}
        />
    </div>
}

const PriceStats = ({orderId}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOrderPriceStats(orderId)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                console.log(e)
            }
        }

        getData()
    }, [orderId])

    if (isLoading) return <Preloader type={'big'}/>

    const params = [
        {
            label: 'Базовая стоимость компетенции',
            value: data.basePrice + " ₽"
        },
        {
            label: 'Комиссия системы:',
            value: data.systemFee + " ₽"
        },
        {
            label: 'Другие комиссии:',
            value: data.otherSystemFee + " ₽"
        },
        {
            label: 'Комиссия на приход (Банк):',
            value: data.incomeFee + " ₽"
        },
        {
            label: 'Комиссия на уход (Банк):',
            value: data.withdrawalFee + " ₽"
        },
    ]
    return <SpecList blockTitle={'Анализ стоимости заказа'}
                     params={params}/>

}

const PaymentsList = ({orderId, orderTitle, customerUserId, executorUserId}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOperationsListWithFilter({
                    searchCode: "ORDER_ID",
                    data: orderId,
                    offset: 100,
                    page: 1
                })
                setData(req)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                setNotFound(true)
                console.log(e)
            } finally {
                setIsLoading(false)
            }
        }

        getData()
    }, [orderId])

    if (isLoading) return <Preloader type={'big'}/>

    if (notFound) return null

    return <div className={s.block}>
        <h5 className={f.boldH5}>Список платежей</h5>
        <Table headsArray={["ID Платежа", "Дата", "Статус"]}
               gridTemplate={'1fr 1fr 1fr'}
               rowsArray={data.map(row => <SinglePaymentListRow row={row} key={row.id}/>
               )}/>
    </div>
}

const SinglePaymentListRow = ({row}) => {


    return <>
        <p className={f.body1}>{row.id}</p>
        <p className={f.body1}>{timestampToDate(row.timestamp)}</p>
        <p className={f.body1}>{row.status}</p>
    </>
}
export default SystemInfo