import {useState} from "react";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";

const EditPhoneModal = ({closeFunc, Store}) => {
    const [phone, setPhone] = useState(Store.authProfile.phone)
    const [phoneIsValid, setPhoneIsValid] = useState(Store.authProfile.phone)
    return <ModalWindow
        title={"Изменить телефон"}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={"Сохранить"}
        primaryAction={async () => {
            await Store.updateUserAuthProfile('PHONE', JSON.stringify({
                phone: phone
            }), Store.authProfile.userId)
            closeFunc(false)
        }}
        primaryButtonIsDisabled={!phoneIsValid}
    >
        <div style={{width: 550,}}>
            <Input title={'Телефон'}
                   value={phone}
                   setValue={setPhone}
                   styles={{maxWidth: '100%'}}
                   validator={'phone'}
                   maxLength={11}
                   setIsValid={setPhoneIsValid}
            />
        </div>
    </ModalWindow>
}

export default EditPhoneModal
