import s from './Sidebar.module.scss'
import f from '../../../static/fonts.module.scss'
import {Link} from "react-router-dom";

import {ReactComponent as Icon1} from "./icons/Chart.svg";
import {ReactComponent as Icon2} from "./icons/Chat.svg";
import {ReactComponent as Icon3} from "./icons/Paper.svg";
import {ReactComponent as Icon4} from "./icons/Setting.svg";
import {ReactComponent as Icon5} from "./icons/Logout.svg";
import {store} from "../../../index";

const Sidebar = () => {
    return (
        <div className={s.wrap}>
            <div className={s.navs}>
                <div>
                    <Link to="/" className={s.nav}>
                        <Icon1/>
                        <p className={`${f.body1} ${s.navTitle}`}>Мониторинг</p>
                    </Link>
                    <Link to="/chats" className={s.nav}>
                        <Icon2/>
                        <p className={`${f.body1} ${s.navTitle}`}>Активные обращения</p>
                    </Link>
                    <Link to="/archive" className={s.nav}>
                        <Icon3/>
                        <p className={`${f.body1} ${s.navTitle}`}>Список обращений</p>
                    </Link>
                    <Link to="/settings" className={s.nav}>
                        <Icon4/>
                        <p className={`${f.body1} ${s.navTitle}`}>Настройки</p>
                    </Link>
                </div>


                <Link to="/logout" className={s.nav} onClick={() => store.logout()}>
                    <Icon5/>
                    <p className={`${f.body1} ${s.navTitle}`}>Выход</p>
                </Link>
            </div>
        </div>
    )
}

export default Sidebar