import $auth from "../http";
import {notifications} from "../index";

// Получение параметров сервиса оплаты (Ценообразовательных коэффициентов)
export const getRates = async () => {
    try {
        const req = await $auth.get('/v1/payment/options?optionsCode=PRICE_OPTIONS')
        return req.data
    } catch (e) {
        console.log({e})
    }
}

//Обновление ценообразовательных коэффициентов
export const updateRates = async (optionsCode, value) => {
    try {
        const req = await $auth.post('/v1/payment/options', {
            optionsCode: optionsCode,
            value: value
        })
        notifications.addNotification(req.data.message, 'OK')
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение списка промокодов
export const getPromoList = async (status, offset, page) => {
    try {
        const req = await $auth.get(`/v1/payment/promo/list?requestType=LIST&lifeStatus=${status}&page=${page}&offset=${offset}`)
        return req.data
    } catch (e) {
        console.log({e})
    }
}


//Получение количества страниц на странице промокодов
export const getPromoCount = async (status) => {
    try {
        const req = await $auth.get(`/v1/payment/promo/list?requestType=NUMBER_OF_PAGE&lifeStatus=${status}&offset=20`)
        return req.data?.recordCount
    } catch (e) {
        console.log({e})
    }
}

// Получение параметров сервиса оплаты (Ценообразовательных коэффициентов)
export const getPromoStats = async () => {
    try {
        const req = await $auth.get('/v1/payment/options?optionsCode=REFERRAL_CODE_OPTIONS')
        return req.data
    } catch (e) {
        console.log({e})
    }
}

//Создание нового промокода
export const createPromo = async (data) => {
    try {
        const req = await $auth.post(`/v1/payment/promo/create`, {
            title: data.title,
            comment: data.comment,
            secretWord: data.secretWord,
            reward: data.reward,
            endDate: data.endDate,
            numberOfActivations: data.numberOfActivations,
        })
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

// Получение количества пользователей активировавших промокод
export const getReferralsCount = async (id, type) => {
    try {
        const req = await $auth.get(`/v1/payment/promo/list/users?requestType=NUMBER_OF_PAGE&id=${id}&promocodeType=${type}&offset=10`)
        return req.data.recordCount
    } catch (e) {
        console.log({e})
    }
}

// Получение списка пользователей активировавших промокод
export const getReferralsList = async (id, type, page, offset) => {
    try {
        const req = await $auth.get(`/v1/payment/promo/list/users?requestType=LIST&id=${id}&promocodeType=${type}&page=${page}&offset=${offset}`)
        return req.data
    } catch (e) {
        console.log({e})
    }
}

//Изменение текщего промокода
export const updatePromo = async (id, data) => {
    try {
        const req = await $auth.put(`/v1/payment/promo/update/${id}`, {
            title: data.title,
            comment: data.comment,
            reward: data.reward,
            endDate: data.endDate,
            numberOfActivations: data.numberOfActivations,
        })
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Архивирование или разархивирование промокодов
export const archivePromo = async (id, status) => {
    try {
        const req = await $auth.put(`/v1/payment/promo/archive/${id}`, {
            status: status
        })
        notifications.addNotification(req.data.message, 'OK')
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение дополнительной информации по реферальному промокоду
export const getPromoAdditionalInfo = async (id, type) => {
    try {
        const req = await $auth.get(`/v1/payment/promo/ref/information?requestType=${type}&id=${id}`)
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

// Получение информации по промокоду
export const getPromoInfo = async (id, type) => {
    try {
        const req = await $auth.get(`/v1/payment/promo?id=${id}&promocodeType=${type}`)
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение списка запросов на зачисление средств на счет
export const getHelpdeskRequests = async (page, offset, type) => {
    try {
        const req = await $auth.get(`/v1/payment/wallet/requests/bonus?requestType=LIST&page=${page}&offset=${offset}&code=${type}`)
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение количества запросов на зачисление средств на счет
export const getHelpdeskRequestsCount = async (type) => {
    try {
        const req = await $auth.get(`/v1/payment/wallet/requests/bonus?requestType=NUMBER_OF_PAGE&offset=10&code=${type}`)
        return req.data.recordCount
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение информации по запросу начисления средств на счет
export const getHelpdeskRequestInfo = async (id) => {
    try {
        const req = await $auth.get(`/v1/payment/wallet/requests/bonus/${id}`)
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Принятие или отклонение запроса на зачисление средств на счет пользователя
export const operateHelpdeskRequest = async (id, status) => {
    try {
        const req = await $auth.put(`/v1/payment/wallet/requests/bonus/${id}`, {
            status
        })
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

//Получение списка запросов на зачисление средств на счет
export const getHelpdeskUserRequests = async (user, page, offset, userType) => {
    try {
        const req = await $auth.get(`/v1/payment/wallet/requests/bonus?requestType=LIST&page=${page}&offset=${offset}&userId=${user}&code=FALSE&userType=${userType}`)
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение количества запросов на зачисление средств на счет
export const getHelpdeskUserRequestsCount = async (user, userType) => {
    try {
        const req = await $auth.get(`/v1/payment/wallet/requests/bonus?requestType=NUMBER_OF_PAGE&offset=10&userId=${user}&code=FALSE&userType=${userType}`)
        return req.data.recordCount
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение баланса платежного кошелька
export const getWalletBalance = async (id) => {
    try {
        const req = await $auth.get(`/v1/payment/wallet/balance?id=${id}`)
        return req.data.balance
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}


//Получение способов оплаты пользователя
export const getUserPaymentMethods = async (id) => {
    try {
        const req = await $auth.get('/v1/payment/wallet/methods', {
            params: {
                id: id
            }
        })
        return req.data
    } catch (e) {
        console.log(e)
        notifications.addNotification(e.response.data.message)
    }
}


//Удаление способа оплаты
export const deleteUserPaymentModal = async (id, userId) => {
    try {
        const req = await $auth.delete('/v1/payment/wallet/methods', {
                params: {
                    id: id,
                    userId: userId
                }
            }
        )
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        console.log(e)
    }
}

//Получение списка платежей
export const getOperationsList = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/order/invoice/list', {
                params: {
                    code: 'LIST',
                    categoryCode: "ALL",
                    firstDate: params.firstDate ? params.firstDate : "10", // 01-01-2000
                    secondDate: params.secondDate ? params.secondDate : "32503683661000", // 01-01-3000,
                    offset: params.offset,
                    page: params.page
                }
            }
        )

        return req.data.data
    } catch (e) {
        throw e
    }
}

//Получение количества платежей
export const getOperationsCount = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/order/invoice/list', {
                params: {
                    code: 'NUMBER_OF_PAGE',
                    categoryCode: "ALL",
                    firstDate: params.firstDate ? params.firstDate : "10", // 01-01-2000
                    secondDate: params.secondDate ? params.secondDate : "32503683661000", // 01-01-3000,
                    offset: 20,
                    page: params.page
                }
            }
        )
        console.log(req.data.data.recordCount)
        return req.data.data.recordCount
    } catch (e) {
        throw e
    }
}

//Получение списка платежей с фильтрами
export const getOperationsListWithFilter = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/order/invoice/list/search', {
                params: {
                    code: 'LIST',
                    searchCode: params.searchCode,
                    data: params.data,
                    offset: params.offset,
                    page: params.page
                }
            }
        )

        return req.data.data
    } catch (e) {
        throw e
    }
}

//Получение количества платежей с фильтрами
export const getOperationsCountWithFilter = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/order/invoice/list/search', {
                params: {
                    code: 'NUMBER_OF_PAGE',
                    searchCode: params.searchCode,
                    data: params.data,
                    offset: 20,
                }
            }
        )
        console.log(req.data.data.recordCount)
        return req.data.data.recordCount
    } catch (e) {
        throw e
    }
}

export const getOperationInfo = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/order/invoice/get', {
                params: {
                    code: 'RECORD_ID',
                    id: params.id
                }
            }
        )
        return req.data
    } catch (e) {
        throw e
    }
}

//Создание счета для оплаты заказа
export const createNewPaymentInvoice = async (params) => {
    try {
        const req = await $auth.post('/v1/payment/order/invoice/create', {
                id: params.orderId,
                description: params.description,
                amount: params.amount,
                useBonus: params.useBonus,
                paymentMethodId: false,
                customerUserId: params.customerUserId,
                executorUserId: params.executorUserId,
            }
        )
        return req.data
    } catch (e) {
        throw e
    }
}


//Отмена оплаты заказа
export const cancelPaymentRequest = async (params) => {
    try {
        const req = await $auth.post('/v1/payment/order/invoice/cancel', {
            code: "RECORD_ID",
            cancelCode: params.cancelCode,
            id: params.id,
            amount: params.amount
        })
        return req.data
    } catch (e) {
        throw e
    }
}

//Подтверждение оплаты заказа
export const confirmPaymentRequest = async (params) => {
    try {
        const req = await $auth.post('/v1/payment/order/invoice/confirm', {
            code: "RECORD_ID",
            id: params.id,
        })
        return req.data
    } catch (e) {
        throw e
    }
}

// Получение статуса платежа используя АПИ банка
export const getPaymentInfoFromBank = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/bank/status/invoice', {
                params: {
                    code: 'RECORD_ID',
                    id: params.id
                }
            }
        )
        return req.data
    } catch (e) {
        throw e
    }
}

//Получить статус заказа используя АПИ банка
export const getOrderInfoFromBank = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/bank/status/order', {
                params: {
                    code: 'ORDER_ID',
                    id: params.id
                }
            }
        )
        return req.data
    } catch (e) {
        throw e
    }
}


//Получить количетсво операций начисления бонусов для пользователя
export const getUserBonusOperationsCount = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/wallet/bonus/history/list', {
                params: {
                    code: 'NUMBER_OF_PAGE',
                    userId: params.id,
                    offset: 20
                }
            }
        )
        return req.data.recordCount
    } catch (e) {
        throw e
    }
}

//Получить количетсво список начисления бонусов для пользователя
export const getUserBonusOperationsList = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/wallet/bonus/history/list', {
                params: {
                    code: 'LIST',
                    userId: params.id,
                    offset: params.offset,
                    page: params.page
                }
            }
        )
        return req.data
    } catch (e) {
        throw e
    }
}

//Получение списка привязанных карта исполнителя (АПИ Банка)
export const getUserPaymentMethodsFromBank = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/bank/method/executor/list', {
                params: {
                    id: params.id
                }
            }
        )
        return req.data.state
    } catch (e) {
        throw e
    }
}


//Получение списка привязанных карта исполнителя (АПИ Банка)
export const manualDeletePayment = async (params) => {
    try {
        await $auth.delete('/v1/payment/bank/method/executor/remove', {
                params: {
                    userId: params.userId,
                    cardId: params.cardId
                }
            }
        )
    } catch (e) {
        throw e
    }
}


//Получение статуса выплаты используя АПИ банка
export const getPayoutInfoFromBankAPI = async (params) => {
    try {
        const req = await $auth.get('/v1/payment/bank/status/invoice/payout', {
                params: {
                    id: params.id,
                    code: "PAYMENT_ID"
                }
            }
        )
        return req.data.state
    } catch (e) {
        throw e
    }
}


//Подтверждение оплаты заказа
export const confirmPayoutRequest = async (params) => {
    try {
        const req = await $auth.post('/v1/payment/payout/confirm',  {
            code: "RECORD_ID",
            id: params.id,
        })
        return req.data
    } catch (e) {
        throw e
    }
}

//Создание запроса на начисление средств на счет
export const createBonusRequest = async (userId, ticketId, comment, amount) => {
    try {
        const req = await $auth.post(`/v1/payment/wallet/requests/bonus`, {
            userId: userId,
            ticketId: ticketId,
            comment: comment,
            amount: amount
        })
        notifications.addNotification(req.data.message, "OK")
    } catch (e) {
        notifications.addNotification(e.response.data.message)
    }
}

