import OperationsTable from "../OperationsTable/OperationsTable";
import {useCallback, useEffect, useState} from "react";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import {getOperationsCountWithFilter, getOperationsListWithFilter} from "../../../../../services/PaymentService";
import {notifications} from "../../../../../index";
import Pagination from "../../../../_BASE/UI/Pagination/Pagination";


const SearchTable = ({searchCode, searchID}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [paginationObject, setPaginationObject] = useState({
        page: 1, offset: 20
    });
    const [total, setTotal] = useState();

    const searchByIDList = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getOperationsListWithFilter({
                searchCode: searchCode,
                data: Number(searchID),
                page: paginationObject.page,
                offset: paginationObject.offset
            })
            setData(req)
        } catch (e) {
            setData([])
            notifications.addNotification(e.response.data.message)
        } finally {
            setIsLoading(false)
        }
    }, [searchCode, searchID, paginationObject.page, paginationObject.offset])

    const searchByIDCount = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getOperationsCountWithFilter({
                searchCode: searchCode,
                data: Number(searchID)
            })
            setTotal(req)
            setIsLoading(false)
        } catch (e) {
            setTotal(0)
            console.log(e)
        }
    }, [searchCode, searchID])

    useEffect(()=>{
        searchByIDCount()
    },[searchByIDCount])

    useEffect(()=>{
        searchByIDList()
    },[searchByIDList])


    if (isLoading) return <Preloader type={'big'}/>

    return <div>
        <OperationsTable data={data}/>
        <Pagination paginationObject={paginationObject}
                    setPaginationObject={setPaginationObject}
                    totalCount={total}

        />
    </div>
}

export default SearchTable