import s from './Card.module.scss'
import f from '../../../static/fonts.module.scss'
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";


import {ReactComponent as Dot} from "./dot.svg";

const Card = ({title, counter, tag, icon, theme, style, actions}) => {


    return <div className={`${s.wrap} ${theme === 'blue' ? s.blue : s.grey}`}
                style={style}
    >
        <div className={s.icon}>{icon}</div>
        <div className={s.statInfoWrap}>
            <div className={s.topLine}>
                {
                    tag && <p className={`${f.caption} ${s.tag}`}>{tag}</p>
                }
                {
                    actions && <div className={s.cardAction}>
                        <DropdownButton text={<Dot/>} type={'iconCustom'} actions={actions}/>
                    </div>
                }
            </div>
            <h3 className={`${f.boldH3} ${s.cardCounter}`}>{counter}</h3>
            <h5 className={`${f.boldH5} ${s.cardTitle}`}>{title}</h5>
        </div>
    </div>
}

export default Card
