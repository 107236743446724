import f from '../../../static/fonts.module.scss'
import s from './PopularCategories.module.scss'
import RadialChart from "../RadiallChart/Charts";
import {useEffect, useState} from "react";
import {getPopularCategoriesByAgent} from "../../../services/StatisticService";
import Preloader from "../../_BASE/UI/Preloader/Preloader";

const PopularCategories = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getPopularCategoriesByAgent()
                const formatted = req.map((item) => {
                    return {
                        name: `${item.sTitle} / ${item.cTitle}`,
                        value: item.number
                    }
                })
                setData(formatted)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [])

    if (isLoading) return <Preloader/>

    if (data?.length === 0) return null

    return <div className={s.wrap}>
        <p className={f.subtitle1Bold}>Популярные категории обращений</p>
        <RadialChart data={data}/>
    </div>
}

export default PopularCategories