import s from './BonusHistoryTable.module.scss'
import Tabs from "../../../_BASE/Layout/TabsTable/Tabs";
import {userStore} from "../UserInfo";

import BonusRequestsTable from "../../Payments/Bonuses/BonusRequestsTable/BonusRequestsTable";
import {useState} from "react";
import BonusOperations from "./BonusOperations/BonusOperations";

const BonusHistoryTable = () => {
    const Store = userStore
    const [activeTab, setActiveTab] = useState('Запросы');

    const tabs = [
        {
            label: 'Запросы',
            content: <BonusRequestsTable userId={Store.userId}
                                         type={'FALSE'}
                                         userType={Store.authProfile.userRoleGroupTitleCode === 'SYSTEM_USERS'
                                             ? 'USER'
                                             : 'TES_SUP_USER'
                                         }
            />
        },
        {
            label: "История изменений",
            content: <BonusOperations/>
        }
    ]

    return <div className={s.wrap}>
        <Tabs tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
        />
    </div>
}

export default BonusHistoryTable