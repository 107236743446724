import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import f from '../../../../../static/fonts.module.scss'
import s from './common.module.scss'
import {useEffect, useState} from "react";
import {deleteUser} from "../../../../../services/AuthService";
import {userStore} from "../../UserInfo";

const DeleteUser = ({closeFunc, Store}) => {
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);


    return <ModalWindow closeFunc={closeFunc}
                        title={"Удалить пользователя"}
                        type={'small'}
                        primaryAction={() => setDeleteConfirmed(true)}
    >
        <div className={s.deleteWrap}>
            <p className={f.body1}>Вы хотите удалить пользователя <span
                className={f.subtitle1Bold}>«{Store.authProfile.name} {Store.authProfile.surname}»</span>,
                вы уверенны? Действие нельзя отменить</p>
        </div>

        {
            deleteConfirmed && <TimerModal
                closeFunc={setDeleteConfirmed}
                outerCloseFunc={closeFunc}
                id={Store.authProfile.userId}/>
        }
    </ModalWindow>
}

const TimerModal = ({closeFunc, outerCloseFunc, id}) => {
    const [timer, setTimer] = useState(15);
    const [countdownId, setCountdownId] = useState();

    useEffect(() => {
        setCountdownId(setInterval(() => {
            if (timer !== 0) {
                setTimer(prev => prev - 1)
            }
        }, 1000))
        return () => {
            clearInterval(countdownId)
        }
    }, [])

    useEffect(() => {
        const deleteRequest = async () => {
            try {
                await deleteUser(id)
                closeFunc(false)
                outerCloseFunc(false)
                await userStore.getAllData()
            } catch (e) {
                throw e
            }
        }

        if (timer === 0) {
            clearInterval(countdownId)
            try {
                deleteRequest()
            } catch (e) {
                console.log(e)
            }
        }
    }, [timer])

    return <ModalWindow closeFunc={closeFunc}
                        title={"Удаление..."}
                        closeButtonLabel={"Отменить"}
    >
        <p className={f.body1}>
            Удаление пользователя начнется через {timer} секунд. <br/> Вы можете отменить это
        </p>
    </ModalWindow>
}

export default DeleteUser
