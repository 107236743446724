import s from './Tabs.module.scss'
import f from '../../../../static/fonts.module.scss'

const Tabs = ({tabs, activeTab, setActiveTab, sideButtons}) => {


    return <div className={s.wrap}>
        <div className={s.top}>
            <div className={s.tabNavs}>
                {
                    tabs.map((tab) => {
                        return <button
                            className={`${s.tabButton} ${f.boldH6} ${activeTab === tab.label ? s.active : ''}`}
                            onClick={() => setActiveTab(tab.label)}
                            key={tab.label}
                        >{tab.label}
                        </button>
                    })
                }
            </div>
            {
                sideButtons
            }
        </div>

        {
            tabs.filter(tab => activeTab === tab.label)[0].content
        }
    </div>
}

export default Tabs