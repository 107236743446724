import s from './TicketList.module.scss'
import m from "../../../static/misc.module.scss";
import f from "../../../static/fonts.module.scss";

import {useEffect, useState} from "react";
import {getAgentTickets, getAgentTicketsCount, searchTickets} from "../../../services/SupportService";
import {getColor} from "../../Dashboard/Main";

import Tabs from "../../_BASE/Layout/TabsTable/Tabs";
import Table from "../../_BASE/Layout/Table/Table";
import Pagination from "../../_BASE/UI/Pagination/Pagination";
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import Input from "../../_BASE/UI/Input/Input";
import Button from "../../_BASE/UI/Button/Button";
import TicketModal from "./TicketModal/TicketModal";
import TransferModal from "./TransferModal/TransferModal";

const searchQuery = async (message, setter) => {
    try {
        const data = await searchTickets(message, "USER_ID")
        setter(data)
    } catch (e) {
        throw e
    }
}

const getData = async (type, page, offset, setter) => {
    try {
        const data = await getAgentTickets(null, type, page, offset)
        setter(data)
    } catch (e) {
        throw e
    }
}

const TableRow = ({rowData, getData, allowTransfer}) => {
    const [ticketModal, setTicketModal] = useState(false);
    const [transferModal, setTransferModal] = useState(false);

    const actions = [
        {
            label: 'Посмотреть обращение',
            func: () => setTicketModal(true)
        },

    ]

    if (allowTransfer) {
        actions.push({
            label: 'Передать обращение',
            func: () => setTransferModal(true)
        })
    }

    return <>
        <div className={m.tableTags}>
            <p className={`${m.tag} ${f.body2}`}>{rowData.sTitle}</p>
            <p className={`${m.tag} ${f.body2}`}>{rowData.cTitle}</p>
        </div>
        <p className={f.body2}>{rowData.question}</p>
        <p className={f.body2}>{new Date(Number(rowData.createDate)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })}</p>
        <p className={`${f.subtitle2Bold} ${getColor(rowData.tsTitle)}`}>{rowData.tsTitle}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            ticketModal && <TicketModal closeFunc={setTicketModal} id={rowData.id}/>
        }
        {
            transferModal && <TransferModal closeFunc={setTransferModal} ticketId={rowData.id} getData={getData}/>
        }
    </>
}

const TicketsTable = ({type}) => {
    const [data, setData] = useState([]);
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });

    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getRecordCount = async () => {
            try {
                const count = await getAgentTicketsCount(null, type)
                setTotal(count)
                if (count === 0) {
                    setIsLoading(false)
                }
            } catch (e) {
                throw e
            }
        }
        getRecordCount()
    }, [type])

    useEffect(() => {
        if (total !== 0) {
            setIsLoading(true)
            getData(type, paginationObject.page, paginationObject.offset, setData)
            setIsLoading(false)
        }
    }, [paginationObject.page, paginationObject.offset, total, type])

    if (isLoading) return <Preloader/>

    return <div className={s.tableWrap}>
        <ErrorBoundary>
            <Table
                gridTemplate={"3fr 5fr 4fr 3fr 91px"}
                headsArray={[
                    "КАТЕГОРИЯ",
                    "ТЕМА",
                    "ДАТА СОЗДАНИЯ",
                    "СТАТУС",
                    "ДЕЙСТВИЯ",
                ]}
                rowsArray={data.map((item) => <TableRow getData={() => getData(type, paginationObject.page, paginationObject.offset, setData)}
                                                        rowData={item} key={item.id} allowTransfer={type==='IN_WORK'}/>)}/>
        </ErrorBoundary>
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total}
        />
    </div>
}

const TicketList = () => {
    const [activeTab, setActiveTab] = useState('В очереди');
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchData, setSearchData] = useState([]);

    const tabs = [
        {
            label: 'В очереди',
            content: <TicketsTable type={'IN_QUEUE'} key={'queue'}/>
        },
        {
            label: 'В работе',
            content: <TicketsTable type={'IN_WORK'} key={'work'}/>
        },
        {
            label: 'Закрытые',
            content: <TicketsTable type={'CLOSE'} key={'closed'}/>
        },
    ]

    return <div className={s.wrap}>
        <div className={s.searchBar}>
            <Input value={searchValue} setValue={setSearchValue}
                   placeholder={'Поиск по User ID'}
                   styles={{maxWidth: "100%", marginRight: 24}}/>
            {
                searchValue?.length > 0
                && <Button text={'Поиск'} click={() => {
                    setIsSearching(true)
                    searchQuery(searchValue, setSearchData)
                }
                }/>
            }
            {
                isSearching
                && <Button type={'alert'}
                           text={'Сбросить'}
                           nestedStyles={{marginLeft: 24}}
                           click={() => {
                               setIsSearching(false)
                               setSearchValue('')
                           }
                           }
                />
            }
        </div>

        {
            isSearching
                ? <Table gridTemplate={"3fr 5fr 4fr 3fr 91px"}
                         headsArray={[
                             "КАТЕГОРИЯ",
                             "ТЕМА",
                             "ДАТА СОЗДАНИЯ",
                             "СТАТУС",
                             "ДЕЙСТВИЯ",
                         ]}
                         rowsArray={searchData.map((item) => <TableRow rowData={item} key={item.id}/>)}/>
                : <Tabs tabs={tabs}
                        activeTab={activeTab} setActiveTab={setActiveTab}/>
        }
    </div>
}

export default TicketList