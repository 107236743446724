import React from "react";
import {ReactComponent as Alert} from "./alert.svg";

import style from './ErrorBoundary.module.scss'
import fonts from '../../static/fonts.module.scss'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return(
                <div className={style.wrap}>
                    <Alert/>
                    <p className={fonts.body1}>Что-то пошло не так...</p>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary