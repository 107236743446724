import React, {useEffect, useState} from "react";

import style from './LogsTable.module.scss'
import fonts from '../../../../static/fonts.module.scss'
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import {getLogsByUserId, getLogsCount} from "../../../../services/AuditService";
import {userStore} from "../UserInfo";
import Table from "../../../_BASE/Layout/Table/Table";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";


const LogsTable = () => {
    const Store = userStore
    const [data, setData] = useState([])
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 10
    });
    const [total, setTotal] = useState(0);
    const [userId] = useState(Store.authProfile.userId)
    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);

    useEffect(() => {
        async function getCount() {
            const count = await getLogsCount(userId)
            setTotal(count.recordCount)
            setCountIsLoaded(true)
        }

        getCount()
    }, [userId])


    useEffect(() => {
        async function getData() {
            const res = await getLogsByUserId(userId, paginationObject.page, paginationObject.offset)
            setData(res)

            setIsLoading(false)
        }

        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, total, userId, countIsLoaded])

    if (isLoading) return <Preloader type={'big'}/>

    return (
        <div>
            <h4 className={`${fonts.boldH4}`}>События и Логи</h4>
            <div className={style.table}>
                <Table gridTemplate={'1fr 3fr 1fr'}
                       rowsArray={
                           data.map(({id, title, message, timestamp}) => {
                               const date = new Date(Number(timestamp))
                               const result = date.toLocaleDateString('ru-RU', {
                                   year: 'numeric',
                                   month: '2-digit',
                                   day: '2-digit',
                                   hour: '2-digit',
                                   minute: '2-digit',
                                   second: '2-digit',
                               })

                               return <React.Fragment key={id}>
                                   <p className={`${fonts.subtitle1Bold}`}>{title}</p>
                                   <p className={fonts.body1}>{message}</p>
                                   <p className={fonts.body1}>{result}</p>
                               </React.Fragment>
                           })
                       }
                       headsArray={['Тип', "Комментарий", "Дата"]}/>
            </div>
            <Pagination
                paginationObject={paginationObject}
                setPaginationObject={setPaginationObject}
                totalCount={total} offsetSteps={[10, 25, 50]}
            />
        </div>
    )
}

export default LogsTable