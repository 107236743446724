import Table from "../../../../_BASE/Layout/Table/Table";

import f from '../../../../../static/fonts.module.scss'
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {timestampToDate} from "../../../../../static/utilities";

const OperationsTable = ({data}) => {
    return <Table headsArray={["ID Платежа",
        "ID Заказа",
        "Комментарий",
        "Сумма", "Дата",
        "Статус",
        // "Действия"
    ]}
                  gridTemplate={'1fr 1fr 3fr 1fr 2fr 2fr '}
                  rowsArray={data.map(rowData => <SingleRow key={rowData.id} rowData={rowData}/>)}
    />
}

const SingleRow = ({rowData}) => {

    // const actions = [
    //     {
    //         label: 'Просмотр',
    //         type: 'link',
    //         link: `/payments/operations/${rowData.id}`
    //     }
    // ]

    return <>
        <p className={f.subtitle1Bold}>{rowData.id}</p>
        <p className={f.subtitle1Bold}>{rowData.orderId}</p>
        <p className={f.body1}>{rowData.description}</p>
        <p className={f.subtitle1Bold}>{rowData.originalAmount / 100}.00 ₽</p>
        <p className={f.subtitle1Bold}>{timestampToDate(rowData.timestamp)}</p>
        <p className={f.subtitle1Bold}>{rowData.status}</p>
        {/*<div style={{display: "flex", justifyContent: "center"}}>*/}
        {/*    <DropdownButton type={'icon'} actions={actions}/>*/}
        {/*</div>*/}
    </>

}
export default OperationsTable