import {makeAutoObservable} from "mobx";

class PageTitleStore {
    title = ''

    constructor() {
        makeAutoObservable(this)
    }

    setNewTitle(text) {
        this.title = text
    }

}

export default new PageTitleStore()