import s from './Settings.module.scss'
import f from '../../../static/fonts.module.scss'
import {useCallback, useEffect, useState} from "react";
import {getUserInfo, updateUserAuthProfile, updateUserAvatar} from "../../../services/AuthService";
import {store} from "../../../index";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import Button from "../../_BASE/UI/Button/Button";
import Input from "../../_BASE/UI/Input/Input";
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import FileInput from "../../_BASE/UI/Input/FileInput/FileInput";
import {uploadImage} from "../../../services/FileService";
import {userStore} from "../UserInfo/UserInfo";

const md5 = require('md5')

const getData = async (setter, isLoading) => {
    const {data} = await getUserInfo(store.user.userId)
    setter(data)
    isLoading(false)
}

const ChangeAvatarModal = ({closeFunc, aftermath}) => {
    const [imageObject, setImageObject] = useState();
    const [imageUrl, setImageUrl] = useState();

    const uploadNewAvatar = useCallback(async () => {
        try {
            const newImage = await uploadImage(imageObject)
            await updateUserAvatar(store.user.userId, newImage.fileId, newImage.fileLink)
            await aftermath()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }, [aftermath, closeFunc, imageObject])

    return <ModalWindow closeFunc={closeFunc}
                        title={'Изменить аватар'}
    >
        <FileInput imageObject={imageObject} setImageObject={setImageObject}
                   imageUrl={imageUrl} setImageUrl={setImageUrl}
                   size={128}
        />
        {
            imageObject && <Button text={'Подтвердить'}
                                   nestedStyles={{marginTop: 24}}
                                   click={() => uploadNewAvatar()}
            />
        }
    </ModalWindow>
}

const Settings = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [email, setEmail] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordIsValid, setPasswordIsValid] = useState();

    const [changeAvatarModalIsVisible, setChangeAvatarModalIsVisible] = useState(false);

    const handleChangeInfo = useCallback(async () => {
        try {
            if (name !== data.user.name
                || surname !== data.user.surname
                || middlename !== data.user.middlename
            ) {
                await updateUserAuthProfile('NAME',
                    JSON.stringify({
                        name: name,
                        surname: surname,
                        middlename: middlename
                    }), store.user.userId)
            }

            if (email !== data.user.email) {
                await updateUserAuthProfile('EMAIL', {email: email}, null)
            }
        } catch (e) {
            throw e
        }
    }, [name, surname, middlename, email])


    const handleChangePassword = useCallback(async () => {
        try {
            if (passwordIsValid
                && (password === confirmPassword)
            ) {
                await updateUserAuthProfile('PASSWORD',
                    JSON.stringify({
                        oldPassword: md5(oldPassword),
                        newPassword: md5(password)
                    }), store.user.userId)
                setPassword('')
                setConfirmPassword('')
                setOldPassword('')
            }
        } catch (e) {
            throw e
        }
    }, [password, oldPassword, passwordIsValid, confirmPassword])


    useEffect(() => {
        getData(setData, setIsLoading)
    }, [])

    useEffect(() => {
        if (data) {
            setName(data?.user?.name)
            setSurname(data?.user?.surname)
            setMiddlename(data?.user?.middlename)
            setEmail(data?.user?.email)
        }
    }, [data])

    if (isLoading) return <Preloader/>

    return <div className={s.wrap}>
        <div className={s.userCard}>
            <div className={s.photo}>
                <img src={data.user.avatarLink} alt=""/>
            </div>
            <div className={s.info}>
                <h5 className={f.boldH5}>{data.user.surname} {data.user.name}</h5>
                <h6 className={f.boldH6}>{data.user.userRoleTitle}</h6>
            </div>
        </div>
        <div>
            <Button click={() => setChangeAvatarModalIsVisible(true)} text={'Изменить фото'}/>
        </div>
        <div className={s.table}>
            <div className={s.col}>
                <div className={s.fields}>
                    <Input title={'Фамилия'} value={surname} setValue={setSurname}/>
                    <Input title={'Имя'} value={name} setValue={setName}/>
                    <Input title={'Отчество'} value={middlename} setValue={setMiddlename}/>
                    <Input title={'E-mail'} value={email} setValue={setEmail}/>
                </div>
                <div>
                    <Button text={'Сохранить'}
                            type={!(name !== data.user.name
                                || surname !== data.user.surname
                                || middlename !== data.user.middlename
                                || email !== data.user.email
                            ) ? 'disabled' : 'main'
                            }
                            disabled={!(name !== data.user.name
                                || surname !== data.user.surname
                                || middlename !== data.user.middlename
                                || email !== data.user.email
                            )}
                            click={async () => {
                                await handleChangeInfo()
                                await getData(setData)
                            }
                            }/>
                </div>
            </div>
            <div className={s.col}>
                <div className={s.fields}>
                    <Input title={'Старый пароль'} password placeholder={'•••••••'}
                           value={oldPassword} setValue={setOldPassword}
                    />
                    <Input title={'Новый пароль'} password placeholder={'•••••••'}
                           value={password} setValue={setPassword}
                           validator={'password'} setIsValid={setPasswordIsValid}
                    />
                    <Input title={'Повторите пароль'} password placeholder={'•••••••'}
                           value={confirmPassword} setValue={setConfirmPassword}/>
                </div>
                {
                    password !== confirmPassword &&
                    password?.length > 5 &&
                    <p style={{marginBottom: 24, color: 'red'}} className={f.subtitle2Bold}>Пароли не совпадают!</p>
                }
                <div>
                    <Button text={'Изменить пароль'}
                            type={(passwordIsValid && (password === confirmPassword)) ? 'main' : "disabled"}
                            disabled={!(passwordIsValid && (password === confirmPassword))}
                            click={() => handleChangePassword()}
                    />
                </div>
            </div>
        </div>
        {
            changeAvatarModalIsVisible && <ChangeAvatarModal closeFunc={setChangeAvatarModalIsVisible}
                                                             aftermath={() => getData(setData, setIsLoading)}/>
        }
    </div>
}

export default Settings