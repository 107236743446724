import {useState} from "react";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {formatGender} from "../../../../../static/utilities";
import Select from "../../../../_BASE/UI/Select/Select";

const EditGenderModal = ({closeFunc, Store}) => {
    const [gender, setGender] = useState(Store.authProfile.gender);

    return <ModalWindow
        title={"Изменить пол пользователя"}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={"Сохранить"}
        primaryAction={async () => {
            await Store.updateUserAuthProfile('GENDER', JSON.stringify({
                gender: formatGender(gender)
            }), Store.authProfile.userId)
            closeFunc(false)
        }}
    >
        <div style={{width: 550,}}>
            <Select options={["Мужской", "Женский", "Не указано"]} title={"Укажите пол"}
                    selectedOption={gender} setSelectedOption={setGender}/>
        </div>
    </ModalWindow>
}

export default EditGenderModal
