import {useEffect, useState} from "react";

import s from './Pagination.module.scss'
import f from '../../../../static/fonts.module.scss'
import {ReactComponent as NextIcon} from "./icon/next.svg";
import {ReactComponent as PrevIcon} from "./icon/prev.svg";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";

const Pagination = ({
                        totalCount,
                        offsetSteps = [20, 50, 100],
                        hide = false,
                        paginationObject, setPaginationObject
                    }) => {

    const [pageCounter, setPageCounter] = useState(Math.ceil(totalCount / paginationObject.offset))

    const setCurrentActive = (m) => {
        setPaginationObject({...paginationObject, page: m})
    }

    useEffect(() => {
        if (totalCount) {
            setPageCounter(Math.ceil(totalCount / paginationObject.offset))
        }
    }, [paginationObject.offset, totalCount])

    const pageButtons = pageCounter => {
        const d = 2

        const numbers = []
        if (pageCounter > 2 * (d + 1) + 2) {
            numbers.push(1) // всегда в начале
            if (paginationObject.page > d + 2) {
                numbers.push('...')
            }
            if (paginationObject.page < d + 3) {
                for (let i = 2; i < 2 * (d + 1) + 2; i++) {
                    numbers.push(i)
                }
            } else if (paginationObject.page < pageCounter - d - 1) {
                for (let i = paginationObject.page - d; i <= paginationObject.page + d; i++) {
                    numbers.push(i)
                }
            } else if (paginationObject.page >= pageCounter - d - 1) {
                for (let i = pageCounter - 2 * (d + 1); i < pageCounter; i++) {
                    numbers.push(i)
                }
            }
            if (paginationObject.page < pageCounter - d - 1) {
                numbers.push('...')
            }
            numbers.push(pageCounter)
        } else {
            for (let i = 1; i <= pageCounter; i++) {
                numbers.push(i)
            }
        }

        return numbers.map((item, index) => {
            return <div
                key={index}
                className={`${s.button} ${f.button} ${paginationObject.page === item ? s.active : ""}`}
                onClick={() => {
                    if (typeof item === 'number') {
                        setCurrentActive(item)
                    }
                }
                }
            >
                {item}
            </div>
        })
    }

    //Смена количества пользователей на странице
    const queryCountButtons = offsetSteps.map((num, index) => {
        return (
            <button
                key={index}
                className={
                    paginationObject.offset === num ? s.activeCounter : ""
                }
                onClick={() => setPaginationObject({
                    page: 1,
                    offset: num
                })}
            >
                {num}
            </button>
        )
    })

    if (hide) return null

    return (
        <ErrorBoundary>
            <div className={s.allWrap}>
                {
                    totalCount > 0 && <div className={s.queryInfo}>
                        <p>Показано {(paginationObject.page - 1) * paginationObject.offset + 1}-{(paginationObject.page * paginationObject.offset) > totalCount ? totalCount : paginationObject.page * paginationObject.offset} из {totalCount} записей</p>
                        <div className={s.offsetSetter}>
                            <p>Записей на странице: </p>
                            <div className={s.queryCountSwitch}>
                                {queryCountButtons}
                            </div>
                        </div>
                    </div>
                }
                {
                    pageCounter > 1 && totalCount !== 0 && <div className={s.wrap}>
                        <div className={s.button}
                             onClick={() => paginationObject.page > 1 ? setCurrentActive(paginationObject.page - 1) : null}>
                            <PrevIcon/>
                        </div>
                        {pageButtons(pageCounter)}
                        <div className={s.button}
                             onClick={() => paginationObject.page < pageCounter ? setCurrentActive(paginationObject.page + 1) : null}>
                            <NextIcon/>
                        </div>
                    </div>
                }
            </div>
        </ErrorBoundary>
    )
}

export default Pagination