import fonts from "../../../../static/fonts.module.scss";
import style from "./TableRow.module.scss";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {ReactComponent as EditIcon} from "../editIcon.svg";
import {useState} from "react";
import OrderModal from "../../OrderInfo/OrderModal/OrderModal";

export const statusTag = (statusLabel, maxLength) => {
    const getColor = () => {
        if (
            statusLabel === 'Заказ создан'
            || statusLabel === 'Поиск исполнителя'
        ) return style.yellow
        if (
            statusLabel === 'Заказ выполняется'
            || statusLabel === 'Заказ оплачен'
            || statusLabel === 'Ожидает подтверждения заказчика'
        ) return style.blue
        if (
            statusLabel === 'Заказ отменен'
            || statusLabel === 'Исполнитель не найден'
            || statusLabel === 'Заказ не оплачен'
            || statusLabel === 'Заказ закрыт'
        ) return style.red
    }

    return <p title={statusLabel} className={`${style.status} ${fonts.subtitle2Bold} ${getColor()}`}>
        {
            maxLength
                ? `${statusLabel.slice(0, maxLength)}...`
                : statusLabel
        }
    </p>
}

const TableRow = ({
                      orderId,
                      createDate,
                      orderTitle,
                      price,
                      statusTitle,
                      changeOrderStatusDate,
                      subjectTitle,
                      optionTitle
                  }) => {

    const [orderModalVisible, setOrderModalVisible] = useState(false);

    const actions = [{
        label: 'Просмотр',
        func: () => setOrderModalVisible(true)
    }]

    return (
        <div className={style.tableRow}>
            <p className={`${fonts.subtitle1Bold} ${style.infoWrap}`}>{orderId}</p>
            <p className={`${fonts.body2} ${style.infoWrap}`}>{orderTitle}</p>
            <div className={style.tagContainer}>
                <div className={style.tagWrap}>
                    <p className={`${fonts.body2} ${style.tag}`}>{subjectTitle}</p>
                </div>
                <div className={style.tagWrap}>
                    <p className={`${fonts.body2} ${style.tag}`}>{optionTitle}</p>
                </div>
            </div>
            <p className={`${fonts.body2} ${style.infoWrap}`}>{price} ₽</p>
            <div>
                {statusTag(statusTitle, 12)}
            </div>
            <p className={`${fonts.body2} ${style.infoWrap}`}>{
                new Date(Number(createDate)).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            }</p>
            <p className={`${fonts.body2} ${style.infoWrap}`}>{
                new Date(Number(changeOrderStatusDate)).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            }</p>
            <div
                className={`${fonts.subtitle1Bold} ${style.infoWrap}`}
                style={{justifyContent: 'center'}}
            >
                <DropdownButton type={'icon'} text={<EditIcon/>} actions={actions}
                                nested_styles={{background: "white"}}/>
            </div>
            {
                orderModalVisible && <OrderModal closeFunc={setOrderModalVisible} orderId={orderId}/>
            }
        </div>
    )
}

export default TableRow