import f from '../../../../static/fonts.module.scss'
import s from './TransferModal.module.scss'

import {useState, useEffect} from "react";
import {getAgents, transferTicket} from "../../../../services/SupportService";
import Radios from "../../../_BASE/UI/Radios/Radios";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import Button from "../../../_BASE/UI/Button/Button";
import Table from "../../../_BASE/Layout/Table/Table";

import {notifications} from "../../../../index";

const TransferModal = ({closeFunc, ticketId, getData}) => {
    const [agentFilterSearchbar, setAgentFilterSearchbar] = useState('');
    const [agentList, setAgentList] = useState([]);
    const [selectedId, setSelectedId] = useState();

    const getAgentList = async () => {
        try {
            const list = await getAgents()
            setAgentList(list)
        } catch (e) {
            throw e
        }
    }

    const AgentListRow = ({agentRowData}) => {
        return <>
            <p className={f.subtitle1Bold}>{agentRowData.id}</p>
            <p className={f.subtitle1Bold}>
                {agentRowData.name} {agentRowData.middlename} {agentRowData.surname}
            </p>
            <div style={{display: "flex", justifyContent: "flex-end", marginRight: -24}}>
                <Radios
                    checkedValue={selectedId}
                    setCheckedValue={setSelectedId}
                    size={'small'}
                    groupName={'agentSelect'}
                    data={[
                        {
                            value: agentRowData.id,
                            title: ''
                        }
                    ]}
                />
            </div>
        </>
    }

    useEffect(() => {
        getAgentList()
    }, [])

    const eTableHeads = [
        "ID",
        "АГЕНТ ТП",
        <span style={{float: "right"}} className={f.subtitle2Bold}>ВЫБОР</span>,
    ]

    const confirmTransfer = async () => {
        if (!selectedId) {
            notifications.addNotification('Выберите нового агента')
            return
        }
        try {
            await transferTicket(ticketId, selectedId)
            await getData()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    return <ModalWindow
        title={'Передача обращения'}
        closeFunc={closeFunc}
    >
        <div className={s.editModalWrap}>
            <Input value={agentFilterSearchbar} setValue={setAgentFilterSearchbar}
                   placeholder={'Поиск'} styles={{maxWidth: '100%'}}/>
            <div className={s.agentList}>
                <Table headsArray={eTableHeads}
                       gridTemplate={'100px 1fr 100px'}
                       rowsArray={
                           agentFilterSearchbar?.length > 0
                               ? agentList.filter((item) =>
                                   item?.name?.indexOf(agentFilterSearchbar) !== -1
                               ).map((row) => <AgentListRow agentRowData={row}/>)
                               : agentList.map((row) => <AgentListRow agentRowData={row}/>)
                       }
                       headerStyle={{padding: 16}}
                />
            </div>
            <div className={s.editButtons}>
                <Button text={'Отмена'} type={'alert'} click={() => closeFunc(false)}/>
                <Button text={'Подтвердить'} click={() => confirmTransfer()}/>
            </div>
        </div>
    </ModalWindow>
}


export default TransferModal