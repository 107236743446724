import axios from "axios";
import {store} from "../index";


export const AUTH_API_URL = process.env.NODE_ENV === 'development' ?
    process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API

axios.defaults.withCredentials = true;
const $auth = axios.create({
        withCredentials: true,
        baseURL: AUTH_API_URL
    }
);

$auth.interceptors.request.use((config) => {
    config.headers!.Authorization = `Bearer ${localStorage.getItem('authToken')}`
    return config;
})

$auth.interceptors.response.use((config) => {
    return config
}, async (error) => {
    console.log({error})
    const originalRequest = error.config
    if(error.response?.status === 401 && error.config && !error.config._isRetry){
        originalRequest._isRetry = true
        try {
            await store.checkAuth()
            return $auth.request(originalRequest)
        } catch (e) {
            console.log({e})
        }
    }

    throw error
})

export default $auth
