import {useEffect} from "react";
import pageTitle from "../../store/PageTitle";
import Settings from "../../components/_PAGES/Settings/Settings";

const SettingsPage = () => {
    useEffect(() => {
        pageTitle.setNewTitle('Настройки')
    }, [])
    return <Settings/>
}

export default SettingsPage