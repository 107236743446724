import {useCallback, useContext, useEffect, useState} from "react";
import {Context, notifications} from "../../../index";
import {closeTicket, getTicketCloseReasons} from "../../../services/SupportService";
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import Select from "../../_BASE/UI/Select/Select";
import Input from "../../_BASE/UI/Input/Input";
import Button from "../../_BASE/UI/Button/Button";


const CloseTicketModal = ({ticketId, closeFunc, chatId}) => {
    const [reasonsData, setReasonsData] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [comment, setComment] = useState('');
    const {ChatStore} = useContext(Context)

    const handleSubmit = useCallback(async () => {
        if (!selectedOption) {
            notifications.addNotification('Выберите приичину закрытия из списка')
            return
        }
        if (comment?.length < 3) {
            notifications.addNotification('Укажите комментарий')
            return
        }

        try {
            const reasonId = reasonsData.filter((reason) => reason.title === selectedOption)[0]?.id
            await closeTicket(ticketId, reasonId, comment)
            ChatStore.setSelectedChatId(null)
            ChatStore.deleteChatById(chatId)
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }, [comment, selectedOption, ticketId, closeFunc, reasonsData])

    useEffect(() => {
        const getReasons = async () => {
            const reasons = await getTicketCloseReasons()
            setReasonsData(reasons)
            setOptions(reasons.map((reasons) => reasons.title))
        }
        getReasons()
    }, [])

    return <ModalWindow closeFunc={closeFunc}
                        title={`Закрыть обращение #${ticketId}`}
    >
        <Select title={'Укажите причину закрытия'}
                styles={{width: 600}}
                options={options}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
        />
        <Input value={comment} setValue={setComment}
               title={'Укажите комментарий'}
               styles={{width: 600, maxWidth: 600, marginTop: 24, marginBottom: 24}}
        />
        <Button text={'Закрыть обращение'} click={() => handleSubmit()}/>
    </ModalWindow>
}

export default CloseTicketModal
