import {useState} from "react";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";

const EditFioModal = ({closeFunc, Store}) => {
    const [name, setName] = useState(Store.authProfile.name)
    const [surname, setSurname] = useState(Store.authProfile.surname)
    const [middlename, setMiddlename] = useState(Store.authProfile.middlename)
    const [nameIsValid, setNameIsValid] = useState()
    const [surnameIsValid, setSurnameIsValid] = useState()
    const [middlenameIsValid, setMiddlenameIsValid] = useState()


    return <ModalWindow
        title={"Изменить ФИО"}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={"Сохранить"}
        primaryAction={async () => {
            await Store.updateUserAuthProfile('NAME', JSON.stringify({
                name: name,
                surname: surname?.length > 0 ? surname : null,
                middlename: middlename?.length > 0 ? middlename : null
            }), Store.authProfile.userId)
            closeFunc(false)
        }}
        primaryButtonIsDisabled={
            Store.authProfile.middlename
                ? !(nameIsValid && surnameIsValid && middlenameIsValid)
                : !(nameIsValid && surnameIsValid)
        }
    >
        <div style={{width: 550,}}>
            <Input title={'Имя'}
                   value={name}
                   setValue={setName}
                   styles={{maxWidth: '100%'}}
                   validator={'name'}
                   setIsValid={setNameIsValid}
            />
            {
                Store.authProfile.surname && <Input title={'Фамилия'}
                                                    value={surname}
                                                    setValue={setSurname}
                                                    styles={{maxWidth: '100%', marginTop: 16}}
                                                    validator={'name'}
                                                    setIsValid={setSurnameIsValid}
                />
            }
            {
                Store.authProfile.middlename && <Input title={'Отчество'}
                                                       value={middlename}
                                                       setValue={setMiddlename}
                                                       styles={{maxWidth: '100%', marginTop: 16}}
                                                       validator={'name'}
                                                       setIsValid={setMiddlenameIsValid}
                />
            }
        </div>
    </ModalWindow>
}

export default EditFioModal
