export const formatDataForAreaCharts = (data, period) => {
    if (period === 'WEEK') {
        return data.map((item) => {
            return {
                name: new Date(item[0]).toLocaleDateString('ru-RU', {
                    weekday: 'short'
                }).toUpperCase(),
                value: item[1]
            }
        })
    }

    if (period === 'MONTH') {
        return data.map((item) => {
            return {
                name: item[0].slice(3, 5),
                value: item[1]
            }
        })
    }

    if (period === 'YEAR') {
        return data.map((item) => {
            return {
                name: (new Date(item[0]).toLocaleDateString('ru-RU', {
                    month: 'short'
                })).toUpperCase().slice(0, 3),
                value: item[1]
            }
        })
    }
}

export const formatDataForNewUsersChart = (data, period) => {
    const formatData = (data) => {
        let res = [{
            name: '',
            all_users: data[0].all_users * .9,
            school: data[0].school * .9,
            university: data[1].university * .9,
        }]
        res.push(...data)
        res.push({
            name: '',
            all_users: data[data.length - 1].all_users * .9,
            school: data[data.length - 1].school * .9,
            university: data[data.length - 1].university * .9,
        })
        return res
    }

    let formatted = []
    if (period === 'WEEK') {
        formatted = data.map((item) => {
            return {
                name: new Date(item[0]).toLocaleDateString('ru-RU', {
                    weekday: 'short'
                }).toUpperCase(),
                ...item[1]
            }
        })
    }

    if (period === 'MONTH') {
        formatted = data.map((item) => {
            return {
                name: item[0].slice(3, 5),
                ...item[1]
            }
        })
    }

    if (period === 'YEAR') {
        formatted = data.map((item) => {
            return {
                name: (new Date(item[0]).toLocaleDateString('ru-RU', {
                    month: 'short'
                })).toUpperCase().slice(0, 3),
                ...item[1]
            }
        })
    }
    console.log(formatted)

    return formatData(formatted)
}

export const formatGender = (text) => {
    switch (text) {
        case 'Мужской':
            return 'MALE'
        case 'Женский':
            return 'FEMALE'
        case 'Не выбрано':
            return 'NOT_SELECT'
        default:
            return 'NOT_SELECT'
    }
}

export const timestampToDate= (timestamp) =>{
    return new Date(Number(timestamp)).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    })
}

export const ISOToDate= (ISODate) =>{
    return new Date(ISODate).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    })
}


export const GRAPH_COLORS = [
    "#3C4AE4",
    "#FF426C",
    "#ffa100",
    "#4151FF",
    "#ff6185",
    "#FFB100",
    "#FFB8BA",
    "#A1A5B7",
    "#E6F1FF",
    "#3F4254",
];